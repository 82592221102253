import {
  action,
  observable,
} from 'mobx';
import { DateTime } from 'luxon';
import { unwrapArrayResponse } from '../utils/graphQlUtils';
import { UPCOMING_PROJECTS, PROJECTS_WITH_MATERIAL } from './Api/Queries';

export const defaultFilter = {
  status: [],
  faufKauf: [],
};

export default class Make {
  constructor(rootStore) {
    this.root = rootStore;
  }

  @observable projects = [];
  @observable startingDay = DateTime.local().startOf('day');
  @observable loading = true;
  @observable reloading = false;
  @observable filter = defaultFilter;
  @observable data = [];

  @action.bound
  getData = async () => {
    const {
      client,
      ui,
      user,
    } = this.root;

    this.reloading = true;
    const today = DateTime.local();
    if (ui.selectedMaterial) {
      const result = await client.query({
        query: PROJECTS_WITH_MATERIAL,
        fetchPolicy: 'no-cache',
        variables: {
          materialNumber: ui.selectedMaterial,
          beforeDate: today.plus({ days: 14 }).startOf('day'),
        },
      });
      this.projects = unwrapArrayResponse(result.data.material.projects);
      const parsedData = this.parseData();

      this.data = parsedData;
    } else {
      const result = await client.query({
        query: UPCOMING_PROJECTS,
        fetchPolicy: 'no-cache',
        variables: {
          beforeDate: today.plus({ days: 14 }).startOf('day'),
          location: user.selectedLocation.value,
        },
      });
      this.projects = unwrapArrayResponse(result.data.allProjects);
      const parsedData = this.parseData();

      this.data = parsedData;
    }
    this.loading = false;
    this.reloading = false;
  }

  @action.bound
  parseData = () => {
    const types = {};

    this.projects.forEach((row) => {
      if (!Object.keys(types).includes(row.systemGroup)) {
        types[row.systemGroup] = {
          type: row.systemGroup,
        };
      }

      const startDate = DateTime.fromISO(row.omlStart).startOf('day');
      const isOverdue = startDate < DateTime.local().startOf('day');
      if (isOverdue) {
        if (!Object.keys(types[row.systemGroup]).includes('overdue')) {
          types[row.systemGroup].overdue = [];
        }
        types[row.systemGroup].overdue.push({
          ...row,
          key: row.salesOrderNumber,
          status: row.trafficLight,
        });
      } else {
        const offsetDiff = startDate.diff(this.startingDay.startOf('day'), 'days');
        const startOffset = offsetDiff.toObject().days ? Math.round(offsetDiff.toObject().days) : 0;

        if (startOffset >= 0) {
          if (!Object.keys(types[row.systemGroup]).includes(`t_plus_${startOffset}`)) {
            types[row.systemGroup][`t_plus_${startOffset}`] = [];
          }
          types[row.systemGroup][`t_plus_${startOffset}`].push({
            ...row,
            key: row.salesOrderNumber,
            status: row.trafficLight,
          });
        }
      }
    });

    return Object.values(types);
  }

  @action.bound
  resetFilter = () => {
    this.filter = defaultFilter;
  };

  @action.bound
  updateFilter = (newFilter) => {
    this.filter = newFilter;

    // set the week to earliest existing match
    const filteredData = this.projectFilter(this.projects);
    if (filteredData.length > 0) {
      const sorted = filteredData.sort((a, b) => {
        if (a.omlStart > b.omlStart) return 1;
        if (b.omlStart > a.omlStart) return -1;
        return 0;
      });
      const earliestFoundProject = DateTime.fromISO(sorted[0].omlStart);
      if (earliestFoundProject >= this.startingDay.plus({ days: 7 }).startOf('day')) {
        this.goToNextWeek();
      } else if (earliestFoundProject < this.startingDay.startOf('day')
        // don't go back for overdue projects
        && earliestFoundProject.startOf('day') >= DateTime.local().startOf('day')
      ) {
        this.goToLastWeek();
      }
    }
  };

  @action.bound
  removeFilterLine = (filterKey) => {
    const [filterClass, filterValue] = filterKey.split('.');
    const prevFilter = this.filter;
    this.filter = {
      ...prevFilter,
      [filterClass]: prevFilter[filterClass].filter((filterEntry) => filterEntry.value !== filterValue),
    };
  };

  @action.bound
  getFilteredData = () => this.data.map((typeObject) => {
    const mapFunction = (projects, column) => {
      if (column === 'type') {
        // name "projects" doesn't apply here. this property is the odd one out
        return projects;
      }
      return this.projectFilter(projects);
    };
    return Object.fromEntries(Object.entries(typeObject).map(
      ([column, projects]) => [column, mapFunction(projects, column)],
    ));
  })

  @action.bound
  projectFilter = (projects) => {
    if (Object.values(this.filter).every((filterField) => filterField.length === 0)) {
      return projects;
    }
    return projects.filter((project) => (
      this.filter.status.some((filterRow) => (
        filterRow.value === project.status
      )) || this.filter.faufKauf.some((filterRow) => (
        filterRow.value === project.salesOrderNumber || filterRow.value === project.productionOrderNumber
      ))
    ));
  }

  @action.bound
  refreshData = () => {
    this.reloading = true;
    this.getData();
  }

  @action.bound
  goToNextWeek = () => {
    const oldStartingDay = this.startingDay;
    this.startingDay = oldStartingDay.plus({ days: 7 });
    this.data = this.parseData();
  }

  @action.bound
  goToLastWeek = () => {
    const oldStartingDay = this.startingDay.startOf('day');
    this.startingDay = oldStartingDay.minus({ days: 7 });
    this.data = this.parseData();
  }
}
