import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { debounce } from 'throttle-debounce';

const RemarkCell = ({
  row,
  onChange,
  id,
}) => {
  const [value, setValue] = useState(row);
  const debouncedUpdate = useMemo(() => debounce(1000, (newValue) => onChange(id, newValue)), [id]);

  const handleChange = (event) => {
    const newValue = event.currentTarget.value;
    setValue(newValue);
    if (onChange) debouncedUpdate(newValue);
  };

  return (
    <input
      value={value || ''}
      onChange={handleChange}
      style={{
        width: '100%',
        height: '100%',
      }}
    />
  );
};

RemarkCell.propTypes = {
  id: PropTypes.string,
  row: PropTypes.string,
  onChange: PropTypes.func,
};

export default RemarkCell;
