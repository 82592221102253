import React, { Component } from 'react';
import { observer, inject, PropTypes as MobxPropTypes } from 'mobx-react';
import { observe } from 'mobx';
import PropTypes from 'prop-types';
import { DateTime } from 'luxon';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { withStyles } from '@material-ui/core/styles';
import { withSnackbar } from 'notistack';
import SimpleTable from '../SimpleTable';
import NewDeliveryModal from '../DeliveryForm/NewDeliveryModal';
import UpdateDeliveryForm from '../DeliveryForm/UpdateDeliveryForm';
import { trimLead0s } from '../../utils/displayUtils';
import { getBaseColumns } from './columns';
import FilterForm from '../FilterForm';

import { defaultGroupProperty } from '../SimpleTable/TableGroup';

@inject('store')
@observer
class Source extends Component {
  constructor(props) {
    super(props);
    props.store.source.changeFilter({
      materialNumber: [],
      status: [],
      supplierCode: [],
      supplierNumber: [],
      materialGroup: [],

    });
  }

  componentDidMount() {
    const {
      ui,
    } = this.props.store;

    if (ui.autoRefresh) {
      this.refreshInterval = setInterval(this.refreshData, ui.autoRefreshDuration);
    }

    this.disposer = observe(ui, 'autoRefresh', ({ newValue }) => {
      if (newValue === false) {
        clearInterval(this.refreshInterval);
      } else {
        this.refreshData();
        this.refreshInterval = setInterval(this.refreshData, ui.autoRefreshDuration);
      }
    });
  }

  componentWillUnmount() {
    clearInterval(this.refreshInterval);
    this.disposer();
  }

  refreshData = () => {
    this.props.enqueueSnackbar('Lädt Daten...');
    this.props.store.source.refreshData();
  }

  handleDeliverySelected = (row, column) => {
    const {
      store,
    } = this.props;

    const {
      permissions,
    } = store.user.user;
    const data = row[column];
    store.source.focusOnRow(row);
    store.source.focusOnColumn(column);
    if (data !== undefined && permissions.includes('delivery.change_deliveryline')) {
      store.source.showUpdateDeliveryModal();
    } else if (permissions.includes('delivery.add_deliveryline')) {
      store.source.showNewDeliveryModal();
    }
  }

  newDeliveryDate = async (values) => {
    this.props.store.source.addDelivery(values);
  }

  updateDeliveryDate = async (values) => {
    this.props.store.source.updateDelivery(values);
  }

  deleteDelivery = async (pk) => {
    this.props.store.source.deleteDelivery(pk);
  }

  handleLastWeekPressed = () => {
    const {
      source,
    } = this.props.store;
    source.setStartingDay(source.startingDay.minus({ days: 7 }));
  }

  handleNextWeekPressed = () => {
    const {
      source,
    } = this.props.store;
    source.setStartingDay(source.startingDay.plus({ days: 7 }));
  }

  renderContents = (selectedMaterial) => {
    const {
      store,
    } = this.props;

    const selectedProject = store.make.projects
      .find(project => project.salesOrderNumber === store.source.projectFilter && project.positionNumber === store.source.projectFilterPositionNumber);
    const getAuxInfo = () => {
      if (selectedProject) {
        return {
          faufKauf: [
            {
              label: `${trimLead0s(selectedProject.productionOrderNumber.trim()
                || selectedProject.salesOrderNumber.trim())} (Pos. ${selectedProject.positionNumber})`,
              onRemove: () => store.source.projectChangeFilter(undefined),
              exclusive: true,
            },
          ],
        };
      }
      if (store.source.filterFromDashboard) {
        return {
          supplierCode: [
            {
              label: `Dashboard: ${store.source.dashboardFilter}`,
              onRemove: () => store.source.deactivateDashboardFilter(),
            },
          ],
        };
      }
      return undefined;
    };

    const groupProperty = {
      ...defaultGroupProperty,
      parentGroupBy: 'group',
    };

    return (
      <SimpleTable
        filter
        filterObject={store.source.formFilter}
        filterReset={store.source.resetFilter}
        filterForm={<FilterForm value={store.source.formFilter} onOk={store.source.changeFilter} />}
        auxiliaryInfo={getAuxInfo()}
        handleDeleteFilterLine={store.source.removeFilterLine}
        rowHoverEffect
        keyColumn="materialNumber"
        data={store.source.data.peek()}
        groupProperty={groupProperty}
        grouped={store.ui.grouped}
        rowIntroId="material"
        isCompact={!store.ui.sourceWeekly}
        columns={
          getBaseColumns(
            store.source.data,
            this.handleDeliverySelected,
            store.source.startingDay,
            store.ui.sourceExpanded,
            store.ui.sourceSwitch,
            store.ui.sourceWeekly,
            store.ui.showReliableSuppliers,
          )
        }
        onRowClick={(row) => { this.props.store.ui.selectMaterial(row.materialNumber); }}
        highlightField="materialNumber"
        highlightValue={selectedMaterial}
        onBodyScroll={(event) => {
          if (!store.source.appending) {
            if (event.target.scrollTop + event.target.offsetHeight >= (event.target.scrollHeight - 40)) {
              store.source.appendData();
            }
          }
        }}
        groupSort={(a, b) => {
          if (a === 'Nicht zugewiesen') {
            return 1;
          }
          if (b === 'Nicht zugewiesen') {
            return -1;
          }
          return 0;
        }}
        sortObject={this.props.store.source.sortObject}
        onSort={this.props.store.source.setSort}
      />
    );
  }

  render() {
    const {
      classes,
      store,
      introId,
    } = this.props;

    const {
      startingDay,
      focussedRow,
      focussedColumn,
      newDeliveryModalVisible,
      closeNewDeliveryModal,
      updateDeliveryModalVisible,
      closeUpdateDeliveryModal,
      appending,
      loading,
      reloading,
    } = store.source;

    return (
      <div id={introId} className={classes.container}>
        <NewDeliveryModal
          visible={newDeliveryModalVisible}
          onCancel={closeNewDeliveryModal}
          onUpdate={this.newDeliveryDate}
          initialMaterial={focussedRow && focussedRow.materialNumber}
          selectedDay={focussedColumn}
          startingDay={startingDay}
        />
        <UpdateDeliveryForm
          visible={updateDeliveryModalVisible}
          onCancel={closeUpdateDeliveryModal}
          onUpdate={this.updateDeliveryDate}
          onDelete={this.deleteDelivery}
          selectedRow={focussedRow}
          selectedDay={focussedColumn}
        />
        <span className={classes.weekSelectorContainer}>
          <Typography variant="subtitle1" color="secondary">Anlieferungen</Typography>
          {store.ui.sourceWeekly && (
            <span className={classes.weekSelector}>
              <IconButton
                onClick={this.handleLastWeekPressed}
                color="secondary"
                disabled={startingDay.startOf('day') <= DateTime.local().startOf('day')}
                aria-label="Zurück"
              >
                <NavigateBeforeIcon />
              </IconButton>
              <Typography variant="subtitle1" color="secondary">
                {`${startingDay.toFormat('dd. LLL')} - ${startingDay.plus({ days: store.ui.sourceExpanded ? 13 : 6 }).toFormat('dd. LLL')}`}
              </Typography>
              <IconButton
                onClick={this.handleNextWeekPressed}
                color="secondary"
                disabled={
                  store.ui.sourceExpanded
                  || startingDay.startOf('day') >= DateTime.local().startOf('day').plus(2, 'weeks')
                }
                aria-label="Vorwärts"
              >
                <NavigateNextIcon />
              </IconButton>
            </span>
          )}
        </span>
        <div className={classes.contentContainer}>
          {(
            appending
            || (loading && !reloading))
            && (
              <div className={classes.appendingOverlay}>
                <CircularProgress />
              </div>
            )}
          {this.renderContents(store.ui.selectedMaterial)}
        </div>
      </div>
    );
  }
}

Source.propTypes = {
  store: MobxPropTypes.objectOrObservableObject,
  classes: PropTypes.object,
  introId: PropTypes.string,
  enqueueSnackbar: PropTypes.func,
};

const style = () => ({
  container: {
    padding: '5px',
    height: '100%',
    position: 'relative',
  },
  contentContainer: {
    height: 'calc(100% - 48px)',
  },
  weekSelectorContainer: {
    height: '48px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    overflowX: 'hidden',
  },
  weekSelector: {
    marginLeft: '10px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    whiteSpace: 'nowrap',
  },
  appendingOverlay: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(255, 255, 255, 0.5)',
    zIndex: '1',
  },
});

export default withStyles(style)(withSnackbar(Source));
