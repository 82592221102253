import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  TextField,
  Button,
} from '@material-ui/core';
import { inject, observer, PropTypes as MobxPropTypes } from 'mobx-react';
import { range } from 'ramda';

@inject('store')
@observer
class LogicConfig extends Component {
  render() {
    const {
      store,
      classes,
    } = this.props;

    const {
      tableReportables,
      updateTableReportable,
      selectedReport,
    } = store.reporting;

    const reset = (index) => {
      updateTableReportable(index, 'category', null);
      updateTableReportable(index, 'subcategory', null);
      updateTableReportable(index, 'operator', null);
      updateTableReportable(index, 'operand', null);
    };

    return (
      <div className={classes.container}>
        {range(0, 2).map(index => {
          const activeCategory = selectedReport.categories.find(category => (
            category.id === tableReportables[index].category
          ));

          return (
            <div className={classes.reportableRow} key={index}>
              <FormControl className={classes.field}>
                <InputLabel id="table-category-label">Kriterienauswahl</InputLabel>
                <Select
                  labelId="table-category-label"
                  id="table-category"
                  value={tableReportables[index].category || ''}
                  onChange={(e) => updateTableReportable(index, 'category', e.target.value)}
                >
                  {selectedReport.categories.map(category => (
                    <MenuItem key={category.id} value={category.id}>
                      {category.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl className={classes.field}>
                <InputLabel id="table-subcategory-label">Berechnungslogik</InputLabel>
                <Select
                  labelId="table-subcategory-label"
                  id="table-subcategory"
                  value={tableReportables[index].subcategory || ''}
                  onChange={(e) => updateTableReportable(index, 'subcategory', e.target.value)}
                >
                  {((activeCategory && activeCategory.subCategories) || []).map(subCategory => (
                    <MenuItem key={subCategory.id} value={subCategory.id}>
                      {subCategory.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl className={classes.field}>
                <InputLabel id="table-operator-label">Operator</InputLabel>
                <Select
                  labelId="table-operator-label"
                  id="table-operator"
                  value={tableReportables[index].operator || ''}
                  onChange={(e) => updateTableReportable(index, 'operator', e.target.value)}
                >
                  <MenuItem value="lt">Kleiner als</MenuItem>
                  <MenuItem value="gte">Größer gleich</MenuItem>
                </Select>
              </FormControl>
              <TextField
                id="table-operand"
                className={classes.field}
                type="number"
                label="Operand"
                value={tableReportables[index].operand || ''}
                onChange={(e) => updateTableReportable(index, 'operand', e.target.value)}
              />
              <Button
                onClick={() => {
                  reset(index);
                }}
                aria-label="Zurücksetzen"
              >
                Zurücksetzen
              </Button>
            </div>
          );
        })}
        <div className={classes.resetAllContainer}>
          <Button onClick={() => { range(0, 2).forEach(reset); }} aria-label="Alle Zurücksetzen">
            Alle Zurücksetzen
          </Button>
        </div>
      </div>
    );
  }
}

LogicConfig.propTypes = {
  store: MobxPropTypes.objectOrObservableObject,
  classes: PropTypes.object,
};

const styles = theme => ({
  container: {
    textAlign: 'start',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  resetAllContainer: {
    marginTop: 5,
    display: 'flex',
    justifyContent: 'flex-end',
  },
  reportableRow: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
  },
  field: {
    flex: 1,
    margin: `0 ${theme.spacing(1)}px`,
  },
});

export default withStyles(styles)(LogicConfig);
