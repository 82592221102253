import {
  action,
  observable,
  flow,
  runInAction,
} from 'mobx';
import { DateTime } from 'luxon';
import { getSheetUploads, updateDeliverySheet } from 'Api/endpoints/sheet-uploads';
import {
  CREATE_DELIVERY,
  UPDATE_DELIVERY,
  DELETE_DELIVERY,
} from './Api/Mutations';
import { SOURCE_DATA, MATERIALS_FROM_PROJECT, AVAILABLE_MATERIALS } from './Api/Queries';

export const defaultFilter = {
  materialNumber: [],
  status: [],
  supplierCode: [],
  supplierNumber: [],
  materialGroup: [],
};

export const defaultSort = {
  field: '',
  ascending: true,
};

export default class Source {
  constructor(rootStore) {
    this.root = rootStore;
  }

  @observable filterFromProject = false;
  @observable filterFromDashboard = false;

  @observable sortObject = defaultSort;

  @observable formFilter = defaultFilter;
  @observable projectFilter = undefined;
  @observable projectFilterPositionNumber = undefined;
  @observable dashboardFilter = undefined;

  @observable data = [];
  @observable focussedColumn = null;
  @observable focussedRow = null;
  @observable newDeliveryModalVisible = false;
  @observable updateDeliveryModalVisible = false;
  @observable lastReceivedPage = null;
  @observable reloading = false;
  @observable loading = false;
  @observable appending = false;
  hasNextPage = false;
  queryId = 0;

  @observable startingDay = DateTime.local().startOf('day');

  @observable latestUploads = [];
  @observable deliveriesToday = [];
  @observable deliveriesTodayLoading = false;

  @action.bound
  updateSheetUploads = async () => {
    const response = await getSheetUploads(this.root.user.token);

    if (response.error !== undefined) {
      this.root.enqueueSnackbar('Laden vergangener Uploads fehlgeschlagen', { variant: 'error' });
    } else {
      this.latestUploads = response.value;
    }
  }

  @action.bound
  updateDeliverySheet = async (sheetUpdates) => {
    const response = await updateDeliverySheet(sheetUpdates, this.root.user.token);
    if (response.error !== undefined) {
      this.root.enqueueSnackbar('Bearbeiten der Lieferungen fehlgeschlagen', { variant: 'error' });
    } else {
      await this.updateSheetUploads();
    }
  }

  @action.bound
  changeFilter(filterObject) {
    if (!Object.is(filterObject, this.formFilter)) {
      this.formFilter = filterObject;
      this.projectFilter = undefined;
      this.projectFilterPositionNumber = undefined;
      this.filterFromProject = false;
      this.replaceData();
    }
  }

  @action.bound
  resetFilter() {
    this.formFilter = defaultFilter;
    this.projectFilter = undefined;
    this.projectFilterPositionNumber = undefined;
    this.filterFromProject = false;
    this.dashboardFilter = undefined;
    this.filterFromDashboard = false;
    this.sortObject = defaultSort;
    this.replaceData();
  }

  @action.bound
  removeFilterLine(filterKey) {
    const [filterClass, filterValue] = filterKey.split('.');
    this.formFilter = {
      ...this.formFilter,
      [filterClass]: this.formFilter[filterClass].filter((filterEntry) => filterEntry.value !== filterValue),
    };
    this.replaceData();
  }

  @action.bound
  projectChangeFilter(projectId, projectPositionNumber) {
    this.projectFilter = projectId;
    this.projectFilterPositionNumber = projectPositionNumber;
    this.filterFromProject = !!projectId;
    this.replaceData();
  }

  @action.bound
  getMaterialsFromAllMaterials = async (currentCursor) => {
    let statusFilterArray = (this.formFilter && this.formFilter.status);
    if (statusFilterArray && !Array.isArray(statusFilterArray)) {
      statusFilterArray = statusFilterArray.peek();
    }
    if (statusFilterArray === undefined) {
      statusFilterArray = [];
    }
    const statusFilter = statusFilterArray.map(filterRow => filterRow.value).join(', ');

    const getDispoFilter = () => {
      if (this.filterFromDashboard) {
        return [
          ...this.dashboardFilter.split(','),
          ...this.formFilter.supplierCode.map(filterRow => filterRow.value),
        ].join(',');
      }
      if (
        this.formFilter
        && this.formFilter.supplierCode
        && this.formFilter.supplierCode.length > 0
      ) {
        return this.formFilter.supplierCode.map(filterRow => filterRow.value).join(',');
      }
      return undefined;
    };

    const results = await this.root.client.query({
      query: SOURCE_DATA,
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
      variables: {
        after: currentCursor,
        first: 50,
        materialNumber_In: (
          this.formFilter
          && this.formFilter.materialNumber
          && this.formFilter.materialNumber.length > 0
        )
          ? this.formFilter.materialNumber.map(filterRow => filterRow.value).join(',')
          : undefined,
        supplierNumber_In: (
          this.formFilter
          && this.formFilter.supplierNumber
          && this.formFilter.supplierNumber.length > 0
        )
          ? this.formFilter.supplierNumber.map(filterRow => filterRow.value).join(',')
          : undefined,
        materialGroup_In: (
          this.formFilter
          && this.formFilter.materialGroup
          && this.formFilter.materialGroup.length > 0
        )
          ? this.formFilter.materialGroup.map(filterRow => filterRow.value).join(',')
          : undefined,
        trafficLight_In: statusFilter,
        supplierCode_In: getDispoFilter(),
        orderBy: `${this.sortObject.ascending ? '' : '-'}${this.sortObject.field}`,
        location: this.root.user.selectedLocation.value,
      },
    });

    if (results.errors) {
      if (results.data) {
        results.errors.forEach(error => {
          this.root.handleError(error, `Fehler beim Laden für Teile: ${error.message}`);
        });
      } else {
        throw new Error('Error retrieving data');
      }
    }

    this.hasNextPage = results.data.allMaterials.pageInfo.hasNextPage;

    return [
      results.data.allMaterials.edges.map(edge => edge.node),
      results.data.allMaterials.pageInfo.endCursor,
    ];
  }

  @action.bound
  getMaterialsWithAvailableDeliveries = async () => {
    this.deliveriesTodayLoading = true;
    const getDispoFilter = () => {
      if (this.filterFromDashboard) {
        return [
          ...this.dashboardFilter.split(','),
        ].join(',');
      }
      return undefined;
    };

    const availableMaterials = await this.root.client.query({
      query: AVAILABLE_MATERIALS,
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
      variables: {
        deliveryLineQuantity: 1.0,
        supplierCode_In: getDispoFilter(),
        orderBy: `${this.sortObject.ascending ? '' : '-'}${this.sortObject.field}`,
        location: this.root.user.selectedLocation.value,
      },
    });

    if (availableMaterials.errors) {
      if (availableMaterials.data) {
        availableMaterials.errors.forEach(error => {
          this.root.handleError(error, `Fehler beim Laden für Anlieferungen: ${error.message}`);
        });
      } else {
        throw new Error('Error retrieving devliveries');
      }
    }

    return this.translateResult(availableMaterials.data.allMaterials.edges.map(edge => edge.node));
  }

  @action.bound
  getMaterialsFromProject = async (currentCursor) => {
    const results = await this.root.client.query({
      query: MATERIALS_FROM_PROJECT,
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
      variables: {
        after: currentCursor,
        first: 50,
        salesOrderNumber: this.projectFilter,
        positionNumber: this.projectFilterPositionNumber,
        location: this.root.user.selectedLocation.value,
      },
    });

    if (results.errors) {
      if (results.data) {
        results.errors.forEach(error => {
          this.root.handleError(error, `Fehler beim Laden für Teile: ${error.message}`);
        });
      } else {
        throw new Error('Error retrieving data');
      }
    }

    const { materials } = results.data.allProjects.edges[0].node;

    this.hasNextPage = materials.pageInfo.hasNextPage;

    return [
      materials.edges.map(edge => edge.node),
      materials.pageInfo.endCursor,
    ];
  }

  translateResult = (result) => result.map((row) => {
    const deliveries = [];
    for (let i = 0; i < row.deliveryLines.edges.length; i += 1) {
      const delivery = row.deliveryLines.edges[i].node;
      deliveries.push(delivery);
    }

    const futureDeliveries = deliveries.filter(delivery => (
      DateTime.fromISO(delivery.deliveryDate) >= DateTime.local().startOf('day')
    ))
      .sort((a, b) => {
        if (DateTime.fromISO(a.deliveryDate) > DateTime.fromISO(b.deliveryDate)) return 1;
        if (DateTime.fromISO(b.deliveryDate) > DateTime.fromISO(a.deliveryDate)) return -1;
        return 0;
      });

    const hasDeliveryToday = deliveries.some(delivery => delivery.deliveryDate === DateTime.local().toISODate());

    return {
      deliveries,
      hasDeliveryToday,
      nextDelivery: futureDeliveries[0],
      group: row.materialGroup ? row.materialGroup.name : 'Nicht zugewiesen',
      disp: row.supplierCode,
      supplier: row.supplierName,
      supplierNumber: row.supplierNumber,
      materialNumber: row.materialNumber,
      description: row.description,
      status: row.trafficLight,
      reservationFilled: row.reservationFilled,
      demandQuantity: row.demandQuantity,
      demandForecast: row.demandForecast,
      stockForecast: row.stockForecast,
      storageTypes: row.storageTypes !== '' ? row.storageTypes : undefined,
      minStorageQuantities: row.minStorageQuantities !== '' ? row.minStorageQuantities : undefined,
      maxStorageQuantities: row.maxStorageQuantities !== '' ? row.maxStorageQuantities : undefined,
      stockObject: {
        current: row.stockQuantity,
        min: 0,
        max: row.stockQuantity,
      },
      isMissingPart: row.isMissingPart,
      hasReliableSupplier: row.hasReliableSupplier,
      abcClassification: row.abcClassification,
      numOverdueDeliveries: deliveries.filter(d => d.overdue).length,
    };
  });

  @action.bound
  getMaterialSummary = async (currentCursor) => {
    try {
      const [materials, endCursor] = this.filterFromProject
        ? await this.getMaterialsFromProject(currentCursor)
        : await this.getMaterialsFromAllMaterials(currentCursor);

      const translatedResult = materials.map((row) => {
        const deliveries = [];
        for (let i = 0; i < row.deliveryLines.edges.length; i += 1) {
          const delivery = row.deliveryLines.edges[i].node;
          deliveries.push(delivery);
        }

        const futureDeliveries = deliveries.filter(delivery => (
          DateTime.fromISO(delivery.deliveryDate) >= DateTime.local().startOf('day')
        ))
          .sort((a, b) => {
            if (DateTime.fromISO(a.deliveryDate) > DateTime.fromISO(b.deliveryDate)) return 1;
            if (DateTime.fromISO(b.deliveryDate) > DateTime.fromISO(a.deliveryDate)) return -1;
            return 0;
          });

        return {
          deliveries,
          nextDelivery: futureDeliveries[0],
          group: row.materialGroup ? row.materialGroup.name : 'Nicht zugewiesen',
          disp: row.supplierCode,
          supplier: row.supplierName,
          supplierNumber: row.supplierNumber,
          materialNumber: row.materialNumber,
          description: row.description,
          status: row.trafficLight,
          reservationFilled: row.reservationFilled,
          demandQuantity: row.demandQuantity,
          demandForecast: row.demandForecast,
          stockForecast: row.stockForecast,
          storageTypes: row.storageTypes !== '' ? row.storageTypes : undefined,
          minStorageQuantities: row.minStorageQuantities !== '' ? row.minStorageQuantities : undefined,
          maxStorageQuantities: row.maxStorageQuantities !== '' ? row.maxStorageQuantities : undefined,
          stockObject: {
            current: row.stockQuantity,
            min: 0,
            max: row.stockQuantity,
          },
          isMissingPart: row.isMissingPart,
          hasReliableSupplier: row.hasReliableSupplier,
          abcClassification: row.abcClassification,
          numOverdueDeliveries: deliveries.filter(d => d.overdue).length,
          materialRange: row.materialRange,
        };
      });
      return [translatedResult, endCursor];
    } catch (error) {
      this.root.handleError(error, 'Failed to retrieve material list');
      return [[], undefined];
    }
  }

  @action.bound
  getTodaysDeliveries = async () => {
    const deliveriesToday = [];
    const materialsWithAvailableDeliveries = await this.getMaterialsWithAvailableDeliveries();
    const materialsWithDeliveriesToday = materialsWithAvailableDeliveries
      .filter(material => material.hasDeliveryToday);
    materialsWithDeliveriesToday.forEach(material => {
      material.deliveries.forEach(delivery => {
        if (delivery.deliveryDate === DateTime.local().toISODate()) {
          deliveriesToday.push({
            ...delivery,
            materialNumber: material.materialNumber,
            disp: material.disp,
          });
        }
      });
    });
    this.deliveriesTodayLoading = false;
    return deliveriesToday;
  }

  // Mobx refuses to let me mock an @action, so I have to make a wrapper to have this available
  @action.bound
  refreshData() {
    this.reloading = true;
    this.replaceData();
  }

  replaceData = flow(function* replaceData() {
    this.loading = true;
    this.queryId += 1;
    const thisQuery = this.queryId;
    const [translatedResult, lastReceived] = yield this.getMaterialSummary();
    const deliveriesToday = yield this.getTodaysDeliveries();

    if (thisQuery === this.queryId) {
      const parsedData = this.parseData(translatedResult);
      const parsedDeliveriesToday = deliveriesToday;

      this.lastReceivedPage = lastReceived;
      this.data = parsedData;
      this.deliveriesToday = parsedDeliveriesToday;
      this.loading = false;
      this.appending = false;
      this.reloading = false;
    }
  })

  appendData = flow(function* appendData() {
    if (this.hasNextPage) {
      this.appending = true;
      this.queryId += 1;
      const thisQuery = this.queryId;
      const [newData, receivedPage] = yield this.getMaterialSummary(this.lastReceivedPage);

      if (thisQuery === this.queryId) {
        const updatedData = [
          ...this.data,
          ...this.parseData(newData),
        ];

        this.data = updatedData;
        this.lastReceivedPage = receivedPage;
        this.loading = false;
        this.appending = false;
        this.reloading = false;
      }
    }
  })

  @action.bound
  parseData = (data) => data.map((row) => {
    const returnRow = {
      ...row,
    };
    Object.keys(returnRow).forEach((key) => {
      if (/t_plus_.+/.test(key)) {
        delete returnRow[key];
      }
    });

    returnRow.deliveries.forEach((delivery) => {
      const deliveryDate = DateTime.fromFormat(delivery.deliveryDate, 'yyyy-MM-dd');
      const offsetDiff = deliveryDate.diff(this.startingDay, 'days');
      const deliveryOffset = offsetDiff.toObject().days ? Math.round(offsetDiff.toObject().days) : 0;
      returnRow[`t_plus_${deliveryOffset}`] = {
        quantity: delivery.quantity,
        source: delivery.source,
        trafficLight: delivery.trafficLight,
        createdDate: delivery.createdDate,
      };
    });
    return returnRow;
  })


  @action.bound
  addDelivery = flow(function* addDelivery(values) {
    try {
      yield this.root.client.mutate({
        mutation: CREATE_DELIVERY,
        variables: {
          materialNumber: values.selectedMaterial,
          deliveryDate: values.deliveryDate.toISODate(),
          quantity: values.numberOfMaterialsInput,
          orderNumber: values.orderNumberInput,
        },
      });
      this.root.enqueueSnackbar('Anlieferung hinzugefügt', { variant: 'success' });

      this.newDeliveryModalVisible = false;
      this.replaceData();
    } catch (error) {
      this.root.handleError(error);
    }
  })

  getSelectedDelivery = () => {
    if (this.focussedColumn === 'nextDelivery') {
      return this.focussedRow.nextDelivery;
    }

    const dayOffset = parseInt(this.focussedColumn.match(/\d+/)[0], 10);
    const focussedDay = this.startingDay.plus({ days: dayOffset }).toFormat('yyyy-MM-dd');
    return this.focussedRow.deliveries.filter(delivery => (
      delivery.deliveryDate === focussedDay
    ))[0];
  }


  @action.bound
  updateDelivery = async (values) => {
    try {
      const selectedDelivery = this.getSelectedDelivery();
      const result = await this.root.client.mutate({
        mutation: UPDATE_DELIVERY,
        variables: {
          pk: selectedDelivery.pk,
          deliveryDate: values.deliveryDate.toISODate(),
          quantity: values.numberOfMaterialsInput,
        },
      });
      if (result.data.updateDelivery.deliveryLine === null) {
        this.root.enqueueSnackbar('Anlieferung gelöscht', { variant: 'success' });
      } else {
        this.root.enqueueSnackbar('Anlieferung aktualisiert', { variant: 'success' });
      }

      runInAction(() => {
        this.updateDeliveryModalVisible = false;
      });

      this.replaceData();
    } catch (error) {
      this.root.handleError(error);
    }
  }

  @action.bound
  deleteDelivery = async () => {
    try {
      const selectedDelivery = this.getSelectedDelivery();
      await this.root.client.mutate({
        mutation: DELETE_DELIVERY,
        variables: {
          pk: selectedDelivery.pk,
        },
      });
      this.root.enqueueSnackbar('Anlieferung gelöscht', { variant: 'success' });
      this.updateSheetUploads();

      runInAction(() => {
        this.updateDeliveryModalVisible = false;
      });

      this.replaceData();
    } catch (error) {
      this.root.handleError(error);
    }
  }

  @action.bound
  deleteOverdueDelivery = async (pk) => {
    try {
      await this.root.client.mutate({
        mutation: DELETE_DELIVERY,
        variables: {
          pk,
        },
      });
      this.root.enqueueSnackbar('Anlieferung gelöscht', { variant: 'success' });
      this.updateSheetUploads();
      this.replaceData();
    } catch (error) {
      this.root.handleError(error);
    }
  }

  @action.bound
  showNewDeliveryModal() {
    this.newDeliveryModalVisible = true;
  }

  @action.bound
  closeNewDeliveryModal() {
    this.newDeliveryModalVisible = false;
  }

  @action.bound
  showUpdateDeliveryModal() {
    this.updateDeliveryModalVisible = true;
  }

  @action.bound
  closeUpdateDeliveryModal() {
    this.updateDeliveryModalVisible = false;
  }

  @action.bound
  focusOnRow(row) {
    this.focussedRow = row;
  }

  @action.bound
  focusOnColumn(column) {
    this.focussedColumn = column;
  }

  @action.bound
  setStartingDay(startingDay) {
    this.startingDay = startingDay.startOf('day');
    this.replaceData();
  }

  @action.bound
  setSort(sortObject) {
    this.sortObject = sortObject;
    this.replaceData();
  }

  @action.bound
  activateDashboardFilter(newFilter) {
    if (newFilter[0] === 'all') {
      this.deactivateDashboardFilter();
    } else {
      const activePresetDispos = this.root.user.user.profile.dispo_presets
        .filter(f => newFilter.includes(f.pk))
        .reduce((allActive, profile) => [...allActive, ...profile.dispos.map(d => d.code)], []);
      const activeOwnDispos = newFilter.includes('own')
        ? this.root.user.user.profile.dispos.map(d => d.code)
        : [];
      this.dashboardFilter = [...activePresetDispos, ...activeOwnDispos].join(', ');
      this.filterFromDashboard = true;
      this.replaceData();
    }
  }

  @action.bound
  deactivateDashboardFilter() {
    this.dashboardFilter = undefined;
    this.filterFromDashboard = false;
    this.replaceData();
  }
}
