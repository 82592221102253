import React from 'react';
import PropTypes from 'prop-types';
import { DateTime } from 'luxon';

import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { DatePicker, KeyboardDatePicker } from '@material-ui/pickers';
import { rangeModes } from '../../../Stores/reporting';
import CustomSelect from '../../CustomSelect';

const RangeConfig = ({
  classes, rangeMode, onModeChange, startDate, endDate, onStartDateChange, onEndDateChange,
  earliestStartDate, latestEndDate,
}) => {
  const handleRangeModeChange = (event) => {
    onModeChange(event.target.value);
  };

  const renderDatePreview = (date) => {
    const formatedDate = DateTime.fromISO(date).toFormat('dd.MM.yyyy');
    return (
      <div className={classes.dateContainer}>
        <span/>
        <TextField
          disabled
          margin="dense"
          className={classes.dateField}
          variant="filled"
          value={formatedDate}
        />
      </div>
    );
  };

  const renderSelectors = () => {
    const startDateTime = DateTime.fromISO(earliestStartDate);
    const endDateTime = DateTime.fromISO(latestEndDate);

    if (rangeMode === 'years') {
      const startOfPeriod = startDateTime.startOf('year');
      const endOfPeriod = endDateTime.endOf('year');

      const years = endOfPeriod.diff(startOfPeriod, 'years').as('years');
      const handleDateChange = (onChange) => (event) => {
        onChange(event.target.value);
      };

      const getStartOption = (year) => ({
        value: `${year - 1}-10-01`,
        label: `GJ ${year}`,
      });

      const getEndOption = (year) => ({
        value: `${year}-09-30`,
        label: `GJ ${year}`,
      });

      // improvement needed
      const startOptions = [];
      const endOptions = [];

      const startYear = startDateTime.year;
      for (let i = 0; i < years; i += 1) {
        startOptions.push(getStartOption(startYear + i));
        endOptions.push(getEndOption(startYear + i));
      }

      return (
        <>
          <div className={classes.col}>
            <CustomSelect
              label="Von"
              value={startDate}
              onChange={handleDateChange(onStartDateChange)}
              options={startOptions.filter(o => DateTime.fromISO(o.value) < DateTime.fromISO(endDate))}
            />
            {renderDatePreview(startDate)}
          </div>
          <div className={classes.col}>
            <CustomSelect
              label="Bis"
              value={endDate}
              onChange={handleDateChange(onEndDateChange)}
              options={endOptions.filter(o => DateTime.fromISO(o.value) > DateTime.fromISO(startDate))}
            />
            {renderDatePreview(endDate)}
          </div>
        </>

      );
    }

    if (rangeMode === 'quarters') {
      const startOfPeriod = startDateTime.startOf('quarter');
      const endOfPeriod = endDateTime.endOf('quarter');

      const span = endOfPeriod.diff(startOfPeriod, 'months').as('months') / 3;

      const handleDateChange = (onChange) => (event) => {
        onChange(event.target.value);
      };

      const startOptions = [];
      const endOptions = [];

      for (let i = 0; i < span; i += 1) {
        const point = startOfPeriod.plus({ quarters: i });
        const start = point.startOf('quarter');
        const end = point.endOf('quarter');
        startOptions.push({
          value: start.toISODate(),
          // NB Siemens Q1 starts in October of the previous year
          label: start.plus({ months: 3 }).toFormat('Qq yyyy'),
        });
        endOptions.push({
          value: end.toISODate(),
          // NB Siemens Q1 starts in October of the previous year
          label: end.plus({ months: 3 }).toFormat('Qq yyyy'),
        });
      }

      return (
        <>
          <div className={classes.col}>
            <CustomSelect
              label="Von"
              value={startDate}
              onChange={handleDateChange(onStartDateChange)}
              options={startOptions.filter(o => DateTime.fromISO(o.value) < DateTime.fromISO(endDate))}
            />
            {renderDatePreview(startDate)}
          </div>
          <div className={classes.col}>
            <CustomSelect
              label="Bis"
              value={endDate}
              onChange={handleDateChange(onEndDateChange)}
              options={endOptions.filter(o => DateTime.fromISO(o.value) > DateTime.fromISO(startDate))}
            />
            {renderDatePreview(endDate)}
          </div>
        </>

      );
    }

    if (rangeMode === 'months') {
      const handleStartDateChange = (date) => {
        onStartDateChange(date.startOf('month').toISODate());
      };

      const handleEndDateChange = (date) => {
        onEndDateChange(date.endOf('month').toISODate());
      };

      return (
        <>
          <div className={classes.col}>
            <div className={classes.dateContainer}>
              <span>Von </span>
              <DatePicker
                id="start-month-picker"
                views={['year', 'month']}
                openTo="year"
                autoOk
                className={classes.dateField}
                inputVariant="filled"
                minDate={DateTime.fromISO(earliestStartDate).startOf('month').toISODate()}
                maxDate={endDate}
                minDateMessage={`Minimales Startdatum: ${DateTime.fromISO(earliestStartDate).toFormat('dd.MM.yyyy')}`}
                value={new Date(startDate)}
                disableFuture
                onChange={handleStartDateChange}
              />
            </div>
          </div>
          <div className={classes.col}>
            <div className={classes.dateContainer}>
              <span>Bis </span>
              <DatePicker
                id="end-month-picker"
                views={['year', 'month']}
                openTo="year"
                autoOk
                minDateMessage="Enddatum darf nicht vor Startdatum liegen"
                maxDateMessage={`Maximales Enddatum: ${DateTime.fromISO(latestEndDate).toFormat('dd.MM.yyyy')}`}
                className={classes.dateField}
                inputVariant="filled"
                minDate={startDate}
                maxDate={DateTime.fromISO(latestEndDate).endOf('month').toISODate()}
                value={new Date(endDate)}
                onChange={handleEndDateChange}
              />
            </div>
          </div>
        </>
      );
    }

    const handleDateChange = (onChange) => (date) => {
      if (date) {
        onChange(date.toISODate());
      }
    };

    return (
      <>
        <div className={classes.col}>
          <div className={classes.dateContainer}>
            <span>Von </span>
            <KeyboardDatePicker
              id="start-date-picker"
              views={['year', 'month', 'date']}
              autoOk
              className={classes.dateField}
              inputVariant="filled"
              format="dd.MM.yyyy"
              minDate={earliestStartDate}
              maxDate={endDate}
              minDateMessage={`Minimales Startdatum: ${DateTime.fromISO(earliestStartDate).toFormat('dd.MM.yyyy')}`}
              maxDateMessage="Startdatum darf nicht nach Enddatum liegen"
              invalidDateMessage="Invalides Datumsformat"
              value={new Date(startDate)}
              onChange={handleDateChange(onStartDateChange)}
            />
          </div>
        </div>
        <div className={classes.col}>
          <div className={classes.dateContainer}>
            <span>Bis </span>
            <KeyboardDatePicker
              id="end-date-picker"
              views={['year', 'month', 'date']}
              autoOk
              className={classes.dateField}
              minDateMessage="Enddatum darf nicht vor Startdatum liegen"
              maxDateMessage={`Maximales Enddatum: ${DateTime.fromISO(latestEndDate).toFormat('dd.MM.yyyy')}`}
              invalidDateMessage="Invalides Datumsformat"
              format="dd.MM.yyyy"
              minDate={startDate}
              maxDate={latestEndDate}
              inputVariant="filled"
              value={new Date(endDate)}
              onChange={handleDateChange(onEndDateChange)}
            />
          </div>
        </div>
      </>
    );
  };

  return (
    <div className={classes.container}>
      <div className={classes.col}>
        <CustomSelect
          value={rangeMode}
          onChange={handleRangeModeChange}
          options={rangeModes}
        />
      </div>
      {renderSelectors()}
    </div>
  );
};

RangeConfig.propTypes = {
  classes: PropTypes.object,
  rangeMode: PropTypes.string,
  onModeChange: PropTypes.func,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  onStartDateChange: PropTypes.func,
  onEndDateChange: PropTypes.func,
  earliestStartDate: PropTypes.string,
  latestEndDate: PropTypes.string,
};

const styles = (theme) => ({
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignContent: 'center',
    padding: theme.spacing(1),
    minHeight: '150px',
  },
  col: {
    display: 'flex',
    flexDirection: 'column',
  },

  dateContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    '& > span': {
      flex: 1,
      fontWeight: 'bold',
    },
    '& > div': {
      flex: 3,
    },
  },

  dateField: {
    fontWeight: 'bold',
    '&:hover:before': {
      borderBottomWidth: '1px',
      borderBottomStyle: 'solid !important',
      borderColor: theme.palette.secondary.main,
    },
    '& input': {
      paddingTop: '16px',
      paddingBottom: '16px',
    },
  },
});

export default withStyles(styles)(RangeConfig);
