/* global Raven */
import 'core-js/stable';
import 'whatwg-fetch';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { unregister } from './registerServiceWorker';

ReactDOM.render(<App />, document.getElementById('root'));
if (process.env.NODE_ENV === 'production') {
  Raven.config(`https://${process.env.REACT_APP_SENTRY_DSN}@${process.env.REACT_APP_SENTRY_HOST}/7`).install();
}

unregister();
