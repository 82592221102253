import React, { Component } from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { withSnackbar } from 'notistack';
import { withApollo } from 'react-apollo';
import Autocomplete from '../../Autocomplete';
import { trimLead0s } from '../../../utils/displayUtils';

const fieldArray = [
  'number',
  'name',
];

export const AllSuppliersQuery = gql`
query allSuppliersQuery (
  $searchTerm: String
){
  allSuppliers(
    first: 5,
    numberOrName: $searchTerm,
  ) {
    edges {
      node {
        ${fieldArray.join('\n')}
      }
    }
  }
}`;

class SupplierFilter extends Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    client: PropTypes.object.isRequired,
    value: PropTypes.array,
    onChange: PropTypes.func,
    label: PropTypes.string,
    single: PropTypes.bool,
  };

  getAvailableChoices = (results) => {
    const choices = [];

    results.forEach((row) => {
      if (!choices.some((choice) => choice.number === row.number)) {
        const rowData = {};
        fieldArray.forEach((field) => {
          rowData[field] = row[field];
        });
        choices.push(rowData);
      }
    });
    choices.sort((a, b) => (a.number > b.number));
    return choices;
  }

  fetchList = async (value) => {
    const data = await this.props.client.query({
      query: AllSuppliersQuery,
      variables: {
        searchTerm: value,
      },
    });
    if (data) {
      const results = data.data.allSuppliers.edges.map((edge) => ({
        number: edge.node.number,
        name: edge.node.name,
      }));
      return this.getAvailableChoices(results).map((choice) => ({
        value: choice.number,
        label: `${trimLead0s(choice.number)} - ${choice.name}`,
      }));
    }
    return [];
  }

  render() {
    const {
      onChange,
      value,
      label,
      name,
      single,
    } = this.props;

    return (
      <Autocomplete
        async
        isMulti={!single}
        value={value}
        loadOptions={this.fetchList}
        onChange={onChange}
        label={label}
        name={name}
      />
    );
  }
}

export default withSnackbar(withApollo(SupplierFilter));
