import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Modal from '@material-ui/core/Modal';
import { withStyles } from '@material-ui/core/styles';

const ConfirmDeleteModal = ({
  open,
  onClose,
  onCancel,
  onConfirm,
  classes,
}) => (
  <Modal
    title="Bestätigen"
    open={open}
    onClose={onClose}
    disableBackdropClick
  >
    <div className={classes.paper}>
      <Typography variant="h6">Bestätigen</Typography>
      <div className={classes.buttons}>
        <Button onClick={onCancel} aria-label="Abbrechen">ABBRECHEN</Button>
        <Button onClick={onConfirm} aria-label="Entfernen">ENTFERNEN</Button>
      </div>
    </div>
  </Modal>
);

ConfirmDeleteModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func,
  classes: PropTypes.object,
};

const style = theme => ({
  paper: {
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    position: 'absolute',
    width: theme.spacing(50),
    backgroundColor: theme.palette.background.paper,
    boxShoadow: theme.shadows[5],
    padding: theme.spacing(4),
    outline: 'none',
  },
  buttons: {
    display: 'flex',
    justifyContent: 'end',
  },
});

export default (withStyles(style)(ConfirmDeleteModal));
