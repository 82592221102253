import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import { withStyles } from '@material-ui/core/styles';
import { DateTime } from 'luxon';

import { trimLead0s } from '../../utils/displayUtils';
import { reformatIsoDate, reformatIsoDateTime } from '../../utils/dateTools';
import RemarkCell from './RemarkCell';
import { updateExpectedMissingPartDetails } from './Mutations/expectedMissingParts';
import { getMissingParts } from '../../Containers/Dashboard/Home/Api/Queries/GetMissingParts';
import styles from './styles';

function renderSimpleCell(props) {
  return (
    <div
      style={{
        margin: 5,
      }}
    >
      <Typography>
        {props.row}
      </Typography>
    </div>
  );
}

renderSimpleCell.propTypes = {
  row: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.element,
  ]),
};

const renderLongTextCell = withStyles(styles)(({ row, classes }) => (
  <div
    className={classes.longTextCell}
  >
    <Typography>
      {row}
    </Typography>
  </div>
));

renderLongTextCell.propTypes = {
  row: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  classes: PropTypes.object,
};

function renderNumberCell(props) {
  const numberProps = {
    ...props,
    row: trimLead0s(props.row),
  };
  return renderSimpleCell(numberProps);
}

renderNumberCell.propTypes = {
  row: PropTypes.string,
};

function renderDateCell(props) {
  return renderSimpleCell({
    ...props,
    row: reformatIsoDate(props.row),
  });
}

function renderDateTimeCell(props) {
  return renderSimpleCell({
    ...props,
    row: reformatIsoDateTime(props.row),
  });
}

renderDateCell.propTypes = {
  row: PropTypes.string,
};

function renderAddDeliveryButton(onClick) {
  const renderFunction = props => (
    <div style={{
      width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center',
    }}
    >
      <IconButton
        id={`${props.row}-add-delivery`}
        color="secondary"
        onClick={() => onClick(props.row)}
        style={{
          width: '1em', height: '1em', padding: 0,
        }}
        data-testid="add-delivery"
        aria-label="Lieferung hinzufügen"
      >
        <Badge
          badgeContent={(
            <ShoppingCartIcon
              style={{ width: '16px', height: '16px', color: '#000' }}
            />
          )}
          overlap="circle"
        >
          <AddCircleIcon style={{ color: 'rgba(191, 191, 191, 1)' }}/>
        </Badge>
      </IconButton>
    </div>
  );

  renderFunction.propTypes = {
    row: PropTypes.string,
  };

  return renderFunction;
}

renderAddDeliveryButton.propTypes = {
  onClick: PropTypes.func,
};

function xlsxDateParser(value) {
  if (value) {
    return DateTime.fromISO(value).toLocaleString(DateTime.DATE_SHORT);
  }
  return '';
}

function xlsxDateTimeParser(value) {
  if (value) {
    return DateTime.fromISO(value).toLocaleString(DateTime.DATETIME_SHORT);
  }
  return '';
}

export const getColumnList = ({
  isDownload,
  showConfirmedDate,
  showKoje,
  expanded,
  editableRemark,
  client,
  showCallDate,
  handleError,
  showDeliveryButton,
  openDeliveryModal,
  tableType,
  location,
}) => {
  const getRemarkColumn = () => {
    if (editableRemark) {
      const handleRemarkUpdate = async (uniqueId, newValue) => {
        const [materialNumber, salesOrderNumber] = uniqueId.split('-');
        try {
          await client.mutate({
            mutation: updateExpectedMissingPartDetails,
            variables: {
              materialNumber,
              salesOrderNumber,
              remark: newValue,
            },
            update: (cache, { data }) => {
              const query = cache.readQuery({ query: getMissingParts, variables: { location } });
              const { groupedExpectedMissingParts } = query;
              const {
                materialNumber: newMaterialNumber,
                salesOrderNumber: newSalesOrderNumber,
                remark,
              } = data.updateExpectedMissingPartDetails.expectedMissingPartDetails;
              const newGroupedData = groupedExpectedMissingParts.map(group => {
                if (group.materialNumber === newMaterialNumber) {
                  return {
                    ...group,
                    expectedMissingParts: {
                      ...group.expectedMissingParts,
                      edges: group.expectedMissingParts.edges.map(edge => ({
                        ...edge,
                        node: edge.node.salesOrderNumber === newSalesOrderNumber ? {
                          ...edge.node,
                          remark,
                        } : edge.node,
                      })),
                    },
                  };
                }
                return group;
              });

              cache.writeQuery({
                query: getMissingParts,
                data: { groupedExpectedMissingParts: newGroupedData },
              });
            },
          });
        } catch (error) {
          handleError(error, 'Beim Aktualisieren der Bemerkung ist ein Fehler aufgetreten');
        }
      };

      return {
        header: 'Bemerkung',
        accessor: 'remark',
        Cell: RemarkCell,
        keyColumn: 'uniqueId',
        onChange: handleRemarkUpdate,
        width: 200,
      };
    }
    return {
      header: 'Bemerkung',
      accessor: 'remark',
      Cell: renderLongTextCell,
      keyColumn: 'uniqueId',
      width: 200,
    };
  };

  const baseTableColumns = [
    {
      header: 'Material',
      accessor: 'materialNumber',
      sortId: 'materialNumber',
      Cell: renderNumberCell,
      xlsxParser: trimLead0s,
      width: 100,
      sortable: true,
    },
    {
      header: 'Materialkurztext',
      accessor: 'materialDescription',
      Cell: renderSimpleCell,
      sortId: 'materialDescription',
      sortable: true,
    },
    {
      header: 'Dispo',
      sortId: 'supplierCode',
      accessor: 'supplierCode',
      Cell: renderSimpleCell,
      width: 80,
      sortable: true,
    },
    {
      header: 'Menge',
      accessor: 'quantity',
      sortId: 'quantity',
      Cell: renderSimpleCell,
      width: 80,
      sortable: true,
      sortSum: true,
    },
    ...(isDownload || expanded ? [{
      header: 'KAUF',
      accessor: 'salesOrderNumber',
      Cell: renderNumberCell,
      xlsxParser: trimLead0s,
      width: 120,
    }] : []),
    {
      header: 'FAUF',
      accessor: 'productionOrderNumber',
      Cell: renderNumberCell,
      xlsxParser: trimLead0s,
      width: 120,
    },
    {
      header: 'OML Start',
      accessor: 'omlStart',
      Cell: renderDateCell,
      xlsxParser: xlsxDateParser,
      width: 100,
    },
    ...(showCallDate ? [{
      header: 'Abrufdatum',
      accessor: 'callDate',
      Cell: renderDateCell,
      xlsxParser: xlsxDateParser,
      width: 100,
    }] : []),
    {
      header: 'Land',
      accessor: 'customerCountryCode',
      Cell: renderSimpleCell,
      width: 60,
    },
    ...(showKoje ? [{
      header: 'Koje',
      accessor: 'kojeNumber',
      Cell: renderSimpleCell,
      width: 80,
    }] : []),
    {
      header: 'Ladetermin',
      accessor: 'loadDate',
      Cell: renderDateCell,
      xlsxParser: xlsxDateParser,
      width: 100,
    },
    ...(showConfirmedDate ? [{
      header: 'Best. Termin',
      accessor: 'confirmed',
      Cell: renderDateTimeCell,
      xlsxParser: xlsxDateTimeParser,
      width: 150,
    }] : []),
    ...(showDeliveryButton ? [{
      key: 'delivery-button',
      header: '',
      accessor: 'materialNumber',
      Cell: renderAddDeliveryButton(openDeliveryModal),
      width: 35,
    }] : []),
    getRemarkColumn(),
  ];

  const deliveriesTodayTableColumns = [
    {
      header: 'Material',
      accessor: 'materialNumber',
      sortId: 'materialNumber',
      Cell: renderNumberCell,
      xlsxParser: trimLead0s,
    },
    {
      header: 'Dispo',
      sortId: 'disp',
      accessor: 'disp',
      Cell: renderSimpleCell,
      width: 80,
    },
    {
      header: 'Menge',
      accessor: 'quantity',
      sortId: 'quantity',
      Cell: renderSimpleCell,
      width: 80,
    },
  ];

  switch (tableType) {
    case 'base':
      return baseTableColumns;
    case 'deliveries-today':
      return deliveriesTodayTableColumns;
    default:
      return baseTableColumns;
  }
};
