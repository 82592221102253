export const trimLead0s = (string) => {
  if (typeof string !== 'string') {
    return string;
  }
  return string.replace(/^0+/, '');
};

export const calculateScrollbarWidth = () => {
  const scrollDiv = document.createElement('div');
  scrollDiv.className = 'scrollbar-measure';
  document.body.appendChild(scrollDiv);

  const scrollbarWidth = `${scrollDiv.offsetWidth - scrollDiv.clientWidth}px`;
  document.body.removeChild(scrollDiv);
  return scrollbarWidth;
};

export const getScrollbarWidth = () => {
  const storedLocal = localStorage.getItem('scrollbar-width');
  if (storedLocal) {
    return storedLocal;
  }
  const fresh = calculateScrollbarWidth();
  localStorage.setItem('scrollbar-width', fresh);
  return fresh;
};
