import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Provider } from 'mobx-react';
import { resolve } from 'react-resolver';
import { ApolloProvider } from 'react-apollo';
import { ApolloClient } from 'apollo-client';
import { createHttpLink } from 'apollo-link-http';
import { setContext } from 'apollo-link-context';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { Settings } from 'luxon';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { SnackbarProvider } from 'notistack';
import LuxonUtils from '@date-io/luxon';
import CssBaseline from '@material-ui/core/CssBaseline';

import Store from './Stores';
import Dashboard from './Containers/Dashboard';
import Login from './Containers/Login';
import theme from './theme';
import { baseUrl } from './Api/index';
import MobxNotifier from './Components/MobxNotifier';

import './App.less';

Settings.defaultLocale = 'de';

// There is a bug in luxon utils regarding year range in the version
// which works for production material-ui/pickers. This simply replaces
// the function with that from the latest version of the same library
LuxonUtils.prototype.getYearRange = function (start, end) {
  const startDate = this.date(start).startOf('year');
  const endDate = this.date(end).endOf('year');

  let current = startDate;
  const years = [];

  while (current < endDate) {
    years.push(current);
    current = current.plus({ year: 1 });
  }

  return years;
};

const store = new Store();

@resolve('store', async () => {
  const { user: userPromise, ui: uiPromise } = store;
  const user = await userPromise; // This was a promise from `hydrate`
  const ui = await uiPromise;

  store.user = user; // Set the mobx object
  store.ui = ui;

  if (store.user.token && store.user.getTimeToTokenExpiry() > 0) {
    store.user.refreshUserToken();
    store.notifications.getNotifications();
  }

  const httpLink = createHttpLink({
    uri: `${baseUrl}/graphql/?`,
  });

  const authLink = setContext((_, { headers }) => ({
    headers: {
      ...headers,
      Authorization: user.token ? `JWT ${user.token}` : '',
    },
  }));

  const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
  });

  store.client = client;

  return store;
})

class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <ApolloProvider client={store.client}>
          <MuiPickersUtilsProvider utils={LuxonUtils}>
            <MuiThemeProvider theme={theme}>
              <>
                <CssBaseline />
                <SnackbarProvider maxSnack={3}>
                  <>
                    <MobxNotifier />
                    <Router>
                      <div className="App">
                        <Switch>
                          <Route exact path="/login" component={Login}/>
                          <Route path="/" component={Dashboard}/>
                        </Switch>
                      </div>
                    </Router>
                  </>
                </SnackbarProvider>
              </>
            </MuiThemeProvider>
          </MuiPickersUtilsProvider>
        </ApolloProvider>
      </Provider>
    );
  }
}

export default App;
