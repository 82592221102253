import axios from 'axios';

/* When no baseUrl is defined, assume relative url (currently done for prod) */
export const baseUrl = process.env.REACT_APP_API_BASE_URL || '';
export const downloadUrl = process.env.REACT_APP_API_DOWNLOAD_URL || `${baseUrl}/admin/static`;
export const reportingUrl = process.env.REACT_APP_API_REPORTING_URL || `${baseUrl}/reporting`;
export const htmlReportGeneratorUrl = process.env.REACT_APP_REPORT_HTML_URL;
export const notificationUrl = process.env.REACT_APP_NOTIFICATION_URL;


export default axios.create({
  baseURL: `${baseUrl}/api`,
});

export const notificationApi = axios.create({
  baseURL: `${notificationUrl}/api`,
});

export function extractErrorText(error) {
  if (error.message) {
    return error.message;
  }
  if (error.data && error.data.detail) {
    return error.data.detail;
  }
  return 'Error';
}
