import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import Collapse from '@material-ui/core/Collapse';
import CardActions from '@material-ui/core/CardActions';
import IconButton from '@material-ui/core/IconButton';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { sortBy, prop } from 'ramda';
import SimpleTableRow from './SimpleTableRow';
import { trimLead0s } from '../../utils/displayUtils';

export function getGroups(data, groupProperty = {}) {
  const { parentGroupBy, childSortBy } = groupProperty;
  const sortByChildProp = sortBy(prop(childSortBy));

  const groups = data.reduce((acc, row) => {
    acc[row[parentGroupBy]] = acc[row[parentGroupBy]] || [];
    acc[row[parentGroupBy]].push(row);
    return acc;
  }, {});

  Object.keys(groups).forEach((groupName) => {
    groups[groupName] = sortByChildProp(groups[groupName]);
  });

  return groups;
}

export const defaultGroupProperty = {
  parentGroupBy: 'materialNumber',
  childSortBy: '',
};

class TableGroup extends PureComponent {
  constructor(props) {
    super(props);

    const expanded = this.getInitialExpandedObject();

    this.state = {
      expanded,
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.data !== prevProps.data) {
      this.updateExpandedGroups();
    }
  }

  getInitialExpandedObject = () => {
    const {
      data,
      groupProperty,
      startCollapsed,
    } = this.props;
    const groups = getGroups(data, groupProperty);
    const expanded = {};
    Object.keys(groups).forEach((groupName) => {
      expanded[groupName] = !startCollapsed;
    });

    return expanded;
  }

  updateExpandedGroups = () => {
    const {
      data,
      groupProperty,
      startCollapsed,
    } = this.props;

    this.setState((prevState) => {
      const groups = Object.keys(getGroups(data, groupProperty));
      const newGroups = groups
        .filter(group => !Object.keys(prevState.expanded).includes(group))
        .reduce((acc, cur) => ({ ...acc, [cur]: !startCollapsed }), {});
      return ({
        expanded: {
          ...prevState.expanded,
          ...newGroups,
        },
      });
    });
  }

  handleExpandClick = key => () => {
    this.setState(prevState => ({
      expanded: {
        ...prevState.expanded,
        [key]: !prevState.expanded[key],
      },
    }));
  }

  isExpanded = (key) => this.state.expanded[key] || this.props.forceExpand

  renderGroupLabel = (key) => {
    const { groupProperty } = this.props;
    const { parentGroupBy } = groupProperty;
    if (parentGroupBy === 'materialNumber') {
      return trimLead0s(key);
    }
    return key;
  }

  render() {
    const {
      classes,
      groupProperty,
      data,
      columns,
      onRowClick,
      highlightField,
      highlightValue,
      grouped,
      keyColumn,
      rowHoverEffect,
      rowIntroId,
      rowToolTip,
      keyColumns,
      groupSort,
      groupRow,
      startCollapsed,
      forceExpand,
      forceHighlight,
      tableIntroId,
      ...other
    } = this.props;

    if (grouped) {
      const groups = getGroups(data, groupProperty);
      const keys = groupSort ? Object.keys(groups).sort(groupSort) : Object.keys(groups);
      return keys.map((key, index) => (
        <TableRow
          key={`${key}-group`}
          className={`${classes.groupRow} ${classes.tableRow}`}
          component="div"
        >
          <TableCell component="div" className={`${classes.expandRow} ${classes.tableRow}`}>
            <IconButton
              id={index === 0 && tableIntroId === 'expected-parts' ? 'expand-row-button' : null}
              className={this.isExpanded(key) ? classes.expandOpen : classes.expand}
              onClick={this.handleExpandClick(key)}
              aria-expanded={this.isExpanded(key)}
              aria-label="Show more"
            >
              <ExpandMoreIcon />
            </IconButton>
            {(groupRow && groupRow(groups[key])) || (
              <CardActions className={classes.groupHeading} >
                <b>{this.renderGroupLabel(key)}</b>
              </CardActions>
            )}
            <Collapse in={this.isExpanded(key)} timeout="auto" unmountOnExit>
              <Table component="div" className={classes.table} {...other}>
                <TableBody component="div" className={classes.tableBody}>
                  {
                    groups[key]
                      .filter((row) => (
                        !highlightField || row[highlightField] !== highlightValue
                      ))
                      .map((row, groupIndex) => {
                        let rowKey;
                        if (keyColumn) {
                          rowKey = row[keyColumn] + groupIndex;
                        } else if (keyColumns) {
                          rowKey = keyColumns.reduce((acc, cur) => `${acc}-${row[cur]}`, row[keyColumns[0]]);
                        } else {
                          rowKey = groupIndex;
                        }
                        return (
                          <SimpleTableRow
                            key={rowKey}
                            {...{
                              row,
                              rowIntroId,
                              rowToolTip,
                              ...this.props,
                            }}
                          />
                        );
                      })
                  }
                </TableBody>
              </Table>
            </Collapse>
          </TableCell>
        </TableRow>
      ));
    }
    return data
      .filter((row) => (
        !highlightField || row[highlightField] !== highlightValue
      ))
      .map((row, index) => (
        <SimpleTableRow
          key={index}
          {...{
            row,
            ...this.props,
          }}
        />
      ));
  }
}

TableGroup.propTypes = {
  data: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  groupProperty: PropTypes.object,
  classes: PropTypes.object.isRequired,
  onRowClick: PropTypes.func,
  highlightField: PropTypes.string,
  highlightValue: PropTypes.any,
  grouped: PropTypes.bool,
  keyColumn: PropTypes.string,
  keyColumns: PropTypes.array,
  rowHoverEffect: PropTypes.bool,
  rowIntroId: PropTypes.string,
  rowToolTip: PropTypes.any,
  groupSort: PropTypes.func,
  groupRow: PropTypes.func,
  startCollapsed: PropTypes.bool,
  forceExpand: PropTypes.bool,
  forceHighlight: PropTypes.bool,
  tableIntroId: PropTypes.string,
};

const style = theme => ({
  expand: {
    position: 'absolute',
    transform: 'rotate(0deg)',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
    right: 10,
    top: -6,
  },
  expandOpen: {
    transform: 'rotate(180deg)',
    position: 'absolute',
    right: 10,
    top: -6,
  },
  expandRow: {
    position: 'relative',
    flexDirection: 'column',
    padding: 0,
    '&:last-child': {
      paddingRight: '0',
    },
    borderBottom: 'none',
  },
  groupRow: {
    height: 'auto',
  },
  groupHeading: {
    display: 'flex',
    justifyContent: 'space-between',
    borderBottom: '1px solid lightgrey',
  },
  tableRow: {
  },
  table: {
  },
  tableBody: {
  },
  tableCell: {
  },
  dataRow: {},
});

export default withStyles(style)(TableGroup);
