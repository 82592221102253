import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject, observer, PropTypes as MobxPropTypes } from 'mobx-react';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import classNames from 'classnames';
import { DateTime } from 'luxon';
import { withApollo } from 'react-apollo';
import { equals } from 'ramda';

import styles from './styles';
import SimpleTable, { tableStyles } from '../SimpleTable';
import { defaultGroupProperty, getGroups } from '../SimpleTable/TableGroup';
import SimpleTableRow from '../SimpleTable/SimpleTableRow';
import { defaultSort } from '../../Stores/source';
import FilterForm from './FilterForm';
import { getColumnList } from './columnGenerator';

const defaultDateFilter = {
  dateFilter: true,
  from: null,
  to: null,
};

export const defaultFilter = {
  materialNumber: [],
  productionOrderNumber: [],
  salesOrderNumber: [],
  omlStart: defaultDateFilter,
};

@inject('store')
@observer
class ListCard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sortObject: defaultSort,
      filterObject: defaultFilter,
    };
  }

  handleOnSort = (sortObject) => {
    this.setState({ sortObject });
  }

  sortData = (data) => {
    const { sortObject } = this.state;
    const { field, ascending, sortSum } = sortObject;

    const sortFn = (a, b) => {
      let comp = 0;
      if (a > b) comp = 1;
      if (b > a) comp = -1;

      return ascending ? comp : comp * -1;
    };

    if (field !== '') {
      const sortAllParts = (a, b) => sortFn(a[field], b[field]);

      const sortedMissingParts = data.sort(sortAllParts);

      if (sortSum) {
        const groups = getGroups(sortedMissingParts, defaultGroupProperty);

        const sortGroupSum = (a, b) => {
          const sumA = a.reduce((total, row) => total + row[field], 0);
          const sumB = b.reduce((total, row) => total + row[field], 0);

          return sortFn(sumA, sumB);
        };

        const sortedGroups = Object.values(groups).sort(sortGroupSum);
        return sortedGroups.flat();
      }
      return sortedMissingParts;
    }
    return data;
  }

  handleFilterChange = (field, newValue) => {
    this.setState(prevState => ({
      filterObject: {
        ...prevState.filterObject,
        [field]: newValue,
      },
    }));
  }

  resetFilter = () => {
    this.setState({
      filterObject: defaultFilter,
    });
  }

  removeFilterLine = (filterKey) => {
    const [filterClass, filterValue] = filterKey.split('.');
    this.setState(prevState => {
      if (prevState.filterObject[filterClass].dateFilter) {
        return {
          filterObject: {
            ...prevState.filterObject,
            [filterClass]: defaultDateFilter,
          },
        };
      }
      return {
        filterObject: {
          ...prevState.filterObject,
          [filterClass]: prevState.filterObject[filterClass].filter((filterEntry) => filterEntry.value !== filterValue),
        },
      };
    });
  }

  // force expand all grouped row temporarily when filter is active
  shouldForceExpand = () => !equals(this.state.filterObject, defaultFilter)

  render() {
    const {
      classes,
      loading,
      introId,
      showCallDate,
      groupProperty,
      store,
      editableRemark,
      title,
      titleElement,
      errorTitle,
      missingParts,
      deliveries,
      canExport,
      downloadSuffix,
      client,
      showConfirmedDate,
      showKoje,
      showDeliveryButton,
      openDeliveryModal,
      initialSort,
    } = this.props;

    const {
      sortObject,
      filterObject,
    } = this.state;

    const renderTable = () => {
      if (missingParts) {
        const extractValue = array => array.map(row => row.value);

        const filteredMissingParts = missingParts
          .filter(part => {
            if (filterObject.materialNumber.length > 0) {
              const filteredNumbers = extractValue(filterObject.materialNumber);
              return filteredNumbers.includes(part.materialNumber);
            }
            return true;
          })
          .filter(part => {
            if (filterObject.productionOrderNumber.length > 0) {
              const filteredNumbers = extractValue(filterObject.productionOrderNumber);
              return filteredNumbers.includes(part.productionOrderNumber);
            }
            return true;
          })
          .filter(part => {
            if (filterObject.salesOrderNumber.length > 0) {
              const filteredNumbers = extractValue(filterObject.salesOrderNumber);
              return filteredNumbers.includes(part.salesOrderNumber);
            }
            return true;
          })
          .filter(part => {
            if (filterObject.omlStart && filterObject.omlStart.from && filterObject.omlStart.to) {
              const { from, to } = filterObject.omlStart;
              const partStart = DateTime.fromISO(part.omlStart);
              return partStart >= from.startOf('day') && partStart <= to.endOf('day');
            }
            return true;
          });

        if (initialSort && !sortObject.field) {
          this.setState({ sortObject: initialSort });
        }

        const sortedMissingParts = this.sortData(filteredMissingParts);

        const tableColumns = getColumnList({
          client,
          showConfirmedDate,
          showKoje,
          expanded: true,
          editableRemark,
          showCallDate,
          handleError: store.handleError,
          showDeliveryButton,
          openDeliveryModal,
          location: store.user.selectedLocation.value,
        });

        const headerColumns = tableColumns.map((column) => {
          if (column.accessor === 'remark' || column.accessor === 'kojeNumber') {
            return {
              ...column,
              Cell: () => '',
            };
          }
          return column;
        });

        const downloadColumns = getColumnList({
          client,
          isDownload: true,
          showConfirmedDate,
          showKoje,
          expanded: true,
          editableRemark,
          showCallDate,
          handleError: store.handleError,
          location: store.user.selectedLocation.value,
        });
        return (
          <SimpleTable
            startCollapsed
            expandAll={this.shouldForceExpand()}
            highlightBottomLevel
            canExport={canExport}
            downloadSuffix={downloadSuffix}
            filter
            filterObject={this.state.filterObject}
            filterForm={(
              <FilterForm
                filterObject={this.state.filterObject}
                changeFilter={this.handleFilterChange}
                data={missingParts}
              />
            )}
            handleDeleteFilterLine={this.removeFilterLine}
            filterReset={this.resetFilter}
            groupProperty={groupProperty}
            grouped
            paperless
            rowHoverEffect
            columns={tableColumns}
            downloadColumns={downloadColumns}
            data={sortedMissingParts}
            sortObject={sortObject}
            onSort={this.handleOnSort}
            introId={introId}
            groupRow={(group) => {
              const totalMissing = group.reduce((total, row) => total + row.quantity, 0);
              const deltaQuantity = group[0].deltaQuantity || 0;
              const formatedDeltaQuantity = deltaQuantity > 0 ? `+${deltaQuantity}` : deltaQuantity;
              const rowData = {
                ...group[0],
                quantity: deltaQuantity
                  ? (
                    <span className={classes.deltaQuantity}>
                      {totalMissing} <span className={classes.deltaQuantityValue}>{formatedDeltaQuantity}</span>
                    </span>
                  )
                  : totalMissing,
                productionOrderNumber: '',
                salesOrderNumber: '',
                omlStart: '',
                callDate: '',
                loadDate: '',
                confirmed: '',
                remark: '',
                customerCountryCode: '',
              };
              const groupRowStyles = {
                tableRow: {
                  ...tableStyles.tableRow,
                  borderBottom: 'none',
                },
                table: tableStyles.table,
                tableBody: tableStyles.tableBody,
                tableCell: tableStyles.tableCell,
                dataRow: tableStyles.dataRow,
              };
              const StyledTableRow = withStyles(groupRowStyles)(SimpleTableRow);
              return (
                <StyledTableRow
                  row={rowData}
                  columns={headerColumns}
                />
              );
            }}
          />
        );
      }
      if (deliveries) {
        if (initialSort && !sortObject.field) {
          this.setState({ sortObject: initialSort });
        }
        const tableColumns = getColumnList({
          client,
          handleError: store.handleError,
          tableType: 'deliveries-today',
        });
        const sortedDeliveriesToday = this.sortData(deliveries);
        return (
          <SimpleTable
            preventGrow
            startCollapsed
            rowHoverEffect
            keyColumn="materialNumber"
            data={sortedDeliveriesToday}
            groupProperty={groupProperty}
            grouped
            paperless
            columns={tableColumns}
            groupRow={(group) => {
              const totalQuantity = group.reduce((total, row) => total + row.quantity, 0);
              const rowData = {
                ...group[0],
                quantity: totalQuantity,
              };
              const groupRowStyles = {
                tableRow: {
                  ...tableStyles.tableRow,
                  borderBottom: 'none',
                },
                table: tableStyles.table,
                tableBody: tableStyles.tableBody,
                tableCell: tableStyles.tableCell,
                dataRow: tableStyles.dataRow,
              };
              const StyledTableRow = withStyles(groupRowStyles)(SimpleTableRow);
              return (
                <StyledTableRow
                  row={rowData}
                  columns={tableColumns}
                />
              );
            }}
          />
        );
      }
      return null;
    };

    return (
      <div className={classNames(classes.container, {
        [classes.fullHeight]: deliveries,
      })}
      >
        <div className={classes.controlRow}>
          {title && (
          <Typography variant="h6" color="secondary">
            {title}
            {errorTitle}
          </Typography>
          )}
          {titleElement && (
            titleElement
          )}

          <span>
            {canExport && (
              <span className={classes.downloadSpacer} />
            )}
          </span>
        </div>
        <Paper id={introId} className={classes.paper} elevation={1} square>
          {loading && (
            <div className={classNames(classes.loadingOverlay, classes.overlay)}>
              <CircularProgress />
            </div>
          )}
          {renderTable()}
        </Paper>
      </div>
    );
  }
}
ListCard.propTypes = {
  classes: PropTypes.object,
  title: PropTypes.string,
  titleElement: PropTypes.element,
  errorTitle: PropTypes.element,
  missingParts: PropTypes.array,
  deliveries: PropTypes.array,
  loading: PropTypes.bool,
  introId: PropTypes.string,
  showCallDate: PropTypes.bool,
  canExport: PropTypes.bool,
  downloadSuffix: PropTypes.string,
  groupProperty: PropTypes.object,
  store: MobxPropTypes.objectOrObservableObject,
  editableRemark: PropTypes.bool,
  client: PropTypes.object,
  showConfirmedDate: PropTypes.bool,
  showKoje: PropTypes.bool,
  showDeliveryButton: PropTypes.bool,
  openDeliveryModal: PropTypes.func,
  initialSort: PropTypes.object,
};

export default withApollo(withStyles(styles)(ListCard));
