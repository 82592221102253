import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';

const ReportingSection = ({
  id,
  classes,
  title,
  action,
  children,
  loading,
}) => (
  <div id={id} className={classes.container}>
    <div className={classes.titleContainer}>
      <Typography variant="h6" color="secondary">
        {title}
      </Typography>
      {action}
    </div>
    {loading ? (
      <div className={classes.loadingOverlay}>
        <CircularProgress/>
      </div>
    ) : (
      <div className={classes.content}>
        {children}
      </div>
    )}

  </div>
);

ReportingSection.propTypes = {
  id: PropTypes.string,
  classes: PropTypes.object,
  title: PropTypes.string,
  action: PropTypes.object,
  children: PropTypes.object,
  loading: PropTypes.bool,
};

const styles = () => ({
  container: {
    margin: '0 1em',
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  content: {
    display: 'flex',
    padding: '12px 0',
  },
  logo: {
    height: '36px',
    width: '64px',
    margin: '10px 30px 10px 0',
  },
  loadingOverlay: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: 'rgba(255, 255, 255, 0.5)',
    zIndex: 100,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export default withStyles(styles)(ReportingSection);
