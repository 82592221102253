import Tooltip from '@material-ui/core/Tooltip';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { AreaChart } from 'react-easy-chart';
import ContainerDimensions from 'react-container-dimensions';
import { DateTime } from 'luxon';
import { getMaxYFromData } from '../../utils/graphTools';

// @inject('store')
// @observer
class ChartCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showTooltip: false,
      x: 0,
      y: 0,
      title: '',
    };
  }

  mouseOverHandler = (d, e) => {
    // need to set the locale back - it's in english for the graphing library to parse
    const date = DateTime.fromFormat(d.x, 'd-LLL-yy', { locale: 'en-US' });

    this.setState({
      showTooltip: true,
      x: e.offsetX - 12,
      y: e.offsetY,
      title: `${d.y} (${date.toFormat('dd-LL-yyyy')})`,
    });
  }

  mouseOutHandler = () => {
    this.setState({ showTooltip: false });
  }

  renderTooltip() {
    return (
      <Tooltip title={this.state.title} aria-label={this.state.title} open>
        <div style={{
          width: 24, height: 24, position: 'absolute', left: this.state.x, top: this.state.y,
        }}
        />
      </Tooltip>
    );
  }

  renderLabels() {
    return this.props.labels.map((label) => (
      <Typography key={label} variant="overline" data-testid="tooltip">{label}</Typography>
    ));
  }

  render() {
    const {
      classes,
      data,
      // introId,
    } = this.props;

    const maxY = getMaxYFromData(data);

    return (
      <div className={classes.container}>
        {this.state.showTooltip ? this.renderTooltip() : ''}
        <ContainerDimensions>
          { ({ width, height }) => (
            <>
              <div className={classes.cardheader}>
                <Typography variant="subtitle1">
                  {this.props.title}
                </Typography>
                <div className={classes.labelsCt}>
                  {this.renderLabels()}
                </div>
              </div>
              <div className={classes.chart}>
                <AreaChart
                  axes
                  yTicks={Math.min(12, maxY)}
                  xType="time"
                  tickTimeDisplayFormat="%d-%m"
                  data={this.props.data}
                  width={width}
                  height={height * 0.9}
                  yDomainRange={[0, maxY * 1.25]}
                  areaColors={['rgba(84,178, 180, 0.4)', 'rgba(84,178, 180, 0.8)']}
                  dataPoints
                  mouseOverHandler={this.mouseOverHandler}
                  mouseOutHandler={this.mouseOutHandler}
                />
              </div>
            </>
          )}
        </ContainerDimensions>
      </div>
    );
  }
}

const styles = (theme) => ({
  container: {
    position: 'relative',
  },
  chart: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    justifyContent: 'space-between',
    // position: 'absolute',
    '& svg .dataPoints>circle.data-point': {
      fill: `${theme.palette.secondary.main}!important`,
      r: 3.8,
      opacity: 0.7,
      clipPath: 'inset(1.5px 1.5px 1.5px 1.5px)',
      cursor: 'pointer',
    },
    '& svg .dataPoints>circle.data-point:hover': {
      r: 5,
      clipPath: 'inset(0.95px 0.95px 0.95px 0.95px)',
    },
    '& svg .areaChart>path.line': {
      stroke: `${theme.palette.secondary.main}!important`,
      strokeWidth: 1.6,
      strokeOpacity: 0.7,
    },
    '& svg linearGradient stop:last-child': {
      stopOpacity: `${0.1}!important`,
    },
  },
  cardheader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: '1em',

  },
  labelsCt: {
    display: 'flex',
    '& span': {
      padding: '4px 8px',
      lineHeight: 1,

      color: 'rgba(0,0,0,0.56)',
      '&:nth-child(1)': {
        background: 'linear-gradient(rgba(84,178, 180, 0.3), rgba(84,178, 180, 0.1))',
      },
      '&:nth-child(2)': {
        background: 'linear-gradient(rgba(84,178, 180, 0.7), rgba(84,178, 180, 0.3))',
      },
      '&:not(:last-child)': {
        marginRight: '2em',
      },
    },
  },
});

ChartCard.propTypes = {
  labels: PropTypes.array.isRequired,
  title: PropTypes.string,
  data: PropTypes.array.isRequired,
  classes: PropTypes.object,
  // introId: PropTypes.string,
};

export default withStyles(styles)(ChartCard);
