import gql from 'graphql-tag';

const deliveryLine = `
  deliveryLine {
    pk
    deliveryDate
    quantity
    order {
      orderId
    }
  }
`;

export const CREATE_DELIVERY = gql`
  mutation CreateDeliveryMutation(
    $materialNumber: String,
    $deliveryDate: Date,
    $quantity: Float,
    $orderNumber: String,
  ) {
    createDelivery(
      materialNumber: $materialNumber,
      deliveryDate: $deliveryDate,
      quantity: $quantity,
      orderNumber: $orderNumber,
    ) {
      ${deliveryLine}
    }
  }
`;

export const UPDATE_DELIVERY = gql`
  mutation UpdateDeliveryMutation(
    $pk: Int,
    $deliveryDate: Date,
    $quantity: Float,
  ) {
    updateDelivery(
      pk: $pk,
      deliveryDate: $deliveryDate,
      quantity: $quantity,
    ) {
      ${deliveryLine}
    }
  }
`;

export const DELETE_DELIVERY = gql`
  mutation DeleteDeliveryMutation(
    $pk: Int,
  ) {
    deleteDelivery(
      pk: $pk,
    ) {
      ${deliveryLine}
    }
  }
`;
