import React, { Component } from 'react';
import { observer, inject, PropTypes as MobxPropTypes } from 'mobx-react';
import { toJS } from 'mobx';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import MaterialFilter from './MaterialFilter';
import SupplierFilter from './SupplierFilter';
import GroupFilter from './GroupFilter';
import Autocomplete from '../Autocomplete';
import StatusBox from '../StatusBox';
import { allSupplierCodes } from '../../utils/dispoUtils';

@inject('store')
@observer
class FilterForm extends Component {
  static propTypes = {
    onOk: PropTypes.func.isRequired,
    value: PropTypes.object.isRequired,
    classes: PropTypes.object,
    store: MobxPropTypes.objectOrObservableObject,
  }

  handleSupplierChange = (newSuppliers) => {
    this.props.onOk({
      ...this.props.value,
      supplierNumber: newSuppliers || [],
    });
  }

  handleGroupChange = (newGroups) => {
    this.props.onOk({
      ...this.props.value,
      materialGroup: newGroups || [],
    });
  }

  handleMaterialChange = (newMaterials) => {
    this.props.onOk({
      ...this.props.value,
      materialNumber: newMaterials || [],
    });
  }

  handleSupplierCodeChange = (newSupplierCodes) => {
    const codes = newSupplierCodes || [];
    const {
      user,
    } = this.props.store.user;

    if (codes.some(selectedCode => selectedCode.value === 'own')) {
      this.props.onOk({
        ...this.props.value,
        supplierCode: user.profile.dispos.map(dispo => ({
          value: dispo.code,
          label: dispo.code,
        })),
      });
    } else if (codes.some(selectedCode => selectedCode.value.startsWith('saved-'))) {
      const selectedProfile = codes.find(selectedCode => selectedCode.value.startsWith('saved-'));
      const profileId = selectedProfile.value.split('saved-')[1];
      const { dispos } = user.profile.dispo_presets.find(preset => preset.pk.toString() === profileId);
      this.props.onOk({
        ...this.props.value,
        supplierCode: dispos.map(dispo => ({
          value: dispo.code,
          label: dispo.code,
        })),
      });
    } else {
      this.props.onOk({
        ...this.props.value,
        supplierCode: newSupplierCodes || [],
      });
    }
  }

  handleStatusChange = (newStatus) => {
    this.props.onOk({
      ...this.props.value,
      status: newStatus || [],
    });
  }

  handleDelete = deletedStatus => () => {
    this.props.onOk({
      ...this.props.value,
      status: this.props.value.status.filter(status => status !== deletedStatus),
    });
  }

  render() {
    const {
      classes,
      value,
      store,
    } = this.props;

    const {
      user,
    } = store.user;

    const savedProfiles = (user.profile && user.profile.dispo_presets) || [];

    return (
      <form autoComplete="off" className={classes.form}>
        <MaterialFilter
          value={value.materialNumber ? value.materialNumber.peek() : []}
          onChange={this.handleMaterialChange}
          label="Material"
          name="material"
        />
        <SupplierFilter
          value={value.supplierNumber ? value.supplierNumber.peek() : []}
          onChange={this.handleSupplierChange}
          label="Lieferant"
          name="supplier"
        />
        <GroupFilter
          value={value.materialGroup ? value.materialGroup.peek() : []}
          onChange={this.handleGroupChange}
          label="Material Kategorie"
          name="group"
        />
        <Autocomplete
          isMulti
          value={
            store.ui.filterDispo
              ? []
              : (value.supplierCode && value.supplierCode.peek())
          }
          defaultOptions={[
            {
              label: 'Mir zugewiesene',
              value: 'own',
            },
            ...savedProfiles.map(profile => ({
              label: profile.title,
              value: `saved-${profile.pk}`,
            })),
            ...allSupplierCodes,
          ]}
          onChange={this.handleSupplierCodeChange}
          label="Dispo"
          name="supplierCode"
        />
        <Autocomplete
          isMulti
          value={value.status ? value.status.peek() : []}
          onChange={this.handleStatusChange}
          label="Ampel"
          name="status"
          defaultOptions={[
            {
              value: 'red',
              label: <StatusBox status="red" />,
            },
            {
              value: 'yellow',
              label: <StatusBox status="yellow" />,
            },
            {
              value: 'green',
              label: <StatusBox status="green" />,
            },
          ]}
        />
      </form>
    );
  }
}

const styles = theme => ({
  colorSquare: {
    height: 20,
    width: 20,
  },
  red: {
    backgroundColor: 'rgba(228, 66, 88, 1)',
  },
  yellow: {
    backgroundColor: 'rgba(245, 225, 20, 1)',
  },
  green: {
    backgroundColor: 'rgba(0, 166, 94, 1)',
  },
  form: {
    display: 'flex',
    width: '100%',
    color: 'white',
    flexDirection: 'column',
  },
  buttons: {
    display: 'flex',
    justifyContent: 'end',
  },
  chip: {
    margin: `${theme.spacing(0.5)}px ${theme.spacing(0.25)}px`,
  },
  trafficLight: {
    margin: '0 8px',
  },
  trafficLightPlaceholder: {
    color: 'rgba(255, 255, 255, 0.7)',
  },
});

export default withStyles(styles)(FilterForm);
