import { join, pathOr } from 'ramda';
import API from '../index';

export async function getSheetUploads(token) {
  try {
    const res = await API.get(
      'delivery-sheet-upload/',
      {
        headers: {
          Authorization: `JWT ${token}`,
        },
      },
    );
    return {
      value: res.data,
    };
  } catch (e) {
    const extractErrors = pathOr([], ['response', 'data', 'non_field_errors']);

    return {
      error: join('. ', extractErrors(e)),
    };
  }
}

export async function updateDeliverySheet(deliverySheet, token) {
  try {
    const res = await API.patch(
      `delivery-sheet/${deliverySheet.id}`,
      deliverySheet,
      {
        headers: {
          Authorization: `JWT ${token}`,
        },
      },
    );
    return {
      value: res.data,
    };
  } catch (e) {
    return {
      error: e.response,
    };
  }
}
