import PropTypes from 'prop-types';
import classNames from 'classnames';
import React from 'react';
import { withStyles } from '@material-ui/core/styles';


const StatusBar = ({
  status,
  classes,
}) => (
  <div
    className={classNames(classes.bar, classes[status])}
  />
);

StatusBar.propTypes = {
  classes: PropTypes.object,
  status: PropTypes.string,
};

const styles = () => ({
  bar: {
    height: '100%',
    width: 10,
  },
  green: {
    backgroundColor: 'rgba(0, 166, 94, 0.5)',
  },
  yellow: {
    backgroundColor: 'rgba(245, 225, 20, 0.5)',
  },
  red: {
    backgroundColor: 'rgba(228, 66, 88, 0.5)',
  },
});

export default withStyles(styles)(StatusBar);
