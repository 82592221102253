export const styles = () => ({
  dashboard: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    margin: '0 2em',
  },
  container: {
    width: '100%',
    height: '100%',
    display: 'flex',
    '&>:not(:first-child):not(:last-child)': {
      margin: '0 1em',
    },
    '&>:first-child': {
      marginRight: '1em',
    },
    '&>:last-child': {
      marginLeft: '1em',
    },
  },
  columnLeft: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    width: '350px',
    flexShrink: 0,
    paddingBottom: '2em',
  },
  columnRight: {
    height: '100%',
    flexGrow: 1,
  },
  deliverListContainer: {
    flexGrow: 1,
    marginTop: '2em',
  },
  graphButtonsContainer: {
    display: 'flex',
    flexDirection: 'column',
    flexShrink: 0,
    marginTop: '2em',
  },
  toolbar: {
    justifyContent: 'space-between',
  },
  appTitle: {
    display: 'flex',
  },
  grow: {
    flex: 1,
  },
  bold: {
    fontWeight: 'bold',
    marginRight: '0.3em',
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    opacity: 1,
    transition: 'opacity 500ms, max-width 500ms',
    maxWidth: '100%',
    height: '100%',
  },
  halfHeightRow: {
    display: 'flex',
    flexDirection: 'row',
    height: '50%',
    overflowY: 'hidden',
  },
  narrow: {
    flex: 1,
  },
  medium: {
    flex: 2,
  },
  wide: {
    flex: 5,
  },
  hidden: {
    maxWidth: 80,
  },
  paper: {
    flex: 1,
    position: 'relative',
    margin: '1em',
    '& h6': {
      opacity: 0.56,
      marginLeft: '2em',
      flex: 1,
      marginTop: '1em',
    },
  },
  detailsButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    position: 'absolute',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'rgba(84, 178, 180, 0.5)',
    },
  },
  button: {
    height: 48,
    width: 48,
  },
  tableFlex: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  tabName: {
    textTransform: 'initial',
  },
});
