import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { DateTime } from 'luxon';
import { DatePicker } from '@material-ui/pickers';
import { trimLead0s } from '../../utils/displayUtils';

class UpdateDeliveryForm extends Component {
  state = {
    numberOfMaterialsInput: '',
    deliveryDate: '',
    confirmDeleteOpen: false,
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.visible && this.props.visible === true) {
      this.populateDefaultValues();
    }
  }

  getDeliveryDate() {
    const {
      selectedDay,
      selectedRow,
    } = this.props;

    if (selectedDay === 'nextDelivery') {
      return DateTime.fromISO(selectedRow.nextDelivery.deliveryDate);
    }
    const dayOffset = selectedDay ? parseInt(selectedDay.match(/\d+/)[0], 10) : undefined;
    return (dayOffset !== undefined)
      ? DateTime.local().plus({ days: dayOffset })
      : DateTime.local();
  }

  populateDefaultValues = () => {
    const {
      selectedDay,
      selectedRow,
    } = this.props;

    const deliveryDate = this.getDeliveryDate();
    const numberOfMaterialsInput = (selectedRow && selectedDay)
      ? (selectedRow[selectedDay]).quantity
      : '';

    this.setState({
      numberOfMaterialsInput,
      deliveryDate,
    });
  }

  handleNumberOfMaterialsChange = (event) => {
    this.setState({
      numberOfMaterialsInput: event.target.value,
    });
  }

  handleUpdateClick = () => {
    const {
      selectedRow,
      onUpdate,
    } = this.props;
    const {
      numberOfMaterialsInput,
      deliveryDate,
    } = this.state;

    onUpdate({
      selectedMaterial: selectedRow ? selectedRow.materialNumber : '',
      numberOfMaterialsInput,
      deliveryDate,
    });
  }

  onDateChange = (date) => {
    this.setState({
      deliveryDate: date,
    });
  }

  toggleConfirmDelete = () => {
    this.setState(prevState => ({
      confirmDeleteOpen: !prevState.confirmDeleteOpen,
    }));
  }

  handleConfirmDelete = async () => {
    try {
      await this.props.onDelete();
      this.setState({
        confirmDeleteOpen: false,
      });
    } catch (e) {
      console.error(e);
    }
  }

  render() {
    const {
      visible,
      onCancel,
      selectedRow,
      classes,
    } = this.props;

    const {
      numberOfMaterialsInput,
      deliveryDate,
      confirmDeleteOpen,
    } = this.state;

    if (!visible) {
      return null;
    }

    const formComplete = numberOfMaterialsInput && deliveryDate;
    return (
      <>
        <Modal
          title="Lieferdatum"
          open={visible}
          onClose={onCancel}
          disableBackdropClick
        >
          <div className={classes.paper}>
            <Typography variant="h6">Lieferdatum</Typography>
            <form autoComplete="off" className={classes.form}>
              <TextField
                disabled
                className={classes.formControl}
                id="material-number"
                label="Material"
                value={`${selectedRow ? trimLead0s(selectedRow.materialNumber) : ''} - ${selectedRow ? selectedRow.description : ''}`}
              />
              <TextField
                className={classes.formControl}
                id="number-of-materials"
                label="Menge"
                value={numberOfMaterialsInput}
                type="number"
                inputProps={{
                  min: 0,
                }}
                onChange={this.handleNumberOfMaterialsChange}
              />
              <DatePicker
                autoOk
                className={classes.formControl}
                id="date"
                label="Lieferdatum"
                value={deliveryDate}
                onChange={this.onDateChange}
                disablePast
              />
              <div className={classes.buttons}>
                <Button onClick={onCancel} aria-label="Abbrechen">CANCEL</Button>
                <Button
                  onClick={(value) => {
                    this.handleUpdateClick(value);
                  }}
                  color="primary"
                  disabled={!formComplete}
                  aria-label="Bestätigen"
                >
                  OK
                </Button>
                <Button
                  onClick={this.toggleConfirmDelete}
                  color="secondary"
                  aria-label="Entfernen"
                >
                  Entfernen
                </Button>
              </div>
            </form>
          </div>
        </Modal>
        <Modal
          title="Bestätigen"
          open={confirmDeleteOpen}
          onClose={this.toggleConfirmDelete}
          disableBackdropClick
        >
          <div className={classes.paper}>
            <Typography variant="h6">Bestätigen</Typography>
            <div className={classes.buttons}>
              <Button onClick={this.toggleConfirmDelete} aria-label="Abbrechen">ABBRECHEN</Button>
              <Button onClick={this.handleConfirmDelete} aria-label="Entfernen">ENTFERNEN</Button>
            </div>
          </div>
        </Modal>
      </>
    );
  }
}

UpdateDeliveryForm.propTypes = {
  visible: PropTypes.bool,
  onCancel: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  selectedRow: PropTypes.object,
  selectedDay: PropTypes.string,
  classes: PropTypes.object,
};

const styles = theme => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  paper: {
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    position: 'absolute',
    width: theme.spacing(50),
    backgroundColor: theme.palette.background.paper,
    boxShoadow: theme.shadows[5],
    padding: theme.spacing(4),
    outline: 'none',
  },
  buttons: {
    display: 'flex',
    justifyContent: 'end',
  },
});

export default withStyles(styles)(UpdateDeliveryForm);
