import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { DateTime } from 'luxon';
import { ReactComponent as Logo } from '../../../assets/VF_Logo.svg';


const ReportPreview = React.forwardRef(({
  classes, report, children, title,
}, ref) => {
  const renderPage = (child, pageIndicator) => (
    <div key={pageIndicator}>
      <div className={classes.pageBreak}/>
      <div className={classes.card}>
        <div className={classes.header}>
          <Logo className={classes.logo}/>
          <div className={classes.title}>
            <Typography variant="h6" color="secondary">
              {title}
            </Typography>
            {report.params && report.params.start_date && report.params.end_date && (
              <Typography variant="body2">
                {`${DateTime.fromISO(report.params.start_date).toFormat('dd.MM.yyyy')} -
              ${DateTime.fromISO(report.params.end_date).toFormat('dd.MM.yyyy')}`}
              </Typography>
            )}
          </div>
        </div>
        {child}
        <div className={classes.footer}>
          <div className={classes.pageNumber}>{pageIndicator}</div>
        </div>
      </div>
    </div>
  );

  const renderPages = () => {
    if (Array.isArray(children)) {
      return children.map((child, index) => renderPage(child, `${index + 1}/${children.length}`));
    }
    return renderPage(children, '1/1');
  };

  return (
    <div ref={ref} className={classes.container}>
      <style type="text/css" media="print">
        {'@page {size: landscape;}'}
      </style>
      {renderPages()}
    </div>
  );
});

ReportPreview.propTypes = {
  classes: PropTypes.object,
  title: PropTypes.string,
  report: PropTypes.object,
  children: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
    PropTypes.array,
  ]),
};

const styles = (theme) => ({
  container: {
    width: '100%',
    overflow: 'auto',
  },
  card: {
    // pageBreakBefore: 'auto',
    display: 'flex',
    flexDirection: 'column',
    // border: '1px solid rgba(239,240,241, 1.0)',
    background: 'white',
    width: '100%',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(1),
    borderBottom: '1px solid rgba(239,240,241, 1.0)',
  },
  logo: {
    height: '36px',
    width: '64px',
    margin: '10px 30px 10px 0',
  },
  title: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
  },
  footer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    borderTop: '1px solid rgba(239,240,241, 1.0)',
  },
  pageNumber: {
    paddingRight: theme.spacing(1),
  },
  pageBreak: {
    marginTop: '1rem',
    display: 'block',
    pageBreakBefore: 'auto',
  },
});

export default withStyles(styles)(ReportPreview);
