import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject, observer, PropTypes as MobxPropTypes } from 'mobx-react';
import { withStyles } from '@material-ui/core/styles';
import { withApollo } from 'react-apollo';
import Autocomplete from '../../Autocomplete';
import SupplierFilter from '../../FilterForm/SupplierFilter';
import GroupFilter from '../../FilterForm/GroupFilter';
import { allSupplierCodes } from '../../../utils/dispoUtils';

@inject('store')
@observer
class FilterConfig extends Component {
  render() {
    const {
      store,
      classes,
      availableFilters = [],
    } = this.props;

    const {
      tableFilter,
      updateTableFilter,
    } = store.reporting;

    return (
      <div className={classes.container}>
        {(availableFilters.length === 0 || availableFilters.includes('supplier')) && (
          <SupplierFilter
            value={tableFilter.supplier.peek() || []}
            onChange={(newSuppliers) => updateTableFilter('supplier', newSuppliers)}
            label="Lieferant"
            name="supplier"
          />
        )}
        {(availableFilters.length === 0 || availableFilters.includes('abc')) && (
          <Autocomplete
            value={tableFilter.abcClassification.peek() || []}
            onChange={(newAbcClassification) => updateTableFilter('abcClassification', newAbcClassification)}
            isMulti
            label="ABC-Klassifizierung"
            name="abc"
            defaultOptions={[
              {
                value: 'A',
                label: 'A',
              },
              {
                value: 'B',
                label: 'B',
              },
              {
                value: 'C',
                label: 'C',
              },
            ]}
            clearable
          />
        )}
        {(availableFilters.length === 0 || availableFilters.includes('dispo')) && (
          <Autocomplete
            value={tableFilter.dispo.peek() || []}
            defaultOptions={allSupplierCodes}
            isMulti
            onChange={(newDispos) => updateTableFilter('dispo', newDispos)}
            label="Dispo"
            name="supplierCode"
            clearable
          />
        )}
        {(availableFilters.length === 0 || availableFilters.includes('group')) && (
          <GroupFilter
            value={tableFilter.group.peek() || []}
            onChange={(newGroups) => updateTableFilter('group', newGroups)}
            label="Material Kategorie"
            name="group"
          />
        )}
      </div>
    );
  }
}

FilterConfig.propTypes = {
  store: MobxPropTypes.objectOrObservableObject,
  classes: PropTypes.object,
  availableFilters: PropTypes.array,
};

const styles = () => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    textAlign: 'start',
  },
});

export default withApollo(withStyles(styles)(FilterConfig));
