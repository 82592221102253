import gql from 'graphql-tag';
import { MISSING_PART_GROUP_DATA } from '../fragments';

export const getNonProductionOrderRelatedMissingParts = gql`
  ${MISSING_PART_GROUP_DATA}
  query getNonProductionOrderRelatedMissingParts (
    $location: String,
  ){
    groupedMissingParts (location: $location, onlyNonProductionOrderRelated: true) {
      ...MissingPartGroupData
    }
  }
`;

// status, dist (mrp_controller), lieferant (supplier)
export const getMissingParts = gql`
  ${MISSING_PART_GROUP_DATA}
  query getMissingParts (
    $location: String,
  ){
    groupedMissingParts {
      ...MissingPartGroupData
    }
    groupedExpectedMissingParts {
      materialNumber
      expectedMissingParts (
        location: $location,
      ) {
        edges {
          node {
            systemName
            omlStart
            delivery
            quantity
            materialNumber
            materialDescription
            supplierCode
            supplierName
            supplierNumber
            salesOrderNumber
            productionOrderNumber
            loadDate
            callDate
            confirmed
            customerCountryCode
            customerName
            remark
            deltaQuantity
          }
        }
      }
    }
    allProjects(
      systemGroup:"Artis",
      location: $location
    ) {
      edges {
        node {
          omlStart
        }
      }
    }
    errorInfo {
      materialErrors
      expectedMissingPartErrors {
        materialErrors
        projectErrors
      }
      hasError
    }
  }
`;
