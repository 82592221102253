import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { observer, inject, PropTypes as MobxPropTypes } from 'mobx-react';
import classnames from 'classnames';
import { Steps } from 'intro.js-react';

import { ApolloMake } from 'Components/Make';
import Source from 'Components/Source';

import 'intro.js/introjs.css';
import '../../../Styles/introjs-overrides.css';

@inject('store')
@observer
class AllContainer extends Component {
  static propTypes = {
    store: MobxPropTypes.objectOrObservableObject,
    classes: PropTypes.object,
  };

  onIntroExit = () => {
    this.props.store.ui.finishMainIntro();
  }

  render() {
    const {
      classes,
      store,
    } = this.props;

    return (
      <div className={classes.container}>
        <Steps
          enabled={store.ui.mainIntroReady && !store.ui.mainIntroDone && !store.source.reloading}
          steps={[
            {
              element: '#source',
              intro: `Dieser Bereich zeigt Lagerbestand und Liefertermine aller relevanten Materialien. Diese sind durch folgende Farbcodierung gekennzeichnet:

Lagerbestand:

Rot - Das Material fehlt für mindestens ein System.
Gelb - Das Material fehlt, wird jedoch voraussichtlich rechtzeitig ankommen.
Grün - Lagerbestand ist ausreichend vorhanden.

Bedarf:

Anzahl der Materialien, die offene Reservierungen im FAUF haben und in KMAT/BOM-Stückliste von KAUFs der nächsten 14 Tage enthalten sind.

Reichweite (RW):

Hier wird angezeigt wie viele Tage der aktuelle Bestand auf Basis von Bedarf und Einlastung noch ausreicht. 

Überfällige Anlieferungen:

Hier wird die Anzahl der überfälligen Anlieferungen angezeigt. Weitere Details zu den Anlieferungen (Lieferant, Menge, PO Nummer, Lieferdatum, Quelle) ist im Pop-up Menü der Materialnummer zu finden.

Anlieferungen:

Rot - Trifft nach dem geplanten Abrufdatum ein
Gelb - Trifft rechtzeitig zum Abruf ein
Grün - Anlieferung unkritisch (Material bereits auf Lager)

Sobald eine Anlieferung vollständig eingetroffen ist, erlischt diese in der VF. Bei Teillieferungen wird die Anzahl der Menge angepasst, überfällige Liefererungen werden in die Überfällig-Spalte verschoben.
`,
            },
            {
              element: '#sort-icon',
              intro: 'Hier können Bedarf und Bestand aufsteigend oder absteigend sortiert werden.',
            },
            {
              element: '#add-delivery',
              intro: 'Hier kann eine neue Anlieferung hinzugefügt werden.',
            },
            {
              element: '#material-details-button',
              intro: `Hier gelangt man zu einem Pop-up Menü mit folgenden Funktionen: Anzeigen von Details zum Material, Hinzufügen einer neuen Anlieferung, überfälligen Anlieferungen und Auswählen des Materials.

Manuell hinzugefügt Anlieferungen ("manual" oder "sheet") können hier gelöscht werden.
`,
            },
            {
              element: '#source .filter',
              intro: `Filter-Knopf: Die Materialien können gefiltert werden.
„X“: Der Filter wird zurückgesetzt. 
RESET: Alle Filter werden zurückgesetzt.`,
            },
            {
              element: '#make .filter',
              intro: `Filter-Knopf: Die Fertigungstarts können gefiltert werden.
„X“: Der Filter wird zurückgesetzt. 
RESET: Alle Filter werden zurückgesetzt.`,
            },
            {
              element: '#make',
              intro: `Dieser Bereich zeigt die Fertigungsstarts in den kommenden Tagen. Systeme sind der Spalte des OML Startdatums zugeordnet. Die Farbcodierung liegt folgenden Regeln zu Grunde:

Rot - Bei mindestens einer benötigten Komponente ist der Bestand gleich Null zum Abrufszeitpunkt.
Gelb - Bestand ist gleich Null wird aber voraussichtlich rechtzeitig geliefert.
Grün - Alle Komponenten liegen in ausreichender Menge im Lager.

In der Spalte "Überfällig" befinden sich alle Systeme die,
- noch nicht vollständig im SAP & SCMIS abgerufen sind.
- ein offenes SCMIS Fehlteil haben.
`,
            },
            {
              element: '#project',
              intro: `Erklärung zu Symbolen: 
FT: Für dieses System gibt es noch mindestens ein offenes SCMIS Fehlteil.
A: System wurde im SCMIS abgerufen.
"Haken": Alle Komponenten im SAP gebucht.
"Herz": Das System wurde als wichtig markiert.
"E. Fehlteile": Anzeige der Anzahl von errechneten Fehlteilen in diesem Auftrag. Die Anzeige kann in der Ansicht-Einstellung aktiviert und deaktiviert werden.
`,
            },
            {
              element: '#project',
              intro: `Durch Klicken auf ein System werden zusätzliche Details angezeigt.
Im linken Bereich der Anlieferungen listet die virtuelle Fabrik nur die dem System zugeordneten Komponenten. Alle abgerufenen Komponenten (im SAP) sind durch einen Haken gekennzeichnet. WICHTIG: Die Farbcodierung der Komponenten bezieht sich jetzt NUR auf das markierte Systeme. Hier können z.B. Komponenten grün sein, die in der Gesamtansicht rot sind, da für dieses System der Lagerbestand ausreichend ist.`,
            },
            {
              element: '#material',
              intro: 'Beim Klicken auf Komponenten zeigt die Virtuelle Fabrik auf der rechten "Fertigungsstart" Seite alle Systemen an, in denen die Komponente verbaut wird.',
            },
            {
              element: '#dashboard-tab',
              intro: 'Zurück zum Dashboard',
            },
            {
              element: '#reporting-button',
              intro: 'Hier ist der Einstieg zum Reporting der Virtuellen Fabrik',
            },
            {
              element: '#header-add',
              intro: 'Upload von Anlieferterminen über eine Excelliste oder Formular.',
            },
            {
              element: '#header-view',
              intro: 'Individuelle Anpassungen können gespeichert werden. Bestimmte Anzeigen können aktiviert und deaktiviert werden.',
            },
            {
              element: '#header-profile',
              intro: 'Hier lassen sich die gespeicherten Dispos erstellen und verwalten.',
            },
          ]}
          initialStep={0}
          onExit={this.onIntroExit}
        />
        <div
          className={classnames(classes.componentWrap, {
            [classes.sourceWrap]: !store.ui.sourceExpanded,
            [classes.zeroWidth]: !store.ui.sourceVisible,
            [classes.visible]: store.ui.sourceVisible,
            [classes.fullWidth]: store.ui.sourceExpanded,
            [classes.compactSource]: store.ui.sourceVisible && !store.ui.sourceWeekly,
          })}
        >
          <Source introId="source" />
        </div>
        <div
          className={classnames(classes.componentWrap, {
            [classes.makeWrap]: !store.ui.makeExpanded && (store.ui.sourceVisible && store.ui.sourceWeekly),
            [classes.zeroWidth]: !store.ui.makeVisible,
            [classes.visible]: store.ui.makeVisible,
            [classes.fullWidth]: store.ui.makeExpanded,
            [classes.largeMake]: store.ui.sourceVisible && !store.ui.sourceWeekly,
          })}
        >
          <ApolloMake introId="make" />
        </div>
      </div>
    );
  }
}

const style = () => ({
  container: {
    display: 'flex',
    height: '100%',
  },
  componentWrap: {
    display: 'inline-block',
    height: '100%',
    verticalAlign: 'top',
    transition: 'width 500ms, opacity 500ms, min-width 500ms',
  },
  visible: {
    opacity: '1',
  },
  sourceWrap: {
    width: '54%',
    minWidth: 681,
    marginLeft: '10px',
    marginRight: '5px',
  },
  compactSource: {
    width: '35%',
    minWidth: '500px',
  },
  makeWrap: {
    width: '46%',
    minWidth: 514,
    marginRight: '10px',
    marginLeft: '5px',
  },
  largeMake: {
    width: '65%',
  },
  fullWidth: {
    width: '100%',
  },
  zeroWidth: {
    width: '0%',
    opacity: '0',
    overflow: 'hidden',
    minWidth: '0',
  },
});

export default withStyles(style)(AllContainer);
