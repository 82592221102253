import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Chip from '@material-ui/core/Chip';
import Checkbox from '@material-ui/core/Checkbox';
import CancelIcon from '@material-ui/icons/Cancel';
import SearchIcon from '@material-ui/icons/Search';
import AsyncSelect from 'react-select/async';
import Select from 'react-select';
import { withStyles } from '@material-ui/core/styles';
import debounce from 'debounce-promise';
import styles from './styles';

function NoOptionsMessage(props) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.noOptionsMessage}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

NoOptionsMessage.propTypes = {
  selectProps: PropTypes.object,
  innerProps: PropTypes.object,
  children: PropTypes.string,
};

function IndicatorSeparator() {
  return null;
}

function Option(props) {
  return (
    <MenuItem
      buttonRef={props.innerRef}
      selected={props.isSelected}
      component="div"
      style={{
        fontWeight: props.isSelected ? 500 : 400,
      }}
      {...props.innerProps}
    >
      <Checkbox checked={props.isSelected} />
      {props.children}
    </MenuItem>
  );
}

Option.propTypes = {
  isSelected: PropTypes.bool,
  isFocused: PropTypes.bool,
  innerRef: PropTypes.func,
  selectProps: PropTypes.object,
  innerProps: PropTypes.object,
  children: PropTypes.string,
};

function Placeholder(props) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.placeholder}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

Placeholder.propTypes = {
  selectProps: PropTypes.object,
  innerProps: PropTypes.object,
  children: PropTypes.string,
};

function SingleValue(props) {
  return (
    <Typography className={props.selectProps.classes.singleValue} {...props.innerProps}>
      {props.children}
    </Typography>
  );
}

SingleValue.propTypes = {
  selectProps: PropTypes.object,
  innerProps: PropTypes.object,
  children: PropTypes.array,
};

function ValueContainer(props) {
  return (
    <div className={props.selectProps.classes.valueContainer}>
      <SearchIcon className={props.selectProps.classes.searchIcon} />
      {props.children}
    </div>
  );
}

ValueContainer.propTypes = {
  selectProps: PropTypes.object,
  children: PropTypes.array,
};

function MultiValue(props) {
  return (
    <Chip
      color="secondary"
      tabIndex={-1}
      label={props.children}
      className={classNames(props.selectProps.classes.chip, {
        [props.selectProps.classes.chipFocused]: props.isFocused,
      })}
      onDelete={props.removeProps.onClick}
      deleteIcon={<CancelIcon {...props.removeProps} />}
    />
  );
}

MultiValue.propTypes = {
  removeProps: PropTypes.object,
  isSelected: PropTypes.bool,
  isFocused: PropTypes.bool,
  selectProps: PropTypes.object,
  innerProps: PropTypes.object,
  children: PropTypes.string,
};

function Menu(props) {
  return (
    <Paper square className={props.selectProps.classes.paper} {...props.innerProps}>
      {props.children}
    </Paper>
  );
}

Menu.propTypes = {
  selectProps: PropTypes.object,
  innerProps: PropTypes.object,
  children: PropTypes.object,
};

class Autocomplete extends Component {
  render() {
    const {
      name,
      async,
      isMulti,
      classes,
      onChange,
      loadOptions,
      theme,
      value,
      defaultInputValue,
      defaultOptions,
      disabled,
      label,
      clearable,
    } = this.props;

    const components = {
      Menu,
      MultiValue,
      NoOptionsMessage,
      Option,
      Placeholder,
      SingleValue,
      ValueContainer,
      IndicatorSeparator,
    };

    const indicatorStyles = (base) => ({
      ...base,
      color: theme.palette.text.primary,
      '&:hover': {
        color: theme.palette.text.primary,
      },
    });

    const selectStyles = {
      input: (base) => ({
        ...base,
        color: theme.palette.text.primary,
        '& input': {
          font: 'inherit',
        },
      }),
      control: (base) => ({
        ...base,
        backgroundColor: 'rgba(255, 255, 255, 0.3)',
        border: 'none',
        color: theme.palette.text.primary,
        boxShadow: 'none',
        '&:hover': {
          border: 'none',
        },
      }),
      loadingIndicator: indicatorStyles,
      clearIndicator: indicatorStyles,
      dropdownIndicator: indicatorStyles,
    };

    if (async) {
      const debouncedLoader = debounce(loadOptions, 500);
      return (
        <>
          <label className={classes.label} htmlFor={name}>{label}</label>
          <AsyncSelect
            name={name}
            inputId={name}
            className={classes.formControl}
            isMulti={isMulti}
            hideSelectedOptions={!isMulti}
            classes={classes}
            value={value}
            styles={selectStyles}
            loadOptions={debouncedLoader}
            components={components}
            onChange={onChange}
            defaultInputValue={defaultInputValue}
            defaultOptions={defaultOptions}
            isDisabled={disabled}
            isClearable={!isMulti || clearable}

            placeholder="Wählen..."
            noOptionsMessage={() => 'Keine Ergebnisse'}
            closeMenuOnSelect={false}
          />
        </>
      );
    }
    return (
      <>
        <label className={classes.label} htmlFor={name}>{label}</label>
        <Select
          name={name}
          inputId={name}
          className={classes.formControl}
          isMulti={isMulti}
          hideSelectedOptions={!isMulti}
          classes={classes}
          value={value}
          styles={selectStyles}
          options={defaultOptions}
          components={components}
          onChange={onChange}
          defaultInputValue={defaultInputValue}
          defaultOptions={defaultOptions}
          isDisabled={disabled}
          placeholder="Wählen..."
          noOptionsMessage={() => 'Keine Ergebnisse'}
          closeMenuOnSelect={false}
          isClearable={clearable}
        />
      </>
    );
  }
}

Autocomplete.propTypes = {
  name: PropTypes.string,
  async: PropTypes.bool,
  isMulti: PropTypes.bool,
  classes: PropTypes.object,
  onChange: PropTypes.func,
  theme: PropTypes.object,
  loadOptions: PropTypes.func,
  value: PropTypes.array,
  defaultInputValue: PropTypes.string,
  defaultOptions: PropTypes.any,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  clearable: PropTypes.bool,
};

export default
withStyles(styles, { withTheme: true })(Autocomplete);
