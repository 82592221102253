import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import NotificationsIcon from '@material-ui/icons/Notifications';
import DeleteIcon from '@material-ui/icons/Delete';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Badge from '@material-ui/core/Badge';
import Switch from '@material-ui/core/Switch';
import ListSubheader from '@material-ui/core/ListSubheader';
import { inject, observer, PropTypes as MobxPropTypes } from 'mobx-react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { DateTime } from 'luxon';
import Fade from '@material-ui/core/Fade';

const StyledMenu = withStyles({
  paper: {
    width: '520px',
  },
})((props) => (
  <Menu {...props} />
));

@inject('store')
@observer
class Notifications extends Component {
  constructor(props) {
    super(props);

    this.state = {
      anchor: null,
      activeTab: 'warning',
    };
  }

  handleOpen = (event) => {
    this.setState({
      anchor: event.currentTarget,
    });
  }

  handleClose = () => {
    this.setState({
      anchor: null,
    });
  }

  handleTabChange = (_, newValue) => {
    this.setState({
      activeTab: newValue,
    });
  }

  render() {
    const {
      store,
      classes,
    } = this.props;

    const {
      anchor,
      activeTab,
    } = this.state;

    const {
      notifications,
      setNotificationReadState,
      deleteNotification,
    } = store.notifications;
    const { dashboardFilter } = store.source;
    const showAll = store.user.dispoFilters[0] === 'all';
    const notificationsByFilteredDispo = dashboardFilter
      ? notifications.filter(({ dispo }) => !dispo || dashboardFilter.includes(dispo))
      : [];
    const notificationsToShow = showAll ? notifications : notificationsByFilteredDispo;
    const sortedNotifications = notificationsToShow.sort((a, b) => {
      const aStart = DateTime.fromISO(a.created_at);
      const bStart = DateTime.fromISO(b.created_at);
      if (aStart > bStart) return -1;
      if (bStart > aStart) return 1;
      return 0;
    });
    const displayedNotifications = sortedNotifications
      .filter(notification => notification.level.toLowerCase() === activeTab);
    const totalUnreadNotification = notificationsToShow.filter(notification => !notification.read);
    const displayedUnreadNotifications = displayedNotifications.filter(notification => !notification.read);
    const displayedReadNotifications = displayedNotifications.filter(notification => notification.read);

    const displayList = (list) => {
      if (list.length > 0) {
        return list
          .map(notification => (
            <MenuItem
              key={notification.id}
              className={classes.container}
            >
              <div className={classes.innerContainer}>
                <span className={classes.title}>{notification.title || 'Title'}</span>
                <span className={classes.body}>{notification.message}</span>
                <span className={classes.date}>{DateTime.fromISO(notification.created_at).toLocaleString(DateTime.DATE_HUGE)}</span>
              </div>
              <div>
                <Switch
                  checked={notification.read}
                  onChange={() => {
                    setNotificationReadState(notification.id, !notification.read);
                  }}
                />
                <IconButton
                  onClick={() => deleteNotification(notification.id)}
                  aria-label="Benachrichtigung löschen"
                >
                  <DeleteIcon/>
                </IconButton>
              </div>
            </MenuItem>
          ));
      }
      return (
        <MenuItem
          disabled
        >
          Keine Nachrichten
        </MenuItem>
      );
    };

    const unreadWarningNotifications = totalUnreadNotification.filter(n => n.level.toLowerCase() === 'warning');
    const unreadInfoNotifications = totalUnreadNotification.filter(n => n.level.toLowerCase() === 'info');

    return (
      <>
        <IconButton
          id="header-notifications"
          color="secondary"
          data-testid="notifications"
          onClick={this.handleOpen}
          aria-label="Benachrichtigungen anzeigen"
        >
          <Badge
            badgeContent={totalUnreadNotification.length}
            color={unreadWarningNotifications.length > 0 ? 'error' : 'primary'}
          >
            <NotificationsIcon/>
          </Badge>
        </IconButton>
        <StyledMenu
          id="notificationsMenu"
          anchorEl={anchor}
          open={!!anchor}
          onClose={this.handleClose}
          TransitionComponent={Fade}
        >
          <h3 className={classes.heading}>Benachrichtigungen</h3>
          <Tabs value={activeTab} onChange={this.handleTabChange}>
            <Tab
              value="warning"
              label={(
                <Badge
                  badgeContent={unreadWarningNotifications.length}
                  color="error"
                >
                  Warnung
                </Badge>
              )}
            />
            <Tab
              value="info"
              label={(
                <Badge
                  badgeContent={unreadInfoNotifications.length}
                  color="primary"
                >
                  Hinweis
                </Badge>
              )}
            />
          </Tabs>
          <ListSubheader disableSticky >Ungelesen</ListSubheader>
          {displayList(displayedUnreadNotifications)}
          <ListSubheader disableSticky >Gelesen</ListSubheader>
          {displayList(displayedReadNotifications)}
        </StyledMenu>
      </>
    );
  }
}

Notifications.propTypes = {
  store: MobxPropTypes.objectOrObservableObject,
  classes: PropTypes.object,
};

const style = () => ({
  menu: {
    width: '520px',
  },
  container: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  innerContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    fontWeight: 'bold',
    whiteSpace: 'pre-line',
  },
  body: {
    whiteSpace: 'pre-line',
  },
  date: {
    fontWeight: 'lighter',
  },
  heading: {
    padding: '0 16px',
  },
});

export default withStyles(style)(Notifications);
