import { DateTime } from 'luxon';
import holidaysData from './holidays.json';

export function isWorkday(day) {
  if (day.weekday === 6 || day.weekday === 7) {
    return false;
  }
  if (holidaysData.holidays.includes(day.toISODate())) {
    return false;
  }
  return true;
}

export function getNDaysMatchingFrom(start, matchFunction, n, maxLoop = 30) {
  let countedDays = 0;
  let i = 0;
  const returnArray = [];

  while (countedDays < n && i < maxLoop) {
    const dayInQuestion = start.plus({ days: i });
    if (matchFunction(dayInQuestion)) {
      returnArray.push(i);
      countedDays += 1;
    }
    i += 1;
  }
  return returnArray;
}

export function getNWorkdayIndeciesFrom(start, n) {
  return getNDaysMatchingFrom(start, isWorkday, n);
}

export function reformatIsoDate(date) {
  if (date) {
    const luxonDate = DateTime.fromISO(date);
    const today = DateTime.local();
    if (today.hasSame(luxonDate, 'day')) {
      return 'Heute';
    }
    if (today.plus({ days: 1 }).hasSame(luxonDate, 'day')) {
      return 'Morgen';
    }
    return DateTime.fromISO(date).toLocaleString({ locale: 'de', ...DateTime.DATE_SHORT });
  }
  return '-';
}

export function reformatIsoDateTime(dateTime) {
  if (dateTime) {
    return DateTime.fromISO(dateTime).toLocaleString({ locale: 'de', ...DateTime.DATETIME_SHORT });
  }
  return '-';
}

export function reformatIsoTime(dateTime) {
  if (dateTime) {
    return DateTime.fromISO(dateTime).toLocaleString({ locale: 'de', ...DateTime.TIME_24_SIMPLE });
  }
  return 'No Time Found';
}
