import { createMuiTheme } from '@material-ui/core/styles';

const themeObject = createMuiTheme({
  palette: {
    type: 'dark',
    background: {
      default: '#009999',
    },
    primary: {
      main: '#fff',
    },
    secondary: {
      main: '#fff',
    },
    typography: {
      fontFamily: '"Calibri", serif',
    },
  },
});

const theme = createMuiTheme(themeObject);

export default theme;
