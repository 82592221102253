import { action, observable } from 'mobx';
import { DateTime } from 'luxon';
import { range } from 'ramda';
import queryString from 'query-string';
import { getInfo, getReportFromUrl, getCategories } from '../Api/endpoints/reporting';

export const rangeModes = [
  { value: 'years', label: 'Geschäftsjahr' },
  { value: 'quarters', label: 'Quatrale ' },
  { value: 'months', label: 'Monate' },
  { value: 'days', label: 'Tage' },
];

const pdfBaseUrl = process.env.REACT_APP_REPORT_PDF_URL;
const htmlBaseUrl = process.env.REACT_APP_REPORT_HTML_URL;

const defaultPartInfos = [
  {
    id: 'a',
    label: 'A-Teile',
    min: 5,
    max: 15,
    toleranceMinus: 50,
    tolerancePlus: 100,
    maxVariation: true,
  },
  {
    id: 'b',
    label: 'B-Teile',
    min: 10,
    max: 60,
    toleranceMinus: 50,
    tolerancePlus: 100,
    maxVariation: true,
  },
  {
    id: 'c',
    label: 'C-Teile',
    min: 20,
    max: 99,
    toleranceMinus: 50,
    tolerancePlus: 100,
    maxVariation: false,
  },
];

export default class Reporting {
  constructor(rootStore) {
    this.root = rootStore;
    this.getCategories();
  }

  @observable loading = false

  @observable info = {}

  @observable rangeMode = undefined
  @observable startDate = undefined
  @observable endDate = undefined

  @observable axisScale = ''

  @observable selectedReportables = []
  @observable selectedReportableMatrix = [[], [], []]

  @observable reportCategories = []
  @observable selectedReportCategory = undefined
  @observable selectedReport = undefined

  @observable expectedMissingPartTrafficLights = ['red']
  @observable disableMissingPartTrafficLights = new Set()

  @observable report = null
  @observable previewLoading: false
  @observable previewUrl = ''
  @observable pdfUrl: ''

  @observable tableFilter = {
    supplier: [],
    dispo: [],
    abcClassification: [],
    group: [],
  }

  @observable tableReportables = [
    {
      category: undefined,
      subcategory: undefined,
      operator: undefined,
      operand: undefined,
    },
    {
      category: undefined,
      subcategory: undefined,
      operator: undefined,
      operand: undefined,
    },
  ];

  @observable selectedMaterial = undefined

  @observable partInfos = defaultPartInfos;

  getRange = (partInfo, minOrMax) => {
    const val = minOrMax === 'min' ? partInfo.min : partInfo.max;
    const min = (val - (partInfo.toleranceMinus / 100) * val).toFixed(1);
    const max = (val + (partInfo.tolerancePlus / 100) * val).toFixed(1);
    return `${min} - ${max} Tage`;
  }

  @action.bound getPartById = (partId) => this.partInfos.find(thisPart => thisPart.id === partId);

  @action.bound setMin = (partId) => (value) => {
    const part = this.getPartById(partId);
    if (part) {
      part.min = value;
    }
  }

  @action.bound setMax = (partId) => (value) => {
    const part = this.getPartById(partId);
    if (part) {
      part.max = value;
    }
  }

  @action.bound setToleranceMinus = (partId) => (value) => {
    const part = this.getPartById(partId);
    if (part) {
      part.toleranceMinus = value;
    }
  }

  @action.bound setTolerancePlus = (partId) => (value) => {
    const part = this.getPartById(partId);
    if (part) {
      part.tolerancePlus = value;
    }
  }

  @action.bound copyToleranceToAll = (partId) => {
    const copiedPart = this.getPartById(partId);
    this.partInfos = this.partInfos.map(partInfo => ({
      ...partInfo,
      toleranceMinus: copiedPart.toleranceMinus,
      tolerancePlus: copiedPart.tolerancePlus,
    }));
  }

  @action.bound
  alignReportablesToMatrix = () => {
    this.selectedReportables = this.selectedReport.reportables.reduce((allSelected, reportable) => {
      if (range(0, 3).every(index => (
        this.selectedReportableMatrix[index].includes(reportable.matrixId[index])
      ))) {
        return [
          ...allSelected,
          reportable.id,
        ];
      }
      return allSelected;
    }, []);
  }

  @action.bound
  toggleReportableMatrixOption = (group, value) => {
    if (this.selectedReportableMatrix[group].includes(value)) {
      this.selectedReportableMatrix[group] = this.selectedReportableMatrix[group].filter(v => v !== value);
    } else {
      this.selectedReportableMatrix[group] = [
        ...this.selectedReportableMatrix[group],
        value,
      ];
    }

    this.alignReportablesToMatrix();
  }

  @action.bound
  updateTableFilter = (category, newFilter) => {
    this.tableFilter = {
      ...this.tableFilter,
      [category]: newFilter ? newFilter : [],
    };
  }

  @action.bound
  updateTableReportable = (index, reportable, value) => {
    this.tableReportables[index][reportable] = value;
  }

  @action.bound
  setRangeMode(mode) {
    this.rangeMode = mode;
    if (mode === 'years') {
      this.startDate = this.getFiscalYearStart(DateTime.local().year);
      this.endDate = this.getFiscalYearEnd(DateTime.local().year);
    } else if (mode === 'quarters') {
      this.startDate = `${DateTime.local().year}-01-01`;
      this.endDate = `${DateTime.local().year}-12-31`;
    } else {
      this.startDate = this.info.earliest_start_date;
      this.endDate = this.info.latest_end_date;
    }
  }

  @action.bound
  setStartDate(date) {
    this.startDate = date;
  }

  @action.bound
  setEndDate(date) {
    this.endDate = date;
  }

  @action.bound
  setAxisScale(value) {
    this.axisScale = value;
  }

  @action.bound
  toggleSelectedReportable(toggledKey) {
    if (this.selectedReportables.includes(toggledKey)) {
      this.selectedReportables = this.selectedReportables.filter(key => key !== toggledKey);
    } else {
      this.selectedReportables = [
        ...this.selectedReportables,
        toggledKey,
      ];
    }
  }

  @action.bound
  forceSelectedReportable(toggledKey, forcedState) {
    if (forcedState === false && this.selectedReportables.includes(toggledKey)) {
      this.selectedReportables = this.selectedReportables.filter(key => key !== toggledKey);
    } else if (forcedState === true) {
      this.selectedReportables = [
        ...this.selectedReportables,
        toggledKey,
      ];
    }
  }

  @action.bound
  setExpectedMissingPartTrafficLights(value) {
    this.expectedMissingPartTrafficLights = value;
  }

  @action.bound
  refreshMissingPartTrafficLightsEnabledStatus(value) {
    const tableCategories = this.tableReportables.map(reportable => reportable.category);
    const isSystemReport = this.selectedReportCategory === 'system';
    const isFilteredMaterials = this.selectedReport.id === 'list-of-filtered-materials';

    if (value === 'yellow') {
      const hasMissingPartsLogic = isFilteredMaterials && tableCategories.includes('missing-parts');
      const hasRangeLogic = isFilteredMaterials && tableCategories.includes('range');

      if (isSystemReport || hasMissingPartsLogic || hasRangeLogic) {
        this.enableTrafficLightOption(value);
      } else {
        this.disableTrafficLightOption(value);
      }
    }
  }

  enableTrafficLightOption(value) {
    this.disableMissingPartTrafficLights.delete(value);
  }

  disableTrafficLightOption(value) {
    this.disableMissingPartTrafficLights.add(value);

    if (this.expectedMissingPartTrafficLights.includes(value)) {
      this.expectedMissingPartTrafficLights.pop(value);
    }
  }

  @action.bound
  getActiveReportCategory = () => (
    this.reportCategories.find(category => category.id === this.selectedReportCategory)
  )

  getBaseConfig = () => (
    {
      start_date: DateTime.fromISO(this.startDate) < DateTime.fromISO(this.info.earliest_start_date)
        ? this.info.earliest_start_date : this.startDate,
      end_date: DateTime.fromISO(this.endDate) > DateTime.fromISO(this.info.latest_end_date)
        || DateTime.fromISO(this.endDate) < DateTime.fromISO(this.info.earliest_start_date)
        ? this.info.latest_end_date : this.endDate,
      expected_missing_part_traffic_lights: this.expectedMissingPartTrafficLights.toString(),
    }
  )

  @action.bound
  getReportConfig = () => {
    if (this.selectedReport.reportType === 'table') {
      const {
        supplier,
        dispo,
        abcClassification,
        group,
      } = this.tableFilter;

      const parseFilter = (filterArray) => {
        if (filterArray.length === 0) return undefined;
        return filterArray.map(object => object.value).join(',');
      };

      const filterConfig = {
        supplier_number: parseFilter(supplier),
        supplier_code: parseFilter(dispo),
        abc_classification: parseFilter(abcClassification),
        material_group_name: parseFilter(group),
      };

      if (this.selectedReport.id === 'list-of-filtered-materials') {
        const tableReportableConfig = {};
        this.tableReportables
          .filter(reportable => (
            reportable.category != null
            && reportable.subcategory != null
            && reportable.operator != null
            && reportable.operand != null
          ))
          .forEach(reportable => {
            const matchingReportable = this.selectedReport.reportables.find(thisReportable => (
              thisReportable.category === `${reportable.category}.${reportable.subcategory}`
            ));
            tableReportableConfig[`eval:${matchingReportable.id}:${reportable.operator}`] = reportable.operand;
          });

        return {
          ...this.getBaseConfig(),
          ...filterConfig,
          ...tableReportableConfig,
        };
      }
      if (this.selectedReport.id === 'min-max-report') {
        return {
          ...filterConfig,
          a_range_min: this.partInfos[0].min,
          a_range_max: this.partInfos[0].max,
          a_upwards_tolerance: this.partInfos[0].tolerancePlus / 100,
          a_downwards_tolerance: this.partInfos[0].toleranceMinus / 100,
          b_range_min: this.partInfos[1].min,
          b_range_max: this.partInfos[1].max,
          b_upwards_tolerance: this.partInfos[1].tolerancePlus / 100,
          b_downwards_tolerance: this.partInfos[1].toleranceMinus / 100,
          c_range_min: this.partInfos[2].min,
          c_range_max: this.partInfos[2].max,
          c_upwards_tolerance: this.partInfos[2].tolerancePlus / 100,
          c_downwards_tolerance: this.partInfos[2].toleranceMinus / 100,
        };
      }
      throw new Error('invalid report settings');
    }

    return {
      ...this.getBaseConfig(),
      axis_scale: this.axisScale,
      material_number: this.selectedMaterial,
    };
  }

  @action.bound
  getReport = async () => {
    const config = this.getReportConfig();

    this.loading = true;
    const response = await getReportFromUrl(this.selectedReport.url, config, this.root.user.token);
    if (response.error !== undefined) {
      this.root.enqueueSnackbar(`Laden des Reports fehlgeschlagen:
      ${Array.isArray(response.error.data) ? response.error.data.toString() : ''}`, { variant: 'error' });
      this.root.ui.showReportConfig();
    } else {
      this.report = response.value;
      this.root.ui.hideReportConfig();
    }
    this.loading = false;
  }

  @action.bound
  getInfo = async () => {
    const response = await getInfo(this.root.user.token);
    if (response.error !== undefined) {
      this.root.enqueueSnackbar('Laden der Information fehlgeschlagen');
    } else {
      this.info = response.value;
    }
  }

  @action.bound
  getCategories = async () => {
    const response = await getCategories();
    if (response.error !== undefined) {
      this.root.enqueueSnackbar('Verbindung zum Berichtsdienst fehlgeschlagen');
    } else {
      this.reportCategories = response.value;
    }
  }

  getFiscalYearStart = (year) => `${year - 1}-10-01`
  getFiscalYearEnd = (year) => `${year}-09-30`

  @action.bound
  selectReport(key) {
    const reportCategory = this.reportCategories.find(category => category.id === this.selectedReportCategory);
    if (reportCategory) {
      const matchingReport = reportCategory.reports.find(report => report.id === key);
      this.selectedReport = matchingReport;
    } else {
      this.selectedReport = undefined;
    }
    this.selectedReportables = [];
    this.report = undefined;
    this.loading = false;
    this.root.ui.showReportConfig();
    this.axisScale = '';
    this.previewUrl = '';
  }

  @action.bound
  selectReportCategory(key) {
    this.selectedReportCategory = key;
    this.selectReport(undefined);
  }

  @action.bound
  selectMaterial(material) {
    this.selectedMaterial = material;
  }

  @action.bound
  activateDashboardFilter() {
    const dashboardFilters = this.root.user.dispoFilters;
    if (dashboardFilters[0] === 'all') {
      this.deactivateDashboardFilter();
    } else {
      const activePresetDispos = this.root.user.user.profile.dispo_presets
        .filter(f => dashboardFilters.includes(f.pk))
        .reduce((allActive, profile) => [...allActive, ...profile.dispos.map(d => d.code)], [])
        .map(value => ({ value, label: value }));
      const activeOwnDispos = (dashboardFilters.includes('own')
        ? this.root.user.user.profile.dispos.map(d => d.code)
        : []).map(value => ({ value, label: value }));
      this.tableFilter.dispo = [...activePresetDispos, ...activeOwnDispos];
    }
  }

  @action.bound
  deactivateDashboardFilter() {
    this.tableFilter.dispo = [];
  }

  @action.bound
  updatePreviewUrl = () => {
    const {
      selectedReport,
      getReportConfig,
      selectedReportables,
      root,
    } = this;

    const {
      token,
    } = root.user;

    const reportQuery = selectedReport ? queryString.stringify({
      reportPath: selectedReport.url,
      token,
      ...getReportConfig(),
      reportables: selectedReportables,
    }) : '';

    this.previewUrl = `${htmlBaseUrl}?${reportQuery}`;
    this.pdfUrl = `${pdfBaseUrl}?${reportQuery}`;
    this.previewLoading = true;
  }

  @action.bound
  previewLoadComplete = () => {
    this.previewLoading = false;
  }

  @action.bound
  resetPartInfos = () => {
    this.partInfos = defaultPartInfos;
  }
}
