import React from 'react';
import PropTypes from 'prop-types';
import { isNil } from 'ramda';
import { DateTime } from 'luxon';
import { inject, observer, PropTypes as MobxPropTypes } from 'mobx-react';
import { withSnackbar } from 'notistack';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Typography from '@material-ui/core/Typography';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CircularProgress from '@material-ui/core/CircularProgress';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { MuiThemeProvider, withStyles } from '@material-ui/core/styles';
import FileDrop from 'react-file-drop';
import { withApollo } from 'react-apollo';

import { upload } from 'Api/endpoints/sheet';
import { style } from './style';
import NewDeliveryFormInner from '../DeliveryForm/NewDeliveryFormInner';
import { DeleteSheetUpload, GetSuppliers, getTemplateUrl } from './api';
import ConfirmDeleteModal from './ConfirmDeleteModal';
import EditDeliverySheetModal from './Edit/EditDeliverySheetModal';
import Autocomplete from '../Autocomplete';
import lightTheme from '../../theme';
import DeliveryModal from './DeliveryModal';

@inject('store')
@observer
class UploadForm extends React.Component {
  static propTypes = {
    client: PropTypes.object.isRequired,
    store: MobxPropTypes.objectOrObservableObject,
    enqueueSnackbar: PropTypes.func,
    classes: PropTypes.object,
  }

  constructor(props) {
    super(props);
    this.dropRef = React.createRef();
    this.state = {
      suppliers: [],
      file: null,
      uploading: false,
      loadingSuppliers: false,
      selectedSupplier: '',
      selectedUpload: null,
      showConfirmDeleteModal: false,
      showSheetEditModal: false,
    };
  }

  componentDidMount() {
    this.mounted = true;
    this.initialize();
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  getSupplierList = async () => {
    this.setState({
      loadingSuppliers: true,
    });
    try {
      const response = await this.props.client.query({
        query: GetSuppliers,
      });
      if (this.mounted) {
        if (response.error) {
          throw new Error(response.error);
        }
        const suppliers = response.data.allSuppliers.edges.map(({ node }) => node);
        this.setState({
          suppliers,
          loadingSuppliers: false,
        });
      }
    } catch (error) {
      if (this.isMounted) {
        this.setState({
          loadingSuppliers: false,
        });
        this.props.store.handleError(error, 'Lieferantenliste konnte nicht geladen werden');
      }
    }
  }

  initialize = () => {
    this.getSupplierList();
    this.props.store.source.updateSheetUploads();
  }

  handleChange = (event) => {
    const selectedSupplier = event.value;
    this.setState({
      selectedSupplier,
    });
  }

  handleImport = async () => {
    this.setState({
      uploading: true,
    });
    this.props.enqueueSnackbar('Lieferungen werden importiert...');
    const resp = await upload(this.state.selectedSupplier, this.state.file, this.props.store.user.token);
    if (isNil(resp.error)) {
      await this.props.store.source.updateSheetUploads();
      this.props.enqueueSnackbar('Import abgeschlossen', { variant: 'success' });
      this.setState({
        uploading: false,
        file: null,
        selectedSupplier: '',
      });
      this.props.store.ui.toggleUploadModal();
    } else {
      let errorMessage = '';
      if (resp.error.status === 500) {
        errorMessage = 'Unbekannter Fehler: Bitte Support kontaktieren';
      } else if (resp.error.response.data && resp.error.response.data.file) {
        const fileErrors = resp.error.response.data.file[0];
        switch (fileErrors.error_id) {
          case 'invalid_file_extension':
            if (fileErrors.allowed_values.length > 1) {
              errorMessage = `Ungültiges Dateiformat. Nur Datein mit den Erweiterungen ${fileErrors.allowed_values.join(', ')} sind erlaubt.`;
              break;
            }
            errorMessage = `Ungültiges Dateiformat. Nur Datein mit der Erweiterung ${fileErrors.allowed_values[0]} ist erlaubt.`;
            break;
          case 'partially_filled_row':
            errorMessage = `Unvollständige Daten in Reihe ${fileErrors.row}.`;
            break;
          case 'invalid_delivery_date':
            errorMessage = `Ungültiges Lieferdatum in Reihe ${fileErrors.row}.`;
            break;
          case 'updated_on_invalid_date':
            errorMessage = `Ungülties Aktualisierungsdatum im Cell ${fileErrors.cell}.`;
            break;
          case 'non_existing_material':
            errorMessage = `Ungültige Materialnummer ${fileErrors.material_number} in Reihe ${fileErrors.row}.`;
            break;
          case 'invalid_delivery_status':
            errorMessage = `Ungültiger Status in Reihe ${fileErrors.row}. Erlaubte Werte sind ${fileErrors.allowed_values.join(', ')}`;
            break;
          case 'invalid_material_quantity':
            errorMessage = `Ungültige Mengenangabe in Reihe ${fileErrors.row}`;
            break;
          case 'invalid_order_id':
            errorMessage = `Ungültige Ordernummer in Reihe ${fileErrors.row}`;
            break;
          default:
            errorMessage = 'Unbekannter Fehler: Bitte Support kontaktieren';
            break;
        }
      } else if (resp.error.response.data) {
        errorMessage = Object.keys(resp.error.response.data).map(object => (
          `${object}: ${resp.error.response.data[object].join(', ')}`
        )).join('; ');
      } else {
        errorMessage = resp.error;
      }

      this.props.store.handleError(resp.error, `Fehler beim Upload von ${this.state.file.name}: ${errorMessage}`);
      this.setState({
        uploading: false,
      });
    }
  }

  handleFileSelect = (event) => {
    const [file] = event.target.files;
    this.uploadFile(file);
  }

  handleFileDrop = ([file]) => {
    if (file.name.slice(-5) === '.xlsx') {
      this.uploadFile(file);
    } else {
      this.props.enqueueSnackbar('Nur .xlsx erlaubt', { variant: 'error' });
    }
  }

  uploadFile = (file) => {
    this.setState({
      file,
    });
  }

  deleteSelectedUpload = async () => {
    try {
      const response = await this.props.client.mutate({
        mutation: DeleteSheetUpload,
        variables: {
          pk: this.state.selectedUpload.id,
        },
      });
      if (this.mounted) {
        if (response.error) {
          throw new Error(response.error);
        }
        this.setState({
          showConfirmDeleteModal: false,
        });
        this.props.store.source.updateSheetUploads();
      }
    } catch (error) {
      if (this.isMounted) {
        this.props.store.handleError(error, 'Löschen Fehlgeschlagen');
      }
    } finally {
      this.setState({
        selectedUpload: null,
      });
    }
  }

  saveSelectedUpload = async (sheetUpdates) => {
    await this.props.store.source.updateDeliverySheet(sheetUpdates);
  }

  toggleConfirmDelete = () => {
    this.setState(prevState => ({
      showConfirmDeleteModal: !prevState.showConfirmDeleteModal,
    }));
  }

  toggleEdit = () => {
    this.setState(prevState => ({
      showSheetEditModal: !prevState.showSheetEditModal,
    }));
  }

  handleDeleteClicked = (selUpload) => () => {
    this.setState({
      selectedUpload: selUpload,
      showConfirmDeleteModal: true,
    });
  }

  handleEditClicked = (selectedUpload) => () => {
    this.setState({
      selectedUpload,
      showSheetEditModal: true,
    });
  }

  renderUploadLine = (line) => {
    const {
      classes,
    } = this.props;

    const parsedDate = DateTime.fromISO(line.created_date);

    return (
      <ListItem key={line.id} className={classes.listItem}>
        {/* Temporarily remove this feature
        <ListItemIcon className={classes.itemIcon}>
          <IconButton
            onClick={this.handleEditClicked(line)}
            data-testid="edit-button"
          >
            <EditIcon/>
          </IconButton>
        </ListItemIcon>
        */}
        <ListItemIcon className={classes.itemIcon}>
          <IconButton
            onClick={this.handleDeleteClicked(line)}
            data-testid="delete-button"
            aria-label="Löschen"
          >
            <DeleteIcon/>
          </IconButton>
        </ListItemIcon>
        <ListItemIcon className={classes.itemIcon}>
          <DeliveryModal upload={line} />
        </ListItemIcon>
        <ListItemText>
          <Typography className={classes.historyLine}>
            <span>
              {line.supplier_name}
            </span>
            <span>
              {parsedDate.toLocaleString({ locale: 'de', ...DateTime.DATETIME_SHORT })}
            </span>
          </Typography>
        </ListItemText>
      </ListItem>
    );
  }

  renderSuppliers = () => {
    if (this.state.suppliers.length === 0) {
      return null;
    }
    return this.state.suppliers.map((supplier) => ({
      label: `${supplier.pk} - ${supplier.name}`,
      value: supplier.pk,
    }));
  }

  render() {
    const {
      classes,
      store,
    } = this.props;

    const {
      uploading,
      file,
      selectedSupplier,
      loadingSuppliers,
      showConfirmDeleteModal,
      showSheetEditModal,
      selectedUpload,
    } = this.state;

    const { latestUploads } = store.source;

    return (
      <>
        <div className={classes.row}>
          <div className={classes.column}>
            <Typography variant="subtitle1">Verlauf</Typography>
            {loadingSuppliers
              ? <CircularProgress/>
              : (
                <List id="upload-history" className={classes.list}>
                  {latestUploads.map((thisUpload) => this.renderUploadLine(thisUpload))}
                </List>
              )}
          </div>
          <form className={classes.column}>
            <Typography variant="subtitle1">xlsx Import</Typography>
            <FormControl className={classes.formControl}>
              <input
                accept=".xlsx"
                className={classes.input}
                id="upload-button"
                type="file"
                onChange={this.handleFileSelect}
              />
              <label htmlFor="upload-button">
                <div
                  className={classes.fileDrop}
                >
                  <FileDrop
                    onDrop={this.handleFileDrop}
                    frame={this.dropRef.current}
                  >
                    {file
                      ? file.name
                      : 'Datei auswählen oder hier ablegen'}
                  </FileDrop>
                </div>
              </label>
            </FormControl>

            <FormControl className={classes.formControl}>
              <Autocomplete
                defaultInputValue={selectedSupplier}
                defaultOptions={this.renderSuppliers()}
                onChange={this.handleChange}
                label="Lieferant"
                name="supplierCode"
              />
            </FormControl>
            <div className={classes.buttons}>
              <Button
                href={getTemplateUrl()}
                aria-label="Vorlage herunterladen"
              >
                <SaveAltIcon className={classes.buttonIcon}/> Vorlage herunterladen
              </Button>
              <Button
                onClick={this.handleImport}
                color="primary"
                disabled={uploading || (!file || !selectedSupplier)}
                aria-label="Importieren"
              >
                <CloudUploadIcon className={classes.buttonIcon}/> {uploading ? 'Uploading' : 'Import'}
              </Button>
            </div>
          </form>
          {this.props.store.user.user.permissions.includes('delivery.add_deliveryline') && (
            <div className={classes.column}>
              <Typography variant="subtitle1">Einzellieferung</Typography>
              <NewDeliveryFormInner/>
            </div>
          )}
        </div>
        <MuiThemeProvider theme={lightTheme}>
          <ConfirmDeleteModal
            open={showConfirmDeleteModal}
            onClose={this.toggleConfirmDelete}
            onCancel={this.toggleConfirmDelete}
            onConfirm={this.deleteSelectedUpload}
          />
          <EditDeliverySheetModal
            open={showSheetEditModal}
            onCancel={this.toggleEdit}
            onSave={this.saveSelectedUpload}
            deliveryUpload={selectedUpload}
          />
        </MuiThemeProvider>
      </>
    );
  }
}

export default withApollo(withSnackbar(withStyles(style)(UploadForm)));
