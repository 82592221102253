import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

const LinkLabel = ({
  id,
  text,
  icon,
  classes,
}) => (
  <span id={id} className={classes.container}>
    <span className={classes.icon}>
      {icon}
    </span>
    <Typography>{text}</Typography>
  </span>
);

LinkLabel.propTypes = {
  id: PropTypes.string,
  text: PropTypes.string,
  icon: PropTypes.object,
  classes: PropTypes.object,
};

const style = () => ({
  container: {
    display: 'flex',
    textTransform: 'initial',
  },
  icon: {
    marginRight: '3px',
  },
});

export default withStyles(style)(LinkLabel);
