import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import { PropTypes as MobxPropTypes, inject, observer } from 'mobx-react';
import { observable, action } from 'mobx';
import { withRouter } from 'react-router';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';
import { withSnackbar } from 'notistack';
import { isNil } from 'ramda';
import CircularProgress from '@material-ui/core/CircularProgress';

import { login, getUserInfo } from 'Api/endpoints/auth';

@inject('store')
@observer
class Login extends Component {
  @observable username = '';

  @observable password = '';

  static propTypes = {
    store: MobxPropTypes.objectOrObservableObject,
    history: ReactRouterPropTypes.history.isRequired,
    location: ReactRouterPropTypes.location.isRequired,
    classes: PropTypes.object,
    enqueueSnackbar: PropTypes.func,
  };

  constructor(props) {
    super(props);
    const hashObject = {};
    const { hash } = props.location;
    if (hash.length > 0) {
      hash.split('#')[1].split('&').forEach((keyValString) => {
        const [key, val] = keyValString.split('=');
        hashObject[key] = val;
      });

      if (hashObject.token) {
        this.state = {
          authenticating: true,
        };
        this.loginWithToken(hashObject.token);
        return;
      }
    }
    this.state = {
      authenticating: false,
    };
  }

  @action.bound onUsernameChange(e) {
    this.username = e.target.value;
  }

  @action.bound onPasswordChange(e) {
    this.password = e.target.value;
  }

  @action.bound onPasswordKeyUp(e) {
    if (e.keyCode === 13) {
      // Enter
      this.formLogin(e);
    }
  }

  loginWithToken = async (token) => {
    const user = await getUserInfo(token);
    this.setState({
      authenticating: false,
    });
    this.doLogin(user, token);
  }

  @action.bound redirectToDashboard() {
    this.props.history.push('/');
  }

  @action.bound
  async formLogin(event) {
    event.preventDefault();
    const { username, password } = this;
    const { error, value: { token, user } = {} } = await login(username, password);

    if (isNil(error)) {
      this.doLogin(user, token);
    } else {
      this.props.store.handleError(error, `Login failed: ${error}`);
    }
  }

  @action.bound
  doLogin(user, token) {
    this.props.enqueueSnackbar('Login erfolgreich', { variant: 'success' });
    this.props.store.user.setUser(user);
    this.props.store.user.setToken(token);
    this.props.store.notifications.getNotifications(token);
    this.redirectToDashboard();
  }

  render() {
    const {
      classes,
    } = this.props;

    const {
      authenticating,
    } = this.state;

    if (authenticating) {
      return (
        <div className={classes.loadingContainer}>
          <CircularProgress />
        </div>
      );
    }
    return (
      <div className="siteContainer" id="__screen_login">
        <form className={classes.form}>
          <Typography variant="h4">VF TEST ENVIRONMENT Login</Typography>
          <TextField
            id="username"
            name="username"
            label="Username"
            className={classes.textField}
            value={this.username}
            onChange={this.onUsernameChange}
          />
          <TextField
            id="password"
            name="password"
            label="Password"
            className={classes.textField}
            onChange={this.onPasswordChange}
            onKeyUp={this.onPasswordKeyUp}
            type="password"
          />
          <Button color="primary" onClick={this.formLogin} aria-label="Einloggen">
            Sign in
          </Button>
        </form>
      </div>
    );
  }
}

const styles = (theme) => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    color: theme.palette.primary.main,
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  loadingContainer: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export default withStyles(styles)(withSnackbar(withRouter(Login)));
