import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import TableCell from '../TableCell';
import StatusBar from '../../StatusBar';

const StatusCell = ({ value, classes }) => {
  if (value) {
    return (
      <TableCell>
        <div className={classNames(classes.container)}>
          <StatusBar status={value} />
        </div>
      </TableCell>
    );
  }

  return null;
};

StatusCell.propTypes = {
  classes: PropTypes.object,
  value: PropTypes.string,
};

const style = () => ({
  container: {
    position: 'absolute',
    left: 0,
    top: 0,
    height: '100%',
    width: 10,
  },
});

export default withStyles(style)(StatusCell);
