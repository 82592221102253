import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { getReliableSuppliers } from 'Api/endpoints/reliable-supplier';
import { withStyles } from '@material-ui/core/styles';
import {
  Button,
  List,
  ListItem,
  Popover,
} from '@material-ui/core';
import HeartIcon from '@material-ui/icons/FavoriteBorder';
import SvgIcon from '@material-ui/core/SvgIcon';
import CheckIcon from '@material-ui/icons/Check';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Badge from '@material-ui/core/Badge';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import { trimLead0s } from 'utils/displayUtils';
import { ReactComponent as CalledIcon } from '../ProjectCard/abgerufen.svg';
import { ReactComponent as MissingIcon } from '../ProjectCard/fehlteile.svg';
import { ReactComponent as DeliveryIcon } from '../../assets/trolley.svg';

const Legend = ({ classes, playIntro, token }) => {
  const [reliableSuppliersAnchor, setReliableSuppliersAnchor] = useState(null);
  const [reliableSuppliers, setReliableSuppliers] = useState([]);

  useEffect(() => {
    const retrieveSuppliers = async () => {
      const { value } = await getReliableSuppliers(token);
      setReliableSuppliers(value);
    };

    if (token) {
      retrieveSuppliers();
    }
  }, [token]);

  const showReliableSuppliers = (event) => {
    setReliableSuppliersAnchor(event.currentTarget);
  };
  const hideReliableSuppliers = () => {
    setReliableSuppliersAnchor(null);
  };

  const reliableSuppliersVisible = !!reliableSuppliersAnchor;
  const popoverId = reliableSuppliersVisible ? 'reliable-suppliers' : undefined;

  const icons = [
    {
      icon: <HeartIcon className={classes.icon} />,
      label: 'Priorisiert',
    },
    {
      icon: (
        <SvgIcon className={classes.icon}>
          <CalledIcon />
        </SvgIcon>
      ),
      label: 'Abgerufen',
    },
    {
      icon: (
        <SvgIcon className={classes.icon}>
          <MissingIcon />
        </SvgIcon>
      ),
      label: 'Fehlteile in SCMIS',
    },
    {
      icon: (
        <CheckIcon className={classes.icon} />
      ),
      label: 'Alle Reservierungen bedient (Komponenten beliefert)',
    },
    {
      icon: <span>E. Fehlteile =</span>,
      label: 'Errechnete Fehlteile: Anzahl Komponenten aufgrund Fehlbestand und fehlender Materialanlieferung vor Abruf',
    },
    {
      icon: (
        <>
          <SvgIcon className={classNames(classes.icon, classes.deliveryIcon)}>
            <DeliveryIcon />
          </SvgIcon>
          <span>= SAP Bestätigungen werden automatisch aktualisiert</span>
          <Popover
            id={popoverId}
            open={reliableSuppliersVisible}
            anchorEl={reliableSuppliersAnchor}
            onClose={hideReliableSuppliers}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            <List>
              {reliableSuppliers.map(supplier => (
                <ListItem key={supplier.id}>{trimLead0s(supplier.number)} {supplier.name}</ListItem>
              ))}
            </List>
          </Popover>
        </>
      ),
      label: <Button className={classes.expandButton} onClick={showReliableSuppliers} aria-label="Freigeschaltete Lieferanten">Freigeschaltete Lieferanten<ExpandMoreIcon /></Button>,
    },
    {
      icon: (
        <div>
          <Badge
            badgeContent={(
              <ShoppingCartIcon
                style={{ width: '16px', height: '16px', color: 'rgba(191, 191, 191, 1)' }}
              />
                )}
            overlap="circle"
          >
            <AddCircleIcon />
          </Badge>
        </div>
      ),
      label: 'Schnellanlieferung hinzufügen',
    },
  ];

  return (
    <div className={classes.container}>
      <div className={classes.iconContainer}>
        {icons.map((icon) => (
          <span key={icon.label} className={classes.iconGroup}>
            {icon.icon}
            <span className={classes.label}>
              {icon.label}
            </span>
          </span>
        ))}
      </div>
      <Button onClick={playIntro} className={classes.introButton} aria-label="Intro abspielen">
        Intro abspielen
      </Button>
    </div>
  );
};

Legend.propTypes = {
  classes: PropTypes.object,
  playIntro: PropTypes.func,
  token: PropTypes.string,
};

const style = () => ({
  iconContainer: {
    margin: '10px 0',
    display: 'flex',
    flexFlow: 'row wrap',
    alignItems: 'center',
  },
  iconGroup: {
    marginRight: '20px',
    fontSize: '1rem',
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
  },
  icon: {
    fontSize: '1rem',
    height: '1em',
    width: '1em',
    color: 'white',
    marginBottom: '2px',
  },
  deliveryIcon: {
    fontSize: '1.5rem',
  },
  label: {
    marginLeft: '5px',
  },
  introButton: {
    marginLeft: '-8px',
  },
  expandButton: {
    textTransform: 'unset',
    fontSize: '1rem',
    fontWeight: 'normal',
  },
});

export default withStyles(style)(Legend);
