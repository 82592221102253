import { action, observable } from 'mobx';
import {
  getNotifications,
  markNotificationAsDone,
  markNotificationAsRead,
  deleteNotification,
} from '../Api/endpoints/notifications';

export default class Notifications {
  constructor(rootStore) {
    this.root = rootStore;
  }

  @observable notifications = [];

  @action.bound getNotifications = async () => {
    try {
      this.notifications = await getNotifications(this.root.user.token, this.root.user.user.profile.location);
    } catch (e) {
      this.root.enqueueSnackbar('Fehler beim Abrufen von Benachrichtungen', {
        variant: 'error',
      });
    }
  }

  @action.bound setNotificationReadState = async (id, state) => {
    const updatedNotification = await markNotificationAsRead(id, this.root.user.token, state);
    this.notifications = this.notifications.map(notification => {
      if (notification.id === updatedNotification.id) {
        return updatedNotification;
      }
      return notification;
    });
  }

  @action.bound completeNotification = async (id) => {
    const updatedNotification = await markNotificationAsDone(id, this.root.user.token);
    this.notifications = this.notifications.map(notification => {
      if (notification.id === updatedNotification.id) {
        return updatedNotification;
      }
      return notification;
    });
  }

  @action.bound deleteNotification = async (id) => {
    const deletedNotification = await deleteNotification(id, this.root.user.token);
    this.notifications = this.notifications
      .filter(notification => notification.id !== deletedNotification.notification_id);
  }
}
