import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { DateTime } from 'luxon';

import { withStyles } from '@material-ui/core/styles';
import { Modal } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import LinesList from './LinesList';
import NewDeliveryForm from '../../DeliveryForm/NewDeliveryForm';


const EditDeliverySheetModal = ({
  open, onCancel, onSave, classes, deliveryUpload,
}) => {
  const [editLine, setEditLine] = useState(null);
  const [deletedLines, setDeletedLines] = useState([]);
  const [updatedLines, setUpdatedLines] = useState([]);
  const [addedLines, setAddedLines] = useState([]);

  const handleRowClick = (line) => {
    if (line) {
      setEditLine({
        id: line.id,
        material_number: line.material_number,
        order_id: line.order_id,
        delivery_date: line.delivery_date ? DateTime.fromISO(line.delivery_date) : DateTime.local(),
        quantity: line.quantity && line.quantity.toString(),
        add: line.add,
      });
    }
  };

  const handleRowDelete = (line) => {
    if (line.id) {
      setDeletedLines([...deletedLines, line.id]);
    } else {
      setAddedLines(addedLines.filter(l => l.add !== line.add));
    }
  };

  const handleOrderIdChange = (event) => {
    setEditLine({
      ...editLine,
      order_id: event.target.value,
    });
  };

  const handleMaterialNumberChange = (material) => {
    if (material && material.value) {
      setEditLine({
        ...editLine,
        material_number: material.value,
      });
    }
  };

  const handleDateChange = (date) => {
    setEditLine({
      ...editLine,
      delivery_date: date,
    });
  };

  const handleQuantityChange = (event) => {
    setEditLine({
      ...editLine,
      quantity: event.target.value,
    });
  };

  const handleCancelEdit = () => {
    setEditLine(null);
  };

  const handleCloseClick = () => {
    setDeletedLines([]);
    setUpdatedLines([]);
    setAddedLines([]);
    setEditLine(null);
    onCancel();
  };

  const handleSaveEdit = () => {
    if (editLine.add) {
      const index = addedLines.findIndex(l => l.add === editLine.add);
      const newAddedLines = [...addedLines];
      newAddedLines[index] = editLine;
      setAddedLines(newAddedLines);
    } else {
      setUpdatedLines([...updatedLines,
        {
          ...editLine,
          delivery_date: editLine.delivery_date.toFormat('yyyy-MM-dd'),
        },
      ]);
    }
    setEditLine(null);
  };


  const handleSaveAdd = () => {
    setAddedLines([...addedLines,
      {
        ...editLine,
        delivery_date: editLine.delivery_date.toFormat('yyyy-MM-dd'),
        add: Math.random(),
      },
    ]);
    setEditLine(null);
  };

  const handleSaveChangesClick = () => {
    const toEditPayload = (line) => ({
      id: line.id,
      material_number: line.material_number,
      order: {
        order_id: line.order_id,
      },
      delivery_date: line.delivery_date,
      quantity: line.quantity,
    });

    const toAddPayload = (line) => ({
      material_number: line.material_number,
      order: {
        order_id: line.order_id,
      },
      delivery_date: line.delivery_date,
      quantity: line.quantity,
    });

    const toDeletePayload = (id) => ({
      id,
      _destroy: true,
    });

    const updatedPayload = updatedLines.map(toEditPayload);
    const addedPayload = addedLines.map(toAddPayload);
    const deletePayload = deletedLines.map(toDeletePayload);

    const sheetUpdates = {
      id: deliveryUpload.delivery_sheet.id,
      delivery_lines: [...updatedPayload, ...addedPayload, ...deletePayload],
    };
    onSave(sheetUpdates);
    handleCloseClick();
  };

  const displayRows = deliveryUpload && deliveryUpload.delivery_sheet
    && deliveryUpload.delivery_sheet
      .delivery_lines.filter(ul => !deletedLines.includes(ul.id))
      .map(line => ({
        ...line,
        order_id: line.order && line.order.order_id,
        ...updatedLines.find(ul => ul.id === line.id),
      }))
      .concat(addedLines)
      .concat([
        {
          new: true,
          material_number: '',
          order_id: '',
          delivery_date: '',
          quantity: '',
        },
      ]);

  return (
    <Modal
      open={Boolean(open)}
      onClose={onCancel}
      disableBackdropClick
    >
      <div className={classes.paper}>
        <Typography variant="h6">Lieferungen bearbeiten</Typography>

        <div className={classes.content}>
          <LinesList
            onRowClick={handleRowClick}
            onRowDelete={handleRowDelete}
            deliveryLines={displayRows}
          />

          <div className={classes.formWrapper}>

            {(editLine) ? (
              <>
                <Typography variant="subtitle1">Lieferung bearbeiten</Typography>
                <NewDeliveryForm
                  key="1"
                  numberOfMaterialsInput={editLine.quantity}
                  deliveryDate={editLine.delivery_date}
                  orderNumberInput={editLine.order_id}
                  selectedMaterial={editLine.material_number}
                  onMaterialInputChange={handleMaterialNumberChange}
                  onDateChange={handleDateChange}
                  onNumberOfMaterialsChange={handleQuantityChange}
                  onOrderNumberChange={handleOrderIdChange}
                />
                <div className={classes.buttons}>
                  <Button onClick={handleCancelEdit} aria-label="Abbrechen">Abbrechen</Button>
                  {(editLine.id || editLine.add) ? (
                    <Button
                      onClick={handleSaveEdit}
                      color="primary"
                      disabled={!editLine.material_number}
                      aria-label="Speichern"
                    >Speichern
                    </Button>
                  )
                    : (
                      <Button
                        onClick={handleSaveAdd}
                        color="primary"
                        disabled={!editLine.material_number}
                        aria-label="Hinzufügen"
                      >Hinzufügen
                      </Button>
                    )}
                </div>

              </>
            )

              : (
                <>
                  <Typography variant="subtitle1">
                    Bitte Zeile auswählen
                  </Typography>
                  <NewDeliveryForm
                    key="2"
                    disabled
                  />
                </>
              )}
          </div>

        </div>

        <div className={classes.buttons}>
          <Button onClick={handleCloseClick} aria-label="Abbrechen">Abbrechen</Button>
          <Button onClick={handleSaveChangesClick} color="primary" aria-label="Speichern">Speichern</Button>
        </div>
      </div>
    </Modal>
  );
};

EditDeliverySheetModal.propTypes = {
  open: PropTypes.bool,
  onCancel: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  classes: PropTypes.object,
  deliveryUpload: PropTypes.object,
};


const styles = theme => ({
  paper: {
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    position: 'absolute',
    width: '90%',
    height: '90vh',
    backgroundColor: theme.palette.background.paper,
    boxShoadow: theme.shadows[5],
    padding: theme.spacing(4),
    outline: 'none',
    display: 'flex',
    flexDirection: 'column',
    // '& h6': {
    //   flex: 1,
    // },
  },
  content: {
    display: 'flex',
    flexDirection: 'row',
    flex: '1 1 auto',
    overflowY: 'auto',
  },
  formWrapper: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    padding: theme.spacing(1),
  },
  buttons: {
    display: 'flex',
    justifyContent: 'end',
    flex: '0 0 auto',
  },
});


export default withStyles(styles)(EditDeliverySheetModal);
