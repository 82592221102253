import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import { rangeModes } from '../../Stores/reporting';


const CustomSelect = ({
  value, onChange, options, classes, selectProps, label,
}) => (
  <div className={classes.container}>
    {label ? <span className={classes.label}>{label}</span> : null}

    <FormControl variant="outlined" fullWidth className={classes.formControl}>
      <Select
        value={value}
        onChange={onChange}
        options={rangeModes}
        IconComponent={() => <KeyboardArrowDownIcon color="secondary"/>}
        {...selectProps}
      >
        {options.map((mode) => (
          <MenuItem
            className={classes.menuItem}
            key={mode.value}
            value={mode.value}
          >{mode.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  </div>
);

CustomSelect.propTypes = {
  value: PropTypes.any,
  onChange: PropTypes.func,
  options: PropTypes.array,
  classes: PropTypes.object,
  selectProps: PropTypes.object,
  label: PropTypes.string,
};

const styles = () => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  label: {
    flex: 1,
    fontWeight: 'bold',
  },
  formControl: {
    boxShadow: '1px 4px 6px -0px rgba(0,0,0,0.10)',
    borderRadius: '5px',
    flex: 3,
    fontWeight: 'bold',
  },
  menuItem: {
    fontWeight: 'bold',
  },
});

export default withStyles(styles)(CustomSelect);
