import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject, observer, PropTypes as MobxPropTypes } from 'mobx-react';
import {
  Checkbox,
  FormControlLabel,
} from '@material-ui/core';

@inject('store')
@observer
class MatrixCheckbox extends Component {
  render() {
    const {
      coords,
      disabled,
      label,
      store,
    } = this.props;

    const {
      selectedReportableMatrix,
      toggleReportableMatrixOption,
    } = store.reporting;

    return (
      <FormControlLabel
        control={(
          <Checkbox
            checked={selectedReportableMatrix[coords[0]].includes(coords[1])}
            onChange={() => toggleReportableMatrixOption(coords[0], coords[1])}
            disabled={disabled}
          />
        )}
        label={label}
      />
    );
  }
}

MatrixCheckbox.propTypes = {
  coords: PropTypes.array,
  store: MobxPropTypes.objectOrObservableObject,
  disabled: PropTypes.bool,
  label: PropTypes.string,
};

export default MatrixCheckbox;
