import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import SortIcon from '@material-ui/icons/Sort';
import SaveIcon from '@material-ui/icons/SaveAlt';
import XLSX from 'xlsx';
import { DateTime } from 'luxon';

import SimpleTableRow from './SimpleTableRow';
import TableGroup from './TableGroup';
import FilterRow from './FilterRow';
import { calculateScrollbarWidth } from '../../utils/displayUtils';

const scrollbarWidth = calculateScrollbarWidth();

function getHeaderStyle(column) {
  if (column.style) {
    return {
      flex: '1',
      width: column.width,
      ...column.style,
    };
  }
  if (column.width) {
    return {
      width: column.width,
    };
  }
  return {
    flex: '1',
  };
}

const downloadData = (columns, data, suffix) => () => {
  const body = data.map(row => columns.map((dataColumn) => {
    const displayValue = dataColumn.xlsxParser
      ? dataColumn.xlsxParser(row[dataColumn.accessor])
      : row[dataColumn.accessor];
    return displayValue;
  }));

  const xlsxFormat = [
    columns.map(column => column.header),
    ...body,
  ];

  const worksheet = XLSX.utils.aoa_to_sheet(xlsxFormat);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, 'SheetJS');
  XLSX.writeFile(workbook, `${DateTime.local().toFormat('yyyyMMddHHmm')}-${suffix}.xlsx`);
};

const SimpleTable = ({
  classes,
  columns,
  data,
  onRowClick,
  highlightField,
  highlightValue,
  onBodyScroll,
  groupProperty,
  grouped,
  keyColumn,
  keyColumns,
  rowHoverEffect,
  rowIntroId,
  paperless,
  rowToolTip,
  groupSort,
  canExport,
  rowBatchSize,
  downloadColumns,
  downloadSuffix,
  isCompact,
  filter,
  filterObject,
  filterReset,
  filterForm,
  handleDeleteFilterLine,
  onSort,
  sortObject,
  groupRow,
  startCollapsed,
  expandAll,
  highlightBottomLevel,
  auxiliaryInfo,
  preventGrow,
  introId,
}) => {
  const [rowsToShow, setRowsToShow] = useState(rowBatchSize || data.length);

  const usedDownloadColumns = downloadColumns || columns;

  const tableGroupClasses = {
    tableRow: classes.tableRow,
    table: classes.table,
    tableBody: classes.tableBody,
    tableCell: classes.tableCell,
    scrollbarCompensation: classes.scrollbarCompensation,
    dataRow: classes.dataRow,
  };

  const batchScrollEffect = (event) => {
    if (event.target.scrollTop + event.target.offsetHeight >= (event.target.scrollHeight - 40)) {
      setRowsToShow(rowsToShow + rowBatchSize);
    }
  };

  const displayData = rowBatchSize
    ? data.slice(0, rowsToShow)
    : data;

  if (canExport && downloadSuffix === undefined) {
    throw new Error('if export is possible, downloadSuffix must be provided');
  }

  const selectedRow = highlightField && data.find((row) => row[highlightField] === highlightValue);

  const onHeaderClick = (column) => () => {
    if (column.sortable) {
      if (sortObject.field === column.sortId) {
        if (sortObject.ascending) {
          onSort({
            ...sortObject,
            ascending: false,
          });
        } else {
          onSort({
            field: '',
            ascending: false,
            sortSum: column.sortSum,
          });
        }
      } else {
        onSort({
          field: column.sortId,
          ascending: true,
          sortSum: column.sortSum,
        });
      }
    }
  };

  return (
    <Paper
      className={classnames(classes.root, {
        [classes.paperless]: paperless,
        [classes.preventGrow]: preventGrow,
      })}
    >
      {filter && (
        <FilterRow
          filterObject={filterObject}
          handleReset={filterReset}
          filterForm={filterForm}
          handleDeleteFilterLine={handleDeleteFilterLine}
          auxiliaryInfo={auxiliaryInfo}
        />
      )}
      <Table component="div" className={classes.table}>
        <TableHead component="div" className={classes.tableHead}>
          <TableRow component="div" className={classnames(classes.tableRow, classes.headerRow)}>
            {columns.map((column) => {
              const isSorted = column.sortable && sortObject.field === column.sortId;

              return (
                <TableCell
                  id={column.key}
                  component="div"
                  key={`${column.key || column.accessor}-header`}
                  className={classnames(
                    classes.tableCell,
                    classes.headerCell,
                    {
                      [classes.sortable]: column.sortable,
                      [classes.centerAlign]: column.centered,
                    },
                  )}
                  style={getHeaderStyle(column)}
                  onClick={onHeaderClick(column)}
                >
                  {column.sortable && !isSorted && (
                    <SortIcon id="sort-icon" className={classes.sortIcon} />
                  )}
                  {isSorted && sortObject.ascending && (
                    <ArrowUpwardIcon className={classes.sortIcon} />
                  )}
                  {isSorted && !sortObject.ascending && (
                    <ArrowDownwardIcon className={classes.sortIcon} />
                  )}
                  {column.tooltip
                    ? (
                      <Tooltip title={column.tooltip}>
                        <p>{column.header}</p>
                      </Tooltip>
                    )
                    : column.header}
                </TableCell>
              );
            })}
          </TableRow>
          {canExport && (
            <IconButton
              id={introId === 'scmis-parts' ? 'download-button' : null}
              className={classes.downloadButton}
              onClick={downloadData(usedDownloadColumns, data, downloadSuffix)}
              color="secondary"
              aria-label="Herunterladen"
            >
              <SaveIcon />
            </IconButton>
          )}
          {selectedRow && (
            <SimpleTableRow
              {...{
                row: selectedRow,
                classes: tableGroupClasses,
                highlightField,
                highlightValue,
                onRowClick,
                columns,
                rowHoverEffect,
                rowIntroId,
                rowToolTip,
              }}
            />
          )}
        </TableHead>
        <TableBody
          component="div"
          className={classnames(classes.tableBody, filter ? classes.mainBody : classes.mainBodyWithoutFilter, {
            [`${classes.groupBody}`]: grouped,
            [`${classes.bodyWithHighlight}`]: selectedRow,
            [`${classes.expandedBodyWithHighlight}`]: selectedRow && !isCompact,
          })}
          onScroll={onBodyScroll || batchScrollEffect}
        >
          <TableGroup
            rowHoverEffect={rowHoverEffect}
            classes={tableGroupClasses}
            data={displayData}
            columns={columns}
            groupProperty={groupProperty}
            onRowClick={onRowClick}
            highlightField={highlightField}
            highlightValue={highlightValue}
            grouped={grouped}
            keyColumn={keyColumn}
            keyColumns={keyColumns}
            rowIntroId={rowIntroId}
            rowToolTip={rowToolTip}
            groupSort={groupSort}
            groupRow={groupRow}
            startCollapsed={startCollapsed}
            forceExpand={expandAll}
            forceHighlight={highlightBottomLevel}
            tableIntroId={introId}
          />
        </TableBody>
      </Table>
    </Paper>
  );
};

SimpleTable.propTypes = {
  classes: PropTypes.object.isRequired,
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  onRowClick: PropTypes.func,
  highlightField: PropTypes.string,
  highlightValue: PropTypes.any,
  onBodyScroll: PropTypes.func,
  groupProperty: PropTypes.object,
  grouped: PropTypes.bool,
  keyColumn: PropTypes.string,
  keyColumns: PropTypes.array,
  rowHoverEffect: PropTypes.bool,
  rowIntroId: PropTypes.string,
  paperless: PropTypes.bool,
  rowToolTip: PropTypes.any,
  groupSort: PropTypes.func,
  canExport: PropTypes.bool,
  downloadColumns: PropTypes.array,
  rowBatchSize: PropTypes.number,
  downloadSuffix: PropTypes.string,
  isCompact: PropTypes.bool,
  filter: PropTypes.bool,
  filterObject: PropTypes.object,
  filterReset: PropTypes.func,
  filterForm: PropTypes.object,
  handleDeleteFilterLine: PropTypes.func,
  sortObject: PropTypes.object,
  onSort: PropTypes.func,
  groupRow: PropTypes.func,
  startCollapsed: PropTypes.bool,
  expandAll: PropTypes.bool,
  highlightBottomLevel: PropTypes.bool,
  auxiliaryInfo: PropTypes.object,
  preventGrow: PropTypes.bool,
  introId: PropTypes.string,
};

export const tableStyles = {
  tableRow: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  centerAlign: {
    justifyContent: 'center',
  },
  table: {
    display: 'block',
    maxHeight: '100%',
    flex: 1,
  },
  tableHead: {
    display: 'block',
    marginRight: scrollbarWidth,
  },
  headerRow: {
    height: '44px',
  },
  headerCell: {
    fontWeight: 'bold',
    display: 'flex !important',
    alignItems: 'center',
    padding: '5px !important',
  },
  sortable: {
    cursor: 'pointer',
  },
  sortIcon: {
    color: 'rgba(128, 128, 128, 1)',
  },
  tableBody: {
    display: 'block',
  },
  tableCell: {
    display: 'block',
    padding: '0',
    '&:last-child': {
      paddingRight: 0,
    },
    boxSizing: 'border-box',
  },
  mainBody: {
    overflowY: 'scroll',
    height: 'calc(100% - 93px)',
  },
  mainBodyWithoutFilter: {
    overflowY: 'scroll',
    height: 'calc(100% - 43px)',
  },
  bodyWithHighlight: {
    height: 'calc(100% - 105px)',
  },
  expandedBodyWithHighlight: {
    height: 'calc(100% - 139px)',
  },
  root: {
    width: '100%',
    overflowX: 'auto',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'hidden',
  },
  paperless: {
    boxShadow: 'none',
    background: 'none',
  },
  dataRow: {},
  downloadButton: {
    position: 'absolute',
    top: -48,
    right: 0,
  },
  preventGrow: {
    position: 'absolute',
  },
};

const styles = () => (tableStyles);

export default withStyles(styles)(SimpleTable);
