import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject, observer, PropTypes as MobxPropTypes } from 'mobx-react';
import { toJS } from 'mobx';
import { withStyles } from '@material-ui/core/styles';
import { withSnackbar } from 'notistack';
import {
  Button,
} from '@material-ui/core';

import { extractErrorText } from '../../../Api/index';
import { allSupplierCodes } from '../../../utils/dispoUtils';
import Autocomplete from '../../Autocomplete';
import { updateProfile } from '../../../Api/endpoints/updateProfile';

@inject('store')
@observer
class ManageDispoProfile extends Component {
  state = {
    selectedDispos: [],
    selectedProfile: undefined,
  }

  deleteProfile = async () => {
    const {
      store,
      enqueueSnackbar,
    } = this.props;

    const {
      selectedProfile,
    } = this.state;

    const {
      user,
      token,
    } = store.user;

    if (user.profile) {
      const oldPresets = user.profile.dispo_presets ? toJS(user.profile.dispo_presets) : [];

      const newPresets = oldPresets.map(preset => {
        if (preset.pk === selectedProfile.value) {
          return {
            ...preset,
            _destroy: true,
          };
        }
        return preset;
      });

      const updatedProfile = {
        profile: {
          ...user.profile,
          dispo_presets: newPresets,
        },
      };

      try {
        const { value, error } = await updateProfile(updatedProfile, token);
        if (error) {
          throw error;
        }
        enqueueSnackbar('Erfolgreich gelöscht', { variant: 'success' });

        user.profile = value.profile;

        this.setState({
          selectedDispos: [],
          selectedProfile: undefined,
        });
      } catch (error) {
        store.handleError(error, extractErrorText(error));
      }
    } else {
      this.showOutOfDateError();
    }
  }

  saveProfile = async () => {
    const {
      store,
      enqueueSnackbar,
    } = this.props;

    const {
      selectedDispos,
      selectedProfile,
    } = this.state;

    const {
      user,
      token,
    } = store.user;

    if (user.profile) {
      const oldPresets = user.profile.dispo_presets ? toJS(user.profile.dispo_presets) : [];

      const newPresets = oldPresets.map(preset => {
        if (preset.pk === selectedProfile.value) {
          return {
            ...preset,
            dispos: selectedDispos.map(dispo => ({
              code: dispo.value,
            })),
          };
        }
        return preset;
      });

      const updatedProfile = {
        profile: {
          ...user.profile,
          dispo_presets: newPresets,
        },
      };

      try {
        const { value, error } = await updateProfile(updatedProfile, token);
        if (error) {
          throw error;
        }
        enqueueSnackbar('Aktualisierung erfolgreich', { variant: 'success' });

        user.profile = value.profile;
      } catch (error) {
        store.handleError(error, extractErrorText(error));
      }
    } else {
      this.showOutOfDateError();
    }
  }

  render() {
    const {
      classes,
      store,
    } = this.props;

    const {
      selectedDispos,
      selectedProfile,
    } = this.state;

    const {
      user,
    } = store.user;

    const availableProfiles = (user.profile.dispo_presets ? user.profile.dispo_presets : [])
      .map(profile => ({
        value: profile.pk,
        label: profile.title,
      }));

    const getDisposFromProfile = (profileId) => {
      const presets = user.profile.dispo_presets ? user.profile.dispo_presets : [];
      const selectedPreset = presets.find(preset => preset.pk === profileId);
      if (selectedPreset) {
        return selectedPreset.dispos.map(dispo => ({
          label: dispo.code,
          value: dispo.code,
        }));
      }
      return undefined;
    };

    return (
      <div className={classes.dispoForm}>
        <div className={classes.dropdownContainer}>
          <Autocomplete
            value={selectedProfile}
            defaultOptions={availableProfiles}
            onChange={(newSelection) => {
              this.setState({
                selectedProfile: newSelection,
                selectedDispos: getDisposFromProfile(newSelection.value),
              });
            }}
            label="Meine Gespeicherten Dispos"
            name="profile"
          />
        </div>
        <div className={classes.dropdownContainer}>
          <Autocomplete
            isMulti
            value={selectedDispos}
            defaultOptions={allSupplierCodes}
            onChange={(newSelection) => {
              this.setState({
                selectedDispos: newSelection,
              });
            }}
            label="Dispos"
            name="supplierCode"
          />
        </div>
        <Button
          onClick={this.saveProfile}
          aria-label="Speichern"
        >
          Speichern
        </Button>
        <Button
          onClick={this.deleteProfile}
          aria-label="Löschen"
        >
          Löschen
        </Button>
      </div>
    );
  }
}

ManageDispoProfile.propTypes = {
  classes: PropTypes.object,
  store: MobxPropTypes.objectOrObservableObject,
  enqueueSnackbar: PropTypes.func,
};

const style = () => ({
  dispoForm: {
    width: '100%',
    display: 'flex',
  },
  dropdownContainer: {
    flex: 1,
    margin: '0 5px',
  },
  textField: {
    flex: 1,
    margin: '0 5px',
  },
});

export default withSnackbar(withStyles(style)(ManageDispoProfile));
