import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import { DateTime } from 'luxon';
import SimpleTable from '../../SimpleTable';
import { trimLead0s } from '../../../utils/displayUtils';
import { reformatIsoDate } from '../../../utils/dateTools';


// todo class deliverylines

class LinesList extends Component {
  static propTypes = {
    classes: PropTypes.object,
    deliveryLines: PropTypes.array.isRequired,
    onRowClick: PropTypes.func,
    onRowDelete: PropTypes.func,
  }

  renderActions = (props) => {
    const {
      onRowClick,
      onRowDelete,
      classes,
    } = this.props;


    if (props.row) {
      const isEditable = !props.row.delivery_date
        || DateTime.local().startOf('day') <= DateTime.fromISO(props.row.delivery_date).startOf('day');
      if (isEditable) {
        return (
          <div className={classes.tableCell}>
            {props.row.new ? (
              <IconButton onClick={() => onRowClick(props.row)} aria-label="Hinzufügen">
                <AddIcon/>
              </IconButton>
            ) : (
              <>
                <IconButton onClick={() => onRowClick(props.row)} aria-label="Bearbeiten">
                  <EditIcon/>
                </IconButton>
                <IconButton onClick={() => onRowDelete(props.row)} aria-label="Löschen">
                  <DeleteIcon/>
                </IconButton>
              </>
            )}
          </div>
        );
      }
    }
    return <div className={classes.tableCell}/>;
  }

  renderCell = (props) => {
    const { classes } = this.props;
    return (
      <div className={classes.tableCell}>
        {props.row}
      </div>
    );
  }


  renderNumberCell = (props) => {
    const numberProps = {
      ...props,
      row: trimLead0s(props.row),
    };
    return this.renderCell(numberProps);
  }

  renderDateCell = (props) => this.renderCell({
    ...props,
    row: props.row !== '' ? reformatIsoDate(props.row) : null,
  })

  render() {
    const {
      classes,
      deliveryLines,
    } = this.props;

    const tableColumns = [
      {
        header: 'Material',
        accessor: 'material_number',
        Cell: this.renderNumberCell,
      },
      {
        header: 'Ordernummer',
        accessor: 'order_id',
        Cell: this.renderCell,
      },
      {
        header: 'Menge',
        accessor: 'quantity',
        Cell: this.renderCell,
      },
      {
        header: 'Lieferdatum',
        accessor: 'delivery_date',
        Cell: this.renderDateCell,
      },
      {
        header: '',
        key: 'actions',
        Cell: this.renderActions,
      },
    ];

    return (
      <div className={classes.container}>
        <SimpleTable
          keyColumn="id"
          columns={tableColumns}
          data={deliveryLines}
        />
      </div>
    );
  }
}

const styles = theme => ({
  container: {
    display: 'flex',
    margin: '1em',
    maxHeight: '100%',
    overflowY: 'hidden',
    flex: 2,
  },
  tableCell: {
    display: 'flex',
    flexDirection: 'row',
    padding: theme.spacing(0, 1),
    alignItems: 'center',
    minHeight: 48,
  },
});

export default withStyles(styles)(LinesList);
