import { join, pathOr } from 'ramda';
import { notificationApi } from '../index';

export const NOTIFICATION_PATH = 'v1/notification';

export async function getNotifications(token, location) {
  const locationQuery = location ? `?location=${location}` : '';
  const res = await notificationApi.get(
    NOTIFICATION_PATH + locationQuery,
    {
      headers: {
        Authorization: `JWT ${token}`,
      },
    },
  );
  return res.data;
}

export async function markNotificationAsRead(id, token, read = true) {
  try {
    const res = await notificationApi.put(
      `${NOTIFICATION_PATH}/${id}`,
      {
        read,
      },
      {
        headers: {
          Authorization: `JWT ${token}`,
          'Content-Type': 'application/json',
        },
      },
    );
    return res.data;
  } catch (e) {
    const extractErrors = pathOr([], ['response', 'data', 'non_field_errors']);

    return {
      error: join('. ', extractErrors(e)),
    };
  }
}

export async function markNotificationAsDone(id, token) {
  try {
    const res = await notificationApi.put(
      `${NOTIFICATION_PATH}/${id}`,
      {
        done: true,
      },
      {
        headers: {
          Authorization: `JWT ${token}`,
          'Content-Type': 'application/json',
        },
      },
    );
    return res.data;
  } catch (e) {
    const extractErrors = pathOr([], ['response', 'data', 'non_field_errors']);

    return {
      error: join('. ', extractErrors(e)),
    };
  }
}

export async function deleteNotification(id, token) {
  try {
    const res = await notificationApi.delete(
      `${NOTIFICATION_PATH}/${id}`,
      {
        headers: {
          Authorization: `JWT ${token}`,
          'Content-Type': 'application/json',
        },
      },
    );
    return res.data;
  } catch (e) {
    const extractErrors = pathOr([], ['response', 'data', 'non_field_errors']);

    return {
      error: join('. ', extractErrors(e)),
    };
  }
}
