import API, { extractErrorText } from '../index';

async function upload(supplier, file, token) {
  try {
    const formData = new FormData();
    formData.set('file', file, file.name);
    formData.set('supplier_number', supplier);

    const res = await API.post(
      'delivery-sheet-upload/',
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `JWT ${token}`,
        },
      },
    );

    return {
      value: res.data,
    };
  } catch (e) {
    return {
      error: e,
    };
  }
}

export { upload };
