import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';


const ValueCard = ({
  id,
  classes,
  title,
  scmisTotal,
  nonProductionOrderRelatedTotal,
  expectedSevenDays,
  expectedFourteenDays,
  loading,
}) => (
  <div id={id} className={classes.container}>
    <div className={classes.title}>
      <Typography variant="h6" color="secondary">
        {title}
      </Typography>
    </div>
    <Paper elevation={1} square>
      {loading && (
        <div className={classes.loadingOverlay}>
          <CircularProgress />
        </div>
      )}
      <div className={classes.content}>
        <div className={classes.item}>
          <Typography variant="subtitle2" color="secondary">
            SCMIS
          </Typography>
          <Typography variant="h3" color="secondary">
            {scmisTotal}
          </Typography>
        </div>
        <div className={classes.spacer} />
        <div className={classes.item}>
          <Typography variant="subtitle2" color="secondary">
            SCMIS (keine FAUF-Teile)
          </Typography>
          <Typography variant="h3" color="secondary">
            {nonProductionOrderRelatedTotal}
          </Typography>
        </div>
        <div className={classes.spacer} />
        <div className={classes.item}>
          <Typography variant="subtitle2" color="secondary">
            Errechnet 7 Tage
          </Typography>
          <Typography variant="h3" color="secondary">
            {expectedSevenDays}
          </Typography>
        </div>
        <div className={classes.spacer} />
        <div className={classes.item}>
          <Typography variant="subtitle2" color="secondary">
            Errechnet 14 Tage
          </Typography>
          <Typography variant="h3" color="secondary">
            {expectedFourteenDays}
          </Typography>
        </div>
      </div>
    </Paper>
  </div>
);

ValueCard.propTypes = {
  id: PropTypes.string,
  classes: PropTypes.object,
  title: PropTypes.string,
  scmisTotal: PropTypes.number,
  nonProductionOrderRelatedTotal: PropTypes.number,
  expectedSevenDays: PropTypes.number,
  expectedFourteenDays: PropTypes.number,
  loading: PropTypes.bool,
};

const numberSize = '2rem';

const styles = () => ({
  container: {
    width: '100%',
  },
  title: {
    height: '48px',
  },
  spacer: {
    borderLeft: '1px solid rgba(0, 0, 0, 0.2)',
    flex: 0,
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    padding: '1em',
  },
  item: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flex: 1,
    '& h3': {
      fontSize: numberSize,
      fontWeight: 'bold',
    },
    '& h6': {
      opacity: 0.86,
      fontSize: '1rem',
      fontWeight: 'bold',
      maxWidth: '200px',
    },
  },
  loadingOverlay: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: 'rgba(255, 255, 255, 0.5)',
    zIndex: 100,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export default withStyles(styles)(ValueCard);
