import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

function TableCell(props) {
  const {
    classes,
    children,
    id,
  } = props;
  return (
    <div
      id={id}
      className={classes.container}
    >
      {children}
    </div>
  );
}

TableCell.propTypes = {
  id: PropTypes.string,
  classes: PropTypes.object,
  children: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
    PropTypes.array,
  ]),
};

const style = () => ({
  container: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    padding: '5px',
  },
  text: {
    fontSize: '16px',
    width: '40px',
    textAlign: 'left',
  },
});

export default withStyles(style)(TableCell);
