import React from 'react';
import PropTypes from 'prop-types';
import Autocomplete from '../Autocomplete';
import { trimLead0s } from '../../utils/displayUtils';

const fieldArray = [
  'materialNumber',
  'materialDescription',
];

const MaterialFilter = ({
  value,
  onChange,
  data,
}) => {
  const getAvailableChoices = (results) => {
    const choices = [];

    results.forEach((row) => {
      if (!choices.some((choice) => choice.materialNumber === row.materialNumber)) {
        const rowData = {};
        fieldArray.forEach((field) => {
          rowData[field] = row[field];
        });
        choices.push(rowData);
      }
    });
    choices.sort((a, b) => (a.materialNumber > b.materialNumber));
    return choices;
  };

  const fetchList = (searchTerm) => {
    if (data && data.length > 0) {
      const filteredData = data.filter((row) => (
        row.materialNumber.includes(searchTerm) || row.materialDescription.includes(searchTerm)
      ));
      return getAvailableChoices(filteredData).map((choice) => ({
        value: choice.materialNumber,
        label: `${trimLead0s(choice.materialNumber)} - ${choice.materialDescription}`,
      }));
    }
    return [];
  };

  return (
    <Autocomplete
      async
      isMulti
      value={value}
      loadOptions={fetchList}
      onChange={onChange}
      label="Material"
      name="material"
    />
  );
};

MaterialFilter.propTypes = {
  value: PropTypes.array,
  onChange: PropTypes.func,
  data: PropTypes.array,
};

export default MaterialFilter;
