import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject, observer, PropTypes as MobxPropTypes } from 'mobx-react';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { withStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import Popover from '@material-ui/core/Popover';
import ClearIcon from '@material-ui/icons/Clear';
import DeleteIcon from '@material-ui/icons/Delete';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Typography from '@material-ui/core/Typography';
import { trimLead0s } from '../../../utils/displayUtils';
import CopyableText from '../../CopyableText';
import StockHistoryChart from '../StockHistoryChart';
import SimpleTable from '../../SimpleTable';
import ConfirmDeleteModal from '../../UploadForm/ConfirmDeleteModal';
import { reformatIsoDate } from '../../../utils/dateTools';

export const editableSources = [
  'VF (manual)',
  'VF (sheet)',
];

@inject('store')
@observer
class MaterialActions extends Component {
  static propTypes = {
    classes: PropTypes.object,
    value: PropTypes.object.isRequired,
    onDeliverySelected: PropTypes.func,
    store: MobxPropTypes.objectOrObservableObject,
  }

  constructor(props) {
    super(props);

    this.state = {
      anchorEl: null,
      open: false,
      showConfirmDeleteModal: false,
      deliveryToBeDeleted: null,
      showDetails: false,
      showOverdue: false,
    };
  }

  componentDidMount() {
    this.props.store.ui.registerMaterialRendered();
  }

  handleMenuOpen = (event) => {
    event.stopPropagation();
    this.setState({ anchorEl: event.currentTarget, open: true });
  }

  handleMenuClose = (event) => {
    event.stopPropagation();
    this.setState({ open: false });
  }

  handleDetailsOpen = (event) => {
    event.stopPropagation();
    this.setState({ showDetails: true, open: false });
  }

  handleDetailsClose = (event) => {
    event.stopPropagation();
    this.setState({ showDetails: false });
  }

  handleOverdueDelOpen = (event) => {
    event.stopPropagation();
    this.setState({ showOverdue: true, open: false });
  }

  handleOverdueDelClose = (event) => {
    event.stopPropagation();
    this.setState({ showOverdue: false });
  }

  handleConfirmDeleteModalOpen = (e, pk) => {
    e.stopPropagation();
    this.setState({ showConfirmDeleteModal: true, deliveryToBeDeleted: pk });
  };

  handleConfirmDeleteModalClose = (e) => {
    e.stopPropagation();
    this.setState({ showConfirmDeleteModal: false });
  };

  handleOverdueDelRemoval = (event) => {
    event.stopPropagation();
    this.props.store.source.deleteOverdueDelivery(this.state.deliveryToBeDeleted);
  }

  handleSelectMaterial = (event) => {
    event.stopPropagation();
    this.props.store.ui.selectMaterial(this.props.value.materialNumber);
  }

  handleDeliverySelected = (event) => {
    const { onDeliverySelected, value } = this.props;
    event.stopPropagation();
    onDeliverySelected(value);
  }

  renderMenuItems() {
    const { value } = this.props;
    const menuItems = [
      {
        text: 'Details anzeigen',
        onClick: this.handleDetailsOpen,
        key: 'details',
      }, {
        text: 'Anlieferung hinzufügen',
        onClick: this.handleDeliverySelected,
        key: 'addNext',
      },
    ];

    if (value.deliveries && value.deliveries.length > 0
      && value.deliveries.some(delivery => delivery.overdue)
    ) {
      menuItems.push({
        text: 'Überfällige Anlieferungen',
        onClick: this.handleOverdueDelOpen,
        key: 'overdue',
      });
    }

    const selectItem = {
      text: 'Auswählen',
      onClick: this.handleSelectMaterial,
      key: 'select',
    };
    menuItems.push(selectItem);

    return menuItems.map(item => <MenuItem key={`${item.key}${value.materialNumber}`} onClick={item.onClick}>{item.text}</MenuItem>);
  }

  renderPopover() {
    const {
      value,
      classes,
    } = this.props;

    const {
      anchorEl,
      showDetails,
    } = this.state;

    const popoverFields = [
      {
        title: 'Bezeichnung',
        render: (<CopyableText bold value={value.description}/>),
      },
      {
        title: 'Materialnummer',
        render: (<CopyableText value={trimLead0s(value.materialNumber)}/>),
      },
      {
        title: 'Lieferantennummer',
        render: (<CopyableText value={value.supplierNumber} trim/>),
      },
      {
        title: 'Lieferant',
        render: (<CopyableText value={value.supplier}/>),
      },
      {
        title: 'Kategorie',
        render: (<CopyableText value={value.group}/>),
      },
      {
        title: 'Dispo Nr.',
        render: (<CopyableText value={value.disp}/>),
      },
      {
        title: 'ABC-Klassifikation',
        render: (<CopyableText value={value.abcClassification}/>),
      },
    ];

    return (
      <Popover
        classes={{
          paper: classes.paper,
        }}
        open={showDetails}
        onClose={this.handleDetailsClose}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <IconButton
          className={classes.closeButton}
          onClick={this.handleDetailsClose}
          data-testid="close"
          aria-label="Schließen"
        >
          <ClearIcon/>
        </IconButton>
        <List
          onClick={event => event.stopPropagation()}
        >
          {popoverFields.map(field => (
            <ListItem key={field.title} className={classes.listItem}>
              <Typography variant="body2">{field.title}</Typography>
              {field.render}
            </ListItem>
          ))}
          <StockHistoryChart material={this.props.value}/>
        </List>
      </Popover>
    );
  }

  renderOverduePopover() {
    const renderCell = (props) => {
      const { classes } = this.props;
      return (
        <div className={classes.tableCell}>
          {props.row}
        </div>
      );
    };

    const renderDeleteCell = (props) => {
      const { classes } = this.props;
      if (editableSources.includes(props.row.source)) {
        return (
          <div className={classes.tableCell}>
            <IconButton
              onClick={e => this.handleConfirmDeleteModalOpen(e, props.row.pk)}
              aria-label="Löschen"
            >
              <DeleteIcon/>
            </IconButton>
          </div>
        );
      }
      return (
        <></>
      );
    };

    const renderDateCell = (props) => renderCell({
      ...props,
      row: props.row !== '' ? reformatIsoDate(props.row) : null,
    });

    const {
      value, classes,
    } = this.props;
    const {
      anchorEl,
      showOverdue,
    } = this.state;

    const tableColumns = [
      {
        header: 'Lieferant',
        accessor: 'supplierName',
        Cell: renderCell,
      },
      {
        header: 'Menge',
        accessor: 'quantity',
        Cell: renderCell,
      },
      {
        header: 'PO Number',
        accessor: 'poNumber',
        Cell: renderCell,
      },
      {
        header: 'Lieferdatum',
        accessor: 'deliveryDate',
        Cell: renderDateCell,
      },
      {
        header: 'Quelle',
        accessor: 'source',
        Cell: renderCell,
      },
      {
        header: '',
        accessor: '',
        Cell: renderDeleteCell,
      },
    ];
    if (value.deliveries) {
      return (
        <Popover
          classes={{
            paper: classes.overdueDelPaper,
          }}
          open={showOverdue}
          onClose={this.handleOverdueDelClose}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          disableRestoreFocus
        >
          <IconButton
            className={classes.closeButton}
            onClick={this.handleOverdueDelClose}
            data-testid="close"
            aria-label="Schließen"
          >
            <ClearIcon/>
          </IconButton>
          <Typography variant="h6">Überfällige Lieferungen</Typography>
          <SimpleTable
            keyColumn="uniqueId"
            columns={tableColumns}
            data={value.deliveries.filter(d => d.overdue).map(d => ({
              ...d,
              uniqueId: `${d.pk}-${d.supplierName}-${d.deliveryDate}`,
            }))}
          />
        </Popover>
      );
    }
    return null;
  }

  renderDeliveryDeleteConfirmationModal() {
    return (
      <>
        <ConfirmDeleteModal
          open={this.state.showConfirmDeleteModal}
          onClose={this.handleConfirmDeleteModalClose}
          onCancel={this.handleConfirmDeleteModalClose}
          onConfirm={this.handleOverdueDelRemoval}
        />
      </>
    );
  }

  render() {
    const { classes } = this.props;
    const { anchorEl, open } = this.state;

    return (
      <>
        <IconButton
          aria-label="more"
          aria-controls="long-menu"
          aria-haspopup="true"
          className={classes.button}
          onClick={this.handleMenuOpen}
        >
          <MoreVertIcon/>
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          keepMounted
          open={open}
          onClose={this.handleMenuClose}
        >
          {this.renderMenuItems()}
        </Menu>
        {this.renderPopover()}
        {this.renderOverduePopover()}
        {this.renderDeliveryDeleteConfirmationModal()}
      </>
    );
  }
}

const style = theme => ({
  button: {
    color: 'rgba(191,191,191, 1)',
    padding: 0,
  },
  paper: {
    padding: theme.spacing(1),
    pointerEvents: 'auto',
    maxWidth: 520,
  },
  overdueDelPaper: {
    padding: theme.spacing(1),
    pointerEvents: 'auto',
    width: '30%',
  },
  listItem: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  text: {
    fontSize: '16px',
    textAlign: 'center',
  },
  closeButton: {
    position: 'absolute',
    top: 0,
    right: 0,
    padding: '6px',
    zIndex: 1,
  },
});

export default withStyles(style)(MaterialActions);
