import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import SvgIcon from '@material-ui/core/SvgIcon';

import { ReactComponent as MaximizeIcon } from '../../../assets/maximize.svg';
import { ReactComponent as MinimizeIcon } from '../../../assets/minimize.svg';

const ConfigCard = ({
  id,
  classes,
  title,
  onToggle,
  expanded,
  children,
  visible,
}) => {
  if (visible) {
    return (
      <div id={id} className={classes.container}>
        <span className={classes.titleContainer}>
          <Typography variant="h6" color="secondary">
            {title}
          </Typography>

          <IconButton
            onClick={onToggle}
            color="secondary"
            aria-label={expanded ? 'Minimieren' : 'Maximieren'}
          >
            <SvgIcon className={classes.icon}>
              {expanded ? <MinimizeIcon/> : <MaximizeIcon/>}
            </SvgIcon>
          </IconButton>
        </span>

        <Paper elevation={1} className={classes.card}>
          {expanded ? children : null}
        </Paper>
      </div>
    );
  }
  return null;
};

ConfigCard.propTypes = {
  id: PropTypes.string,
  classes: PropTypes.object,
  title: PropTypes.string,
  onToggle: PropTypes.func,
  expanded: PropTypes.bool,
  children: PropTypes.object,
  visible: PropTypes.bool,
};

const styles = () => ({
  container: {
    padding: '0 1em',
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  icon: {
    fontSize: '1.5rem',
  },
  card: {
    marginRight: '10px',
    marginBottom: '20px',
    width: '100%',
    padding: '10px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
  },
});

export default withStyles(styles)(ConfigCard);
