import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { DatePicker } from '@material-ui/pickers';
import { withApollo } from 'react-apollo';
import gql from 'graphql-tag';

import Autocomplete from '../Autocomplete';
import { trimLead0s } from '../../utils/displayUtils';

const fieldArray = [
  'materialNumber',
  'description',
];

export const GetMaterialListQuery = gql`
query GetMaterialList(
  $materialNumberOrDescription: String
){
  allMaterials(
    first: 5,
    materialNumberOrDescription: $materialNumberOrDescription,
    trafficLight_In: "red, yellow, green",
  ) {
    edges {
      node {
        ${fieldArray.join('\n')}
      }
    }
  }
}`;

class NewDeliveryForm extends Component {
  static propTypes = {
    classes: PropTypes.object,
    client: PropTypes.object,
    numberOfMaterialsInput: PropTypes.string,
    orderNumberInput: PropTypes.string,
    onOrderNumberChange: PropTypes.func,
    deliveryDate: PropTypes.object,
    onMaterialInputChange: PropTypes.func,
    onNumberOfMaterialsChange: PropTypes.func,
    onDateChange: PropTypes.func,
    selectedMaterial: PropTypes.string,
    disabled: PropTypes.bool,
  };

  getAvailableChoices = (results) => {
    const choices = [];

    results.forEach((row) => {
      if (!choices.some(choice => choice.materialNumber === row.materialNumber)) {
        const rowData = {};
        fieldArray.forEach((field) => {
          rowData[field] = row[field];
        });
        choices.push(rowData);
      }
    });
    choices.sort((a, b) => (a.materialNumber > b.materialNumber));
    return choices;
  }

  fetchList = async (value) => {
    const useValue = value || this.props.selectedMaterial;
    const data = await this.props.client.query({
      query: GetMaterialListQuery,
      variables: {
        materialNumberOrDescription: useValue,
      },
    });
    if (data) {
      const results = data.data.allMaterials.edges.map(edge => ({
        materialNumber: edge.node.materialNumber,
        description: edge.node.description,
      }));
      return this.getAvailableChoices(results).map(choice => ({
        value: choice.materialNumber,
        label: `${trimLead0s(choice.materialNumber)} - ${choice.description}`,
      }));
    }
    return [];
  }

  render() {
    const {
      classes,
      numberOfMaterialsInput,
      deliveryDate,
      orderNumberInput,
      onOrderNumberChange,
      onMaterialInputChange,
      onNumberOfMaterialsChange,
      onDateChange,
      selectedMaterial,
      disabled
    } = this.props;

    return (
      <form autoComplete="off" className={classes.form}>
        <Autocomplete
          async
          loadOptions={this.fetchList}
          defaultOptions
          onChange={onMaterialInputChange}
          label="Material"
          name="material"
          defaultInputValue={selectedMaterial && trimLead0s(selectedMaterial)}
          disabled={disabled}
        />
        <TextField
          className={classes.formControl}
          id="order-number"
          label="Ordernummer"
          value={orderNumberInput}
          type="number"
          onChange={onOrderNumberChange}
          disabled={disabled}
        />
        <TextField
          className={classes.formControl}
          id="number-of-materials"
          label="Menge"
          value={numberOfMaterialsInput}
          type="number"
          onChange={onNumberOfMaterialsChange}
          disabled={disabled}
        />
        <DatePicker
          autoOk
          className={classes.formControl}
          id="date"
          label="Lieferdatum"
          value={deliveryDate || null}
          onChange={onDateChange}
          disablePast
          disabled={disabled}
        />
      </form>
    );
  }
}

const style = theme => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
});

export default withStyles(style)(withApollo(NewDeliveryForm));
