import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';


const DiagramConfig = ({
  classes,
  axisScale,
  onAxisScaleChange,
  activeScalings,
}) => {
  const handleAxisScaleChange = event => {
    onAxisScaleChange(event.target.value);
  };

  const scalingLabels = {
    daily: 'Nach Tagen',
    weekly: 'Nach Wochen',
    monthly: 'Nach Monaten',
    quarterly: 'Nach Quartalen',
    yearly: 'Nach Jahren',
  };

  return (
    <div className={classes.container}>
      <FormControl>
        <RadioGroup className={classes.radioGroup} value={axisScale} onChange={handleAxisScaleChange}>
          {activeScalings.map(scaling => (
            <FormControlLabel
              key={scaling}
              value={scaling}
              control={<Radio/>}
              label={<Typography className={classes.labelTitle}>{scalingLabels[scaling]}</Typography>}
            />
          ))}
        </RadioGroup>
      </FormControl>
    </div>
  );
};

DiagramConfig.propTypes = {
  classes: PropTypes.object,
  axisScale: PropTypes.string,
  onAxisScaleChange: PropTypes.func,
  activeScalings: PropTypes.array,
};

const styles = (theme) => ({
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    padding: theme.spacing(1),
  },
  radioGroup: {
    display: 'flex',
    flexDirection: 'row',
  },
  labelTitle: {
    fontWeight: 'bold',
  },
});

export default withStyles(styles)(DiagramConfig);
