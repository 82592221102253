import gql from 'graphql-tag';

export const SET_PROJECT_PRIORITY = gql`
  mutation setProjectPriority(
    $priority: Int,
    $salesOrderNumber: String,
  ) {
    setProjectPriority(
      priority: $priority
      salesOrderNumber: $salesOrderNumber
    ) {
      project {
        priority
      }
    }
  }
`;

export const EXCLUDE_PROJECT = gql`
  mutation excludeProject(
    $salesOrderNumber: String
    $productionOrderNumber: String
    $note: String
  ) {
    excludeProject(
      salesOrderNumber: $salesOrderNumber
      productionOrderNumber: $productionOrderNumber
      note: $note
    ) {
      excludedProject {
        excluded
      }
    }
  }
`;
