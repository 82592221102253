import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject, observer, PropTypes as MobxPropTypes } from 'mobx-react';
import classNames from 'classnames';

import { withStyles } from '@material-ui/core/styles';
import {
  Checkbox,
  FormControlLabel,
  Typography,
  RadioGroup,
  Radio,
  FormControl,
  FormGroup,
  FormLabel,
} from '@material-ui/core';
import MatrixCheckbox from './MatrixCheckbox';


@inject('store')
@observer
class TypeConfig extends Component {
  handleSelect = (event, newValue) => {
    this.props.store.reporting.selectReport(newValue);
  }

  getTableReportables = (report, table) => (
    report.reportables
      .filter(reportable => reportable.matrixId[0] === table))

  getRowReportables = (report, table, row) => (
    this.getTableReportables(report, table).filter(reportable => reportable.matrixId[1] === row)
  )

  rowChecked = (report, table, row) => (
    this.getRowReportables(report, table, row).every(reportable => (
      this.props.store.reporting.selectedReportables.includes(reportable.id)
    ))
  )

  toggleRow = (report, table, row) => {
    if (this.rowChecked(report, table, row)) {
      this.getRowReportables(report, table, row).forEach(reportable => {
        this.props.store.reporting.forceSelectedReportable(reportable.id, false);
      });
    } else {
      this.getRowReportables(report, table, row).forEach(reportable => {
        this.props.store.reporting.forceSelectedReportable(reportable.id, true);
      });
    }
  }

  render() {
    const {
      classes,
      store,
    } = this.props;

    const {
      selectedReportableMatrix,
      selectedReport,
      getActiveReportCategory,
      selectedReportables,
      toggleSelectedReportable,
    } = store.reporting;

    const selectedCategory = getActiveReportCategory();

    if (selectedCategory) {
      const availableReports = selectedCategory.reports;
      const selectedReportId = selectedReport ? selectedReport.id : '';

      return (
        <div className={classes.container}>
          <RadioGroup
            aria-label="selected-report"
            name="selected-report"
            value={selectedReportId}
            onChange={this.handleSelect}
            className={classes.reportOptions}
          >
            {availableReports.map(report => (
              <FormControlLabel key={report.id} value={report.id} control={<Radio />} label={report.label} />
            ))}
          </RadioGroup>
          {availableReports.map(report => (
            <div
              key={`tab-panel-${report.id}`}
              value={report.id}
              className={classNames(classes.tabContainer, {
                [classes.hidden]: report.id !== selectedReportId,
                [classes.grid]: !report.matrixSelection,
              })}
            >
              {report.matrixSelection ? (
                <div className={classes.matrix}>
                  <FormControl component="fieldset">
                    <FormLabel component="legend" className={classes.legend}>Fehlteiltyp</FormLabel>
                    <FormGroup className={classes.matrixRow}>
                      <MatrixCheckbox
                        coords={[0, 0]}
                        disabled={
                          selectedReportableMatrix[1].includes(1)
                          || selectedReportableMatrix[1].includes(2)
                        }
                        label="SCMIS"
                      />
                      <MatrixCheckbox coords={[0, 1]} label="Errechnete"/>
                    </FormGroup>
                  </FormControl>
                  <FormControl component="fieldset">
                    <FormLabel component="legend" className={classes.legend}>Berechnungslogik</FormLabel>
                    <FormGroup className={classes.matrixRow}>
                      <MatrixCheckbox coords={[2, 0]} label="Anzahl der absoluten FT" />
                      <MatrixCheckbox coords={[2, 1]} label="Anzahl basiert auf Systeme" />
                      <MatrixCheckbox coords={[2, 2]} label="Anzahl Materialen mit FT"/>
                    </FormGroup>
                  </FormControl>
                  <FormControl component="fieldset">
                    <FormLabel component="legend" className={classes.legend}>Zeitraum für die Berechnung</FormLabel>
                    <FormGroup className={classes.matrixRow}>
                      <MatrixCheckbox coords={[1, 0]} label="Erste Woche"/>
                      <MatrixCheckbox
                        coords={[1, 1]}
                        disabled={
                          selectedReportableMatrix[0].includes(0)
                        }
                        label="Zweite Woche"
                      />
                      <MatrixCheckbox
                        coords={[1, 2]}
                        disabled={
                          selectedReportableMatrix[0].includes(0)
                        }
                        label="Beide Wochen"
                      />
                    </FormGroup>
                  </FormControl>
                </div>
              ) : (
                <>
                  {report.reportType !== 'table' && report.reportables.map(reportable => (
                    <FormControlLabel
                      key={reportable.id}
                      className={classes.formControl}
                      control={(
                        <Checkbox
                          checked={selectedReportables.includes(reportable.id)}
                          onChange={() => toggleSelectedReportable(reportable.id)}
                        />
                      )}
                      label={(
                        <>
                          <Typography
                            className={classes.labelTitle}
                            variant="subtitle2"
                            align="left"
                          >
                            {reportable.title}
                          </Typography>
                          {reportable.subtitle && (
                            <Typography className={classes.labelSubtitle} variant="body2" align="left">
                              {reportable.subtitle}
                            </Typography>
                          )}
                        </>
                      )}
                    />
                  ))}
                </>
              )}
            </div>
          ))}
        </div>
      );
    }
    return null;
  }
}


TypeConfig.propTypes = {
  classes: PropTypes.object,
  store: MobxPropTypes.objectOrObservableObject,
};

const styles = theme => ({
  container: {
    width: '100%',
  },
  reportOptions: {
    flexDirection: 'row',
  },
  matrix: {
    display: 'flex',
    flexDirection: 'column',
  },
  matrixRow: {
    display: 'flex',
    flexDirection: 'row',
  },
  legend: {
    marginTop: theme.spacing(1),
    color: 'rgba(0, 0, 0, 0.54) !important',
    textAlign: 'start',
  },
  grid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(220px, 1fr))',
    gridGap: theme.spacing(1),
  },
  hidden: {
    display: 'none',
  },
  labelTitle: {
    fontWeight: 'bold',
  },
  labelSubtitle: {
    fontWeight: 'light',
    color: 'rgba(0, 0, 0, 0.4)',
  },
  formControl: {
    minWidth: '270px',
    padding: '10px',
    alignItems: 'center',
  },
});

export default withStyles(styles)(TypeConfig);
