import React, { Component } from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { withSnackbar } from 'notistack';
import { withApollo } from 'react-apollo';
import Autocomplete from '../../Autocomplete';

export const AllMaterialGroupsQuery = gql`
query AllMaterialGroupsQuery (
  $nameContains: String
){
  allMaterialGroups(
    first: 5,
    name_Icontains: $nameContains,
  ) {
    edges {
      node {
        name
        pk
      }
    }
  }
}`;

class GroupFilter extends Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    client: PropTypes.object.isRequired,
    value: PropTypes.array,
    onChange: PropTypes.func,
    label: PropTypes.string,
    single: PropTypes.bool,
  };

  fetchList = async (value) => {
    const response = await this.props.client.query({
      query: AllMaterialGroupsQuery,
      variables: {
        nameContains: value,
      },
    });
    if (response) {
      return response.data.allMaterialGroups.edges.map((edge) => ({
        value: edge.node.pk,
        label: edge.node.name,
      }));
    }
    return [];
  }

  render() {
    const {
      name,
      onChange,
      value,
      label,
      single,
    } = this.props;

    return (
      <Autocomplete
        async
        isMulti={!single}
        value={value}
        loadOptions={this.fetchList}
        onChange={onChange}
        label={label}
        name={name}
      />
    );
  }
}

export default withSnackbar(withApollo(GroupFilter));
