import React from 'react';
import PropTypes from 'prop-types';
import Autocomplete from '../Autocomplete';
import { trimLead0s } from '../../utils/displayUtils';

const NumberRefFilter = ({
  value,
  onChange,
  data,
  label,
  field,
}) => {
  const getAvailableChoices = (results) => {
    const choices = [];

    results.forEach((row) => {
      if (!choices.some((choice) => choice[field] === row[field])) {
        choices.push({
          [field]: row[field],
        });
      }
    });
    choices.sort((a, b) => (a[field] > b[field]));
    return choices;
  };

  const fetchList = (searchTerm) => {
    if (data && data.length > 0) {
      const filteredData = data.filter((row) => (
        row[field].includes(searchTerm)
      ));
      return getAvailableChoices(filteredData).map((choice) => ({
        value: choice[field],
        label: trimLead0s(choice[field]),
      }));
    }
    return [];
  };

  return (
    <Autocomplete
      async
      isMulti
      value={value}
      loadOptions={fetchList}
      onChange={onChange}
      label={label}
      name={field}
    />
  );
};

NumberRefFilter.propTypes = {
  value: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  data: PropTypes.array.isRequired,
  label: PropTypes.string.isRequired,
  field: PropTypes.string.isRequired,
};

export default NumberRefFilter;
