import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject, observer, PropTypes as MobxPropTypes } from 'mobx-react';
import { withStyles } from '@material-ui/core/styles';
import { TextField } from '@material-ui/core';

@inject('store')
@observer
class FilterConfig extends Component {
  render() {
    const {
      store,
      classes,
    } = this.props;

    const {
      selectedMaterial,
      selectMaterial,
    } = store.reporting;

    const handleChange = (event) => {
      selectMaterial(event.target.value);
    };

    return (
      <div className={classes.container}>
        <TextField
          id="material-number"
          value={selectedMaterial || ''}
          onChange={handleChange}
          label="Material Nummer"
          name="material-number"
        />
      </div>
    );
  }
}

FilterConfig.propTypes = {
  store: MobxPropTypes.objectOrObservableObject,
  classes: PropTypes.object,
};

const styles = () => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    textAlign: 'start',
  },
});

export default withStyles(styles)(FilterConfig);
