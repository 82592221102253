import { create } from 'mobx-persist';
import { observable, action } from 'mobx';
import Raven from 'raven-js';
import { DateTime } from 'luxon';

import Ui from './ui';
import User from './user';
import Source from './source';
import Make from './make';
import Reporting from './reporting';
import Notifications from './notifications';

const hydrate = create({ jsonify: true });

if (process.env.REACT_APP_SENTRY_DSN) {
  Raven.config(process.env.REACT_APP_SENTRY_DSN).install();
}

export default class Store {
  constructor() {
    this.user = hydrate('userstore', new User(this));
    this.ui = hydrate('selectedPreset', new Ui(this));
    this.source = new Source(this);
    this.make = new Make(this);
    this.reporting = new Reporting(this);
    this.notifications = new Notifications(this);
  }

  @observable client = undefined;
  @observable messages = [];

  @action.bound
  enqueueSnackbar(message, options) {
    this.messages.push({
      key: DateTime.local().valueOf(),
      message,
      options,
    });
  }

  @action.bound
  removeSnackbar(note) {
    this.messages.remove(note);
  }

  @action.bound
  handleError(error, messageOverride) {
    console.error(error);
    const errorSpecifics = error.graphQLErrors && error.graphQLErrors[0] && error.graphQLErrors[0].extensions;
    const specialOverrides = errorSpecifics && {
      'duplicate_delivery': `Es existiert bereits eine Lieferung für ${errorSpecifics.material_number} am ${DateTime.fromISO(errorSpecifics.delivery_date).toLocaleString(DateTime.DATE_SHORT)}.`,
    };
    const specialMessage = specialOverrides && specialOverrides[errorSpecifics.error_id];
    this.enqueueSnackbar(messageOverride || specialMessage || error.message || 'Unknown Error', { variant: 'error' });
    Raven.captureException(error);
  }
}
