import React, { Component } from 'react';
import PropTypes from 'prop-types';
import copy from 'copy-to-clipboard';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CopyIcon from '@material-ui/icons/FileCopy';
import { withSnackbar } from 'notistack';
import classnames from 'classnames';
import { trimLead0s } from '../../utils/displayUtils';

class CopyableText extends Component {
  static propTypes = {
    value: PropTypes.oneOfType([
      PropTypes.element,
      PropTypes.string,
      PropTypes.number,
    ]),
    trim: PropTypes.bool,
    variant: PropTypes.string,
    classes: PropTypes.object,
    enqueueSnackbar: PropTypes.func.isRequired,
    className: PropTypes.string,
    style: PropTypes.object,
    copyValue: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    bold: PropTypes.bool,
  }

  constructor(props) {
    super(props);

    this.wrapperRef = null;
    this.state = {
      enabled: false,
    };
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  copyTextToClipboardHandler = () => {
    const { value, enqueueSnackbar } = this.props;

    const copyValue = this.props.copyValue || value;

    copy(copyValue);
    enqueueSnackbar('Kopiert');
  }

  setWrapperRef = (node) => {
    this.wrapperRef = node;
  }

  handleClick = (event) => {
    const { enabled } = this.state;
    event.stopPropagation();
    if (enabled) {
      this.copyTextToClipboardHandler();
      document.removeEventListener('mousedown', this.handleClickOutside);
      this.setState({ enabled: false });
    } else {
      document.addEventListener('mousedown', this.handleClickOutside);
      this.setState({ enabled: true });
    }
  }

  handleClickOutside = (event) => {
    if (this.state.enabled && this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.setState({ enabled: false });
    }
  }

  render() {
    const {
      classes,
      value,
      trim,
      variant = 'body2',
      className,
      style,
      bold,
    } = this.props;
    const { enabled } = this.state;

    const renderValue = trim ? trimLead0s(value) : value;
    const copyValue = this.props.copyValue || value;

    return (
      <div
        className={classnames(className || classes.defaultStyle, classes.wrapperContainer, { [classes.enabledContainer]: enabled })}
        ref={this.setWrapperRef}
        onClick={this.handleClick}
      >
        {
          this.state.enabled && (
            <CopyIcon className={classes.copyIcon}/>
          )
        }
        <Typography
          style={style}
          className={classnames(className, {
            [classes.enabledText]: enabled,
            [classes.bold]: bold,
          })}
          variant={variant}
        >
          {enabled ? copyValue : renderValue}
        </Typography>
      </div>
    );
  }
}

const style = () => ({
  wrapperContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  copyIcon: {
    height: '0.6em',
    width: '0.6em',
    padding: '0 0.5px',
    color: 'rgb(208, 208, 208)',
  },
  enabledContainer: {
    backgroundColor: '#fff',
    cursor: 'pointer',
  },
  enabledText: {
    backgroundColor: 'rgb(43, 36, 131)',
    borderRadius: '2px',
    padding: '0 1px',
    color: '#fff',
  },
  defaultStyle: {
    paddingLeft: '15px',
  },
  bold: {
    fontWeight: 'bold',
  },
});

export default withStyles(style)(withSnackbar(CopyableText));
