import { Component } from 'react';
import PropTypes from 'prop-types';
import { withSnackbar } from 'notistack';
import { observer, inject, PropTypes as MobxPropTypes } from 'mobx-react';
import { autorun } from 'mobx';

@inject('store')
@observer
class MobxNotifier extends Component {
  static propTypes = {
    store: MobxPropTypes.objectOrObservableObject,
    enqueueSnackbar: PropTypes.func,
  }

  displayed = [];

  componentDidMount() {
    autorun(() => {
      const { messages = [] } = this.props.store;

      messages.forEach((message) => {
        // Do nothing if snackbar is already displayed
        if (this.displayed.includes(message.key)) return;
        // Display snackbar using notistack
        this.props.enqueueSnackbar(message.message, message.options);
        // Keep track of snackbars that we've displayed
        this.storeDisplayed(message.key);
        // Dispatch action to remove snackbar from mobx store
        this.props.store.removeSnackbar(message.key);
      });
    });
  }

  storeDisplayed = (id) => {
    this.displayed = [...this.displayed, id];
  };

  render() {
    return null;
  }
}

export default withSnackbar(MobxNotifier);
