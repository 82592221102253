import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { PropTypes as MobxPropTypes, inject, observer } from 'mobx-react';
import { observe } from 'mobx';
import ReactRouterPropTypes from 'react-router-prop-types';
import { withRouter } from 'react-router';
import { withStyles, MuiThemeProvider } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { DateTime } from 'luxon';
import { Steps } from 'intro.js-react';
import { withApollo } from 'react-apollo';
import {
  IconButton, Tabs, Tab, Tooltip, Typography,
} from '@material-ui/core';
import { ErrorOutline } from '@material-ui/icons';

import { getMissingParts, getNonProductionOrderRelatedMissingParts } from './Api/Queries/GetMissingParts';
import theme from '../../../theme';
import ValueCard from '../../../Components/ValueCard';
import ListCard from '../../../Components/ListCard';

import 'intro.js/introjs.css';
import '../../../Styles/introjs-overrides.css';
import { styles } from './styles';
import { unwrapNodes } from '../../../utils/graphQlUtils';
import { defaultGroupProperty } from '../../../Components/SimpleTable/TableGroup';
import NewDeliveryModal from '../../../Components/DeliveryForm/NewDeliveryModal';
import ChartModal from '../../../Components/ChartCard/ChartModal';
import ErrorOverviewModal from '../../../Components/ErrorModal/ErrorOverviewModal';


const filterByDate = (targetDate) => (node) => {
  const startTime = DateTime.fromISO(node.omlStart);
  if (targetDate.hasSame(startTime, 'day')) {
    return true;
  }
  return false;
};

const getSumForXDays = (x, data) => {
  const today = DateTime.local().startOf('day');
  const endDate = today.plus({ days: x });
  const includedData = data.filter((node) => {
    const startTime = DateTime.fromISO(node.omlStart);
    return startTime < endDate;
  });
  return includedData.length;
};

const generateGraphFromData = (data) => {
  const today = DateTime.local().startOf('day');
  const graphPoints = [];
  if (data) {
    for (let i = 0; i < 14; i += 1) {
      const iDaysInFuture = today.plus({ days: i });
      graphPoints[i] = {
        x: iDaysInFuture.setLocale('en').toFormat('d-LLL-yy'),
        y: data.filter(filterByDate(iDaysInFuture)).length,
      };
    }
  }
  return graphPoints;
};

const parseMissingParts = (groupedMissingParts) => {
  const wrappedMissingParts = groupedMissingParts
    ? groupedMissingParts.reduce((all, group) => {
      // expected missing parts are named differently
      const groupMissingParts = group.missingParts || group.expectedMissingParts;
      return [
        ...all,
        ...groupMissingParts.edges,
      ];
    },
    [])
    : [];
  const missingParts = unwrapNodes(wrappedMissingParts);
  const annotatedMissingParts = missingParts.map(missingPart => ({
    ...missingPart,
    uniqueId: `${missingPart.materialNumber}-${missingPart.salesOrderNumber}`,
  }));
  return annotatedMissingParts;
};


@inject('store')
@observer
class Home extends Component {
  constructor(props) {
    super(props);
    props.store.source.activateDashboardFilter(props.store.user.dispoFilters);
    this.state = {
      showNewDeliveryModal: false,
      showErrorModal: false,
      selectedMaterial: null,
      showChartModal: false,
      selectedChart: null,
      loading: true,
      groupedScmisMissingParts: [],
      groupedExpectedMissingParts: [],
      wrappedProjects: [],
      expectedMissingPartErrors: {
        materialErrors: [],
        projectErrors: [],
      },
      currentTabIndex: 0,
    };
  }


  componentDidMount() {
    const {
      user,
    } = this.props.store;

    this.fetchMissingParts();
    this.locationDisposer = observe(user, 'selectedLocation', () => {
      this.setState({ loading: true });
      this.fetchMissingParts();
    });
  }
  componentWillUnmount() {
    this.locationDisposer();
  }

  hasExpectedMissingPartErrors = () => {
    const {
      expectedMissingPartErrors,
    } = this.state;
    return expectedMissingPartErrors.materialErrors.length > 0
      || expectedMissingPartErrors.projectErrors.length > 0;
  }

  getExpectedMissingPartErrorTitle = () => {
    const {
      expectedMissingPartErrors,
    } = this.state;
    if (expectedMissingPartErrors.materialErrors.length > 0 || expectedMissingPartErrors.projectErrors.length > 0) {
      return (
        <Tooltip title="Es gab Fehler beim Laden der errechneten Fehlteile.">
          <IconButton onClick={this.openErrorModal}>
            <ErrorOutline color="error" />
          </IconButton>
        </Tooltip >
      );
    }
    return <></>;
  }

  handleClick = () => {
    this.props.history.push('/all');
  }

  onIntroExit = () => {
    this.props.store.ui.dashboardIntroDone = true;
  }

  openNewDeliveryModal = (materialNumber) => {
    this.setState({
      showNewDeliveryModal: true,
      selectedMaterial: materialNumber,
    });
  }

  closeNewDeliveryModal = () => {
    this.setState({
      showNewDeliveryModal: false,
      selectedMaterial: null,
    });
  }

  openErrorModal = () => {
    this.setState({
      showErrorModal: true,
    });
  }

  closeErrorModal = () => {
    this.setState({
      showErrorModal: false,
    });
  }

  openChartModal = (chart) => {
    this.setState({
      showChartModal: true,
      selectedChart: chart,
    });
  }

  closeChartModal = () => {
    this.setState({
      showChartModal: false,
      selectedChart: null,
    });
  }

  handleTabChange = (event, newValue) => {
    this.setState({
      currentTabIndex: newValue,
    });
  }

  getMissingPartTabs = () => {
    const {
      currentTabIndex,
    } = this.state;
    const {
      classes,
    } = this.props;

    return (
      <Tabs value={currentTabIndex} onChange={this.handleTabChange} textColor="secondary">
        <Tab label={<Typography className={classes.tabName}>FAUF Teile</Typography>} />
        <Tab label={<Typography className={classes.tabName}>Keine FAUF Teile</Typography>} />
      </Tabs>
    );
  }

  fetchMissingParts = async () => {
    try {
      const response = await this.props.client.query({
        query: getMissingParts,
        variables: {
          location: this.props.store.user.selectedLocation.value,
        },
      });
      const nonProductionOrderRelatedMissingParts = await this.props.client.query({
        query: getNonProductionOrderRelatedMissingParts,
        variables: {
          location: this.props.store.user.selectedLocation.value,
        },
      });
      if (response) {
        this.setState({
          loading: false,
          groupedScmisMissingParts: response.data.groupedMissingParts,
          groupedExpectedMissingParts: response.data.groupedExpectedMissingParts,
          wrappedProjects: response.data.allProjects && response.data.allProjects.edges,
          expectedMissingPartErrors: {
            materialErrors: [],
            projectErrors: [],
          },
        });
        if (response.data.errorInfo && response.data.errorInfo.hasError) {
          console.error(response.data.errorInfo);
          const { expectedMissingPartErrors } = response.data.errorInfo;
          if (expectedMissingPartErrors.materialErrors.length > 0 || expectedMissingPartErrors.projectErrors.length > 0) {
            this.setState({ expectedMissingPartErrors });
            this.props.store.handleError(response.data.errorInfo,
              'Fehler beim Abruf der errechneten Fehlteile: Es ist möglich, dass nicht alle Daten korrekt angezeigt werden.');
          } else {
            this.props.store.handleError(response.data.errorInfo,
              'Allgemeiner Fehler beim Abrufen der Daten.');
          }
        }
      }
      if (nonProductionOrderRelatedMissingParts) {
        this.setState({
          groupedNonProductionOrderRelatedMissingParts: nonProductionOrderRelatedMissingParts.data.groupedMissingParts,
        });
      }
    } catch (error) {
      this.setState({
        loading: false,
      });
      this.props.store.handleError(error, 'Fehlteile konnten nicht geladen werden');
    }
  }

  render() {
    const {
      classes,
      // wrappedProjects,
      // groupedScmisMissingParts,
      // groupedExpectedMissingParts,
      store,
      // loading,
    } = this.props;

    const {
      user,
      dispoFilters,
    } = store.user;

    const getUserSupplierCodes = () => {
      if (dispoFilters[0] === 'all') {
        return [];
      }
      const activePresets = user.profile.dispo_presets.filter(preset => dispoFilters.includes(preset.pk));
      const disposFromPresets = activePresets.reduce((dispos, preset) => [...dispos, ...preset.dispos], []);
      if (dispoFilters.includes('own')) {
        return [
          ...user.profile.dispos,
          ...disposFromPresets,
        ];
      }
      return disposFromPresets;
    };


    const filteredSupplierCodes = getUserSupplierCodes();

    const hasOwnDispos = filteredSupplierCodes.length > 0;
    const useFilteredDispos = dispoFilters[0] !== 'all' && hasOwnDispos;

    const scmisMissingParts = parseMissingParts(this.state.groupedScmisMissingParts);
    const nonProductionOrderRelatedMissingParts = parseMissingParts(this.state.groupedNonProductionOrderRelatedMissingParts);
    const expectedMissingParts = parseMissingParts(this.state.groupedExpectedMissingParts);
    const allProjects = unwrapNodes(this.state.wrappedProjects);

    const userScmisMissingParts = useFilteredDispos && scmisMissingParts
      ? scmisMissingParts.filter(part => filteredSupplierCodes.some(dispo => dispo.code === part.supplierCode))
      : undefined;

    const userNonProductionOrderRelatedMissingParts = useFilteredDispos && nonProductionOrderRelatedMissingParts
      ? nonProductionOrderRelatedMissingParts.filter(
        part => filteredSupplierCodes.some(dispo => dispo.code === part.supplierCode),
      )
      : undefined;

    const userExpectedMissingParts = useFilteredDispos && expectedMissingParts
      ? expectedMissingParts.filter(part => filteredSupplierCodes.some(dispo => dispo.code === part.supplierCode))
      : undefined;

    const getMissingPartsForTab = (tabNumber) => {
      if (tabNumber === 1) {
        return useFilteredDispos ? userNonProductionOrderRelatedMissingParts : nonProductionOrderRelatedMissingParts;
      }
      return useFilteredDispos ? userScmisMissingParts : scmisMissingParts;
    };

    const startsData = generateGraphFromData(allProjects);
    const missingData = generateGraphFromData(expectedMissingParts);
    const userMissingData = generateGraphFromData(userExpectedMissingParts);

    const expectedSevenDays = useFilteredDispos
      ? getSumForXDays(7, userExpectedMissingParts)
      : getSumForXDays(7, expectedMissingParts);

    const expectedFourteenDays = useFilteredDispos
      ? getSumForXDays(14, userExpectedMissingParts)
      : getSumForXDays(14, expectedMissingParts);

    const missingPartsData = useFilteredDispos
      ? [missingData, userMissingData]
      : [missingData];
    const missingPartsLabels = useFilteredDispos
      ? ['Alle', 'Eigen']
      : ['Alle'];

    const scmisGroupProperty = {
      ...defaultGroupProperty,
      childSortBy: 'omlStart',
    };


    const chartData = [
      {
        id: 'project-graph',
        title: 'Artis Fertigungsstarts 14 Tage',
        data: [startsData],
        labels: ['Alle'],
        loading: this.state.loading,
      },
      {
        id: 'parts-graph',
        title: 'Errechnete Fehlteile 14 Tage',
        data: missingPartsData,
        labels: missingPartsLabels,
        loading: this.state.loading,
      },
    ];

    const showGraphButtons = store.user.selectedLocation.value === 'Forchheim';

    return (
      <MuiThemeProvider theme={theme}>
        <Steps
          enabled={!store.ui.dashboardIntroDone}
          steps={[
            {
              element: '#app-title',
              intro: 'Willkommen zur Virtuellen Fabrik. Hier bekommst du eine virtuelle Führung durch die einzelnen Funktionen.',
            },
            {
              element: '#project-graph',
              intro: 'Anzeige der Fertigungsstart in den kommenden 14 Tagen.',
            },
            // {
            //   element: '#expand-collapse-button',
            //   intro: 'Hier kann die Grafik bzw. die Tabelle vergrößert oder verkleinert werden.',
            // },
            {
              element: '#parts-graph',
              intro: 'Anzahl der zu erwartenden Fehlteile in den kommenden 14 Tagen.',
            },
            {
              element: '#scmis-parts',
              intro: 'Diese Liste zeigt aktuelle Fehlteile in SCMIS (mit und ohne FAUF Bezug). Alle Daten inkl. Bemerkung werden aus SCMIS übernommen. Die Spalten "Material", "Materialkurztext", "Dispo" und "Menge" können aufsteigend oder absteigend sortiert werden. Mittels Filtersymbol kann nach Material, KAUF, FAUF und OML-Start gefiltert werden.',
            },
            {
              element: '#expand-row-button',
              intro: 'Mit Klick auf den Pfeil können Details zu den einzelnen Materialien angezeigt oder wieder zusammengefasst werden.',
            },
            {
              element: '#download-button',
              intro: 'Die Listen können hier als Excel Tebelle runtergeladen werden.',
            },
            {
              element: '#expected-parts',
              intro: 'Diese Liste zeigt errechnete Fehlteile (nur mit FAUF Bezug), die entsprechend geplanter Fertigungsstarts, Bestand und voraussichtlicher Anlieferungen zu erwarten sind. Die Spalten "Material", "Materialkurztext", "Dispo" und "Menge" können aufsteigend oder absteigend sortiert werden. Mittels Filtersymbol kann nach Material, KAUF, FAUF und OML-Start gefiltert werden. Die Angabe neben der Menge zeigt die Veränderung in der "Errechnete Fehlteile 14 Tage" Liste. Mit den entsprechenden Berechtigungen kann die Information zu den einzelnen Materialien unter der Bemerkung hinzugefügt oder aber mittels "Plus"-Button eine manuelle Anlieferung erfasst werden.',
            },
            {
              element: '#details-button',
              intro: 'Hier ist der Einstieg zur Detailansicht der Virtuellen Fabrik',
            },
            {
              element: '#reporting-button',
              intro: 'Hier ist der Einstieg zum Reporting der Virtuellen Fabrik',
            },
            {
              element: '#dispo-dropdown',
              intro: 'Hier lässt sich die Ansicht bei Bedarf nach den eigenen Dispokürzeln, allen Dispos und gespeicherten Dispos filtern.',
            },
            {
              element: '#location-dropdown',
              intro: 'Hier kann zwischen den Standorten Forchheim und Kemnath gewechselt werden. Die Sichten zeigen dann entsprechend nur die Inhalte des jeweiligen Standortes.',
            },
            {
              element: '#header-notifications',
              intro: `Übersicht Benachrichtigungen: diese weisen auf Veränderungen in der Virtuellen Fabrik hin und geben Handlungsvorschläge (wo zutreffend).

Die Benachrichtigungen sind unterteilt in Warnungen (größere Auswirkung) und Hinweise (kleine Auswirkung bzw. rein informativ).
Benachrichtigungen können als gelesen bzw. ungelesen markiert sowie gelöscht werden.
Benachrichtigungen verschwinden nach 2 Wochen aus der Übersicht.

Abhängig von der Nutzerauswahl im Dashboard (All Dispo/Eigene Dispo) werden wo zutreffend nur die dispobasierten Nachrichten angezeigt.
`,
            },
            {
              element: '#header-profile',
              intro: 'Hier lassen sich die gespeicherten Dispos erstellen und verwalten.',
            },
            {
              element: '#missing-parts',
              intro: 'Anzeige der Anzahl von SCMIS-Fehlteilen (mit und ohne FAUF Bezug), errechneten Fehlteilen in 7 Tagen und in 14 Tagen.',
            },
            {
              element: '#deliveries-today',
              intro: `Hier werden Lieferungen mit heutigem Liefertermin angezeigt.
              Gibt es mehr als eine Anlieferung pro Materialnummer, so wird die Menge als Summe zusammengefasst.`,
            },
            {
              element: '#header-legend',
              intro: 'Legende der Abkürzungen und Symbole, sowie Info über aktuelle SW-Version. Hilfefunktion könnte neu gestarten werden.',
            },
          ]}
          initialStep={0}
          onExit={this.onIntroExit}
        />
        <div className={classes.dashboard}>
          <div className={classes.container}>
            <div className={classes.columnLeft}>
              <ValueCard
                id="missing-parts"
                title="Fehlteile"
                scmisTotal={useFilteredDispos
                  ? userScmisMissingParts.length
                  : scmisMissingParts.length}
                nonProductionOrderRelatedTotal={useFilteredDispos
                  ? userNonProductionOrderRelatedMissingParts.length
                  : nonProductionOrderRelatedMissingParts.length}
                expectedSevenDays={expectedSevenDays}
                expectedFourteenDays={expectedFourteenDays}
                loading={this.state.loading}
              />
              <div className={classes.deliverListContainer}>
                <ListCard
                  introId="deliveries-today"
                  title="Anlieferungen heute"
                  deliveries={store.source.deliveriesToday.peek()}
                  loading={this.props.store.source.deliveriesTodayLoading}
                  groupProperty={{ ...defaultGroupProperty }}
                  initialSort={{
                    field: 'disp',
                    ascending: true,
                  }}
                />
              </div>
              {
                showGraphButtons && (
                  <div
                    className={classes.graphButtonsContainer}
                  >
                    {chartData.map((data) => (
                      <Button
                        key={data.title}
                        onClick={() => this.openChartModal(data)}
                        id={data.id}
                        aria-label={data.title}
                      >
                        {data.title}
                      </Button>
                    ))}
                  </div>
                )
              }
            </div>
            <div className={classes.columnRight} style={{ height: '100%' }}>
              <div className={classes.halfHeightRow}>
                <div className={classes.tableFlex}>
                  <Typography variant="h6" color="secondary">
                    SCMIS-Fehlteile
                  </Typography>
                  <ListCard
                    introId="scmis-parts"
                    missingParts={getMissingPartsForTab(this.state.currentTabIndex)}
                    loading={this.state.loading}
                    groupProperty={scmisGroupProperty}
                    titleElement={this.getMissingPartTabs()}
                    canExport
                    downloadSuffix={useFilteredDispos
                      ? filteredSupplierCodes.map(dispo => dispo.code).join('-')
                      : 'alle'}
                    showConfirmedDate
                    showKoje
                    initialSort={{
                      field: 'supplierCode',
                      ascending: true,
                    }}
                  />
                </div>
              </div>
              <div className={classes.halfHeightRow} style={{ paddingTop: '1em' }}>
                <ListCard
                  introId="expected-parts"
                  title="Errechnete Fehlteile 14 Tage"
                  errorTitle={this.getExpectedMissingPartErrorTitle()}
                  missingParts={useFilteredDispos ? userExpectedMissingParts : expectedMissingParts}
                  loading={this.state.loading}
                  canExport
                  showCallDate
                  downloadSuffix={useFilteredDispos
                    ? filteredSupplierCodes.map(dispo => dispo.code).join('-')
                    : 'alle'}
                  groupProperty={defaultGroupProperty}
                  editableRemark={user.permissions.includes('delivery.change_expectedmissingpartdetails')}
                  showDeliveryButton={user.permissions.includes('delivery.add_deliveryline')}
                  openDeliveryModal={this.openNewDeliveryModal}
                  initialSort={{
                    field: 'supplierCode',
                    ascending: true,
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <NewDeliveryModal
          visible={this.state.showNewDeliveryModal}
          onCancel={this.closeNewDeliveryModal}
          onUpdate={async (values) => {
            try {
              await this.props.store.source.addDelivery(values);
              this.closeNewDeliveryModal();
            } catch (e) {
              console.error(e);
            }
          }}
          initialMaterial={this.state.selectedMaterial}
        />
        <ChartModal
          visible={this.state.showChartModal}
          onCancel={this.closeChartModal}
          selectedChart={this.state.selectedChart}
        />
        <ErrorOverviewModal
          visible={this.state.showErrorModal}
          onCancel={this.closeErrorModal}
          materialErrors={this.state.expectedMissingPartErrors.materialErrors}
          projectErrors={this.state.expectedMissingPartErrors.projectErrors}
        />
      </MuiThemeProvider>
    );
  }
}

Home.propTypes = {
  client: PropTypes.object.isRequired,
  history: ReactRouterPropTypes.history.isRequired,
  classes: PropTypes.object,
  store: MobxPropTypes.objectOrObservableObject,
};

export default withApollo(withStyles(styles)(withRouter(Home)));
