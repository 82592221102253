import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Select,
  MenuItem,
  ListSubheader,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { PropTypes as MobxPropTypes, inject, observer } from 'mobx-react';
import { difference } from 'ramda';
import DispoCard from './DispoCard';

@inject('store')
@observer
class DashboardFilter extends Component {
  handleDispoModeChange = (event) => {
    const { value } = event.target;
    const { user, source } = this.props.store;

    const newValues = difference(value, user.dispoFilters);
    if (newValues.includes('all') || value.length === 0) {
      user.dispoFilters = ['all'];
      source.deactivateDashboardFilter();
    } else {
      const newFilter = value.filter(val => val !== 'all');
      user.dispoFilters = newFilter;
      source.activateDashboardFilter(newFilter);
    }
  }

  handleLocationChange = (event) => {
    const { value } = event.target;
    const { user, source } = this.props.store;
    const { selectedLocation, setLocation } = this.props.store.user;
    if (selectedLocation.value !== value) {
      setLocation(value);
      user.dispoFilters = ['all'];
      source.deactivateDashboardFilter();
    }
  }

  render() {
    const {
      classes,
      store,
      showDispoFilter,
    } = this.props;

    const {
      user,
      dispoFilters,
      locationOptions,
      selectedLocation,
    } = store.user;

    const savedProfiles = user.profile.dispo_presets;

    const userSupplierCodes = (user.profile && user.profile.dispos) || [];
    const hasOwnDispos = userSupplierCodes.length > 0;

    return (
      <div className={classes.menu}>
        {showDispoFilter && (
          <span id="dispo-dropdown">
            <Select
              multiple
              className={classes.select}
              value={dispoFilters.peek()}
              onChange={this.handleDispoModeChange}
              disableUnderline
              classes={{
                icon: classes.selectIcon,
              }}
              renderValue={(selected) => {
                if (selected.includes('all')) {
                  return 'Alle Dispos';
                }

                const selectedPresets = savedProfiles
                  .filter(profile => selected.includes(profile.pk))
                  .map(profile => profile.title);

                if (selected.includes('own')) {
                  return [
                    'Eigene Dispos',
                    ...selectedPresets,
                  ].join(', ');
                }
                return selectedPresets.join(', ');
              }}
            >
              <MenuItem value="all">
                <DispoCard
                  hideCheckbox
                  label="Alle Dispos"
                  dispos={[]}
                />
              </MenuItem>
              <MenuItem value="own" disabled={!hasOwnDispos}>
                {hasOwnDispos
                  ? (
                    <DispoCard
                      label="Eigene Dispos"
                      dispos={userSupplierCodes.map(dispo => dispo.code)}
                      selected={dispoFilters.includes('own')}
                    />
                  ) : 'Keine eigene Dispos'}
              </MenuItem>
              {savedProfiles.length > 0 && (
              <ListSubheader>Gespeicherte Dispos</ListSubheader>
              )}
              {savedProfiles.map(profile => (
                <MenuItem key={profile.pk} value={profile.pk}>
                  <DispoCard
                    label={profile.title}
                    dispos={profile.dispos.map(dispo => dispo.code)}
                    selected={dispoFilters.includes(profile.pk)}
                  />
                </MenuItem>
              ))}
            </Select>
          </span>
        )}

        <span id="location-dropdown">
          <Select
            className={classes.select}
            value={selectedLocation.value}
            onChange={this.handleLocationChange}
            disableUnderline
            classes={{
              icon: classes.selectIcon,
            }}
          >
            {locationOptions.map(option => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </span>
      </div>
    );
  }
}

DashboardFilter.propTypes = {
  classes: PropTypes.object,
  store: MobxPropTypes.objectOrObservableObject,
  showDispoFilter: PropTypes.bool,
};

const styles = theme => ({
  menu: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    '&>:first-child': {
      paddingRight: '1em',
      borderRight: '1px solid #54b2b4',
    },
    '&>:last-child': {
      paddingLeft: '1em',
    },
  },
  select: {
    fontSize: '0.875rem',
    color: theme.palette.secondary.main,
  },
  selectIcon: {
    color: theme.palette.secondary.main,
  },
});

export default withStyles(styles)(DashboardFilter);
