import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { computed, observe } from 'mobx';
import {
  PropTypes as MobxPropTypes,
  observer,
  inject,
} from 'mobx-react';
import { DateTime } from 'luxon';
import classnames from 'classnames';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';
import { isWorkday } from '../../utils/dateTools';
import SimpleTable from '../SimpleTable';
import FilterForm from './FilterForm';

import { defaultGroupProperty } from '../SimpleTable/TableGroup';

import {
  getMakeColumns,
} from './columns';

@inject('store')
@observer
class Make extends Component {
  componentDidMount() {
    const {
      ui,
      make,
    } = this.props.store;

    if (ui.autoRefresh) {
      this.refreshInterval = setInterval(make.refreshData, ui.autoRefreshDuration);
    }

    this.refreshDisposer = observe(ui, 'autoRefresh', ({ newValue }) => {
      if (newValue === false) {
        clearInterval(this.refreshInterval);
      } else {
        make.refreshData();
        this.refreshInterval = setInterval(make.refreshData, ui.autoRefreshDuration);
      }
    });

    this.expandDisposer = observe(ui, 'makeExpanded', ({ newValue }) => {
      if (newValue === true) {
        make.getData();
      }
    });
    make.getData();
    this.selectedDisposer = observe(this.props.store.ui, 'selectedMaterial', make.getData);
  }

  componentWillUnmount() {
    clearInterval(this.refreshInterval);
    this.refreshDisposer();
    this.expandDisposer();
    this.selectedDisposer();
  }

  @computed get columns() {
    return getMakeColumns(
      isWorkday,
      this.props.store.make.startingDay,
      this.props.store.ui.makeExpanded,
    );
  }

  renderContents = () => {
    const {
      classes,
      store,
    } = this.props;

    const {
      loading,
      reloading,
      filter,
      getFilteredData,
      updateFilter,
      resetFilter,
      removeFilterLine,
    } = store.make;

    const filteredData = getFilteredData();
    const groupProperty = {
      ...defaultGroupProperty,
      parentGroupBy: 'type',
    };
    return (
      <>
        {(loading || reloading) && (
          <div className={classes.loadingOverlay} >
            <CircularProgress />
          </div>
        )}
        <SimpleTable
          filter
          filterObject={filter}
          filterReset={resetFilter}
          filterForm={<FilterForm value={filter} onOk={updateFilter} />}
          handleDeleteFilterLine={removeFilterLine}
          keyColumn="type"
          data={filteredData}
          groupProperty={groupProperty}
          columns={this.columns}
          grouped
          groupSort={(a, b) => {
            if (a === 'Artis One' || b === 'Artis') {
              return 1;
            }
            if (a === 'Artis' || b === 'Artis One') {
              return -1;
            }
            return 0;
          }}
        />
      </>
    );
  }

  render() {
    const {
      classes,
      introId,
      store,
    } = this.props;

    const {
      startingDay,
      goToLastWeek,
      goToNextWeek,
    } = store.make;

    const {
      ui,
    } = store;

    const fullMode = this.props.mode === 'full';
    return (
      <div
        id={introId}
        className={classnames(classes.container, {
          isOverdueExtended: fullMode || ui.isMakeOverdueExpanded,
        })}
      >
        <div className={classes.contentContainer}>
          <span className={classes.weekSelectorContainer}>
            <Typography variant="subtitle1" color="secondary">Fertigungsstarts</Typography>
            <span className={classes.weekSelector}>
              <IconButton
                onClick={goToLastWeek}
                color="secondary"
                disabled={startingDay.startOf('day') <= DateTime.local().startOf('day')}
                aria-label="Zurück"
              >
                <NavigateBeforeIcon />
              </IconButton>
              <Typography variant="subtitle1" color="secondary">
                {`${startingDay.toFormat('dd. LLL')} - ${startingDay.plus({ days: store.ui.makeExpanded ? 13 : 6 }).toFormat('dd. LLL')}`}
              </Typography>
              <IconButton
                onClick={goToNextWeek}
                color="secondary"
                data-testid="next-week"
                disabled={
                  store.ui.makeExpanded
                  || startingDay.startOf('day') >= DateTime.local().startOf('day').plus(2, 'weeks')
                }
                aria-label="Vorwärts"
              >
                <NavigateNextIcon />
              </IconButton>
            </span>
          </span>
          {this.renderContents()}
        </div>
      </div>
    );
  }
}

Make.propTypes = {
  mode: PropTypes.string,
  store: MobxPropTypes.objectOrObservableObject,
  classes: PropTypes.object,
  introId: PropTypes.string,
};

const style = () => ({
  container: {
    padding: '5px',
    height: '100%',
    position: 'relative',
  },
  contentContainer: {
    height: 'calc(100% - 48px)',
  },
  weekSelectorContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    overflowX: 'hidden',
  },
  weekSelector: {
    marginLeft: '10px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    whiteSpace: 'nowrap',
  },
  loadingOverlay: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(255, 255, 255, 0.5)',
    zIndex: '1',
  },
});

const ApolloMake = withStyles(style)(Make);
export { Make, ApolloMake };
