import { action, observable } from 'mobx';
import { persist } from 'mobx-persist';

export default class Ui {
  constructor(rootStore) {
    this.root = rootStore;
  }

  @observable isUploadModalVisible = false

  @observable selectedMaterial = undefined

  @persist @observable grouped = false
  @persist @observable sourceSwitch = false
  @persist @observable sourceVisible = true
  @persist @observable sourceExpanded = false
  @persist @observable sourceWeekly = false
  @persist @observable makeVisible = true
  @persist @observable makeExpanded = false
  @persist @observable autoRefresh = true
  @persist @observable showExpectedMissingParts = true
  @persist @observable showReliableSuppliers = true
  @persist @observable showDashboardGraphs = true
  @persist @observable showDashboardTables = true
  @observable autoRefreshDuration = 60000

  @persist @observable selectedPreset = 'source'

  @observable dashboardIntroDone = true
  @observable mainIntroDone = true
  @observable mainIntroReady = false
  @observable mainIntroDisablingAutoRefresh = false
  @observable reportingIntroDone = true
  materialsRendered = false
  projectsRendered = false

  @observable showRange = true
  @observable showType = true
  @observable showDiagram = true
  @observable showExtended = true
  @observable showFilter = true
  @observable showLogic = true
  @observable showMinMax = true

  @observable presetViews = [
    {
      id: 'source',
      label: 'Source',
      config: {
        grouped: false,
        sourceSwitch: false,
        sourceVisible: true,
        sourceExpanded: false,
        sourceWeekly: false,
        makeVisible: true,
        makeExpanded: false,
        autoRefresh: true,
        showExpectedMissingParts: true,
        showReliableSuppliers: true,
        showDashboardGraphs: true,
        showDashboardTables: true,
      },
    },
    {
      id: 'make',
      label: 'Make',
      config: {
        grouped: true,
        sourceSwitch: false,
        sourceVisible: false,
        sourceExpanded: false,
        sourceWeekly: false,
        makeVisible: true,
        makeExpanded: true,
        autoRefresh: true,
        showExpectedMissingParts: true,
        showReliableSuppliers: true,
        showDashboardGraphs: true,
        showDashboardTables: true,
      },
    },
  ]

  @action.bound
  selectPreset(presetName) {
    this.selectedPreset = presetName;
    const { config } = this.presetViews.find((preset) => preset.id === presetName);
    this.assignPreset(config);
  }

  @action.bound
  selectUserPreset(preset) {
    this.selectedPreset = preset.id;
    this.assignPreset(preset.config);
  }

  @action.bound
  assignPreset(config) {
    this.grouped = config.grouped;
    this.sourceSwitch = config.sourceSwitch;
    this.sourceVisible = config.sourceVisible;
    this.sourceExpanded = config.sourceExpanded;
    this.sourceWeekly = config.sourceWeekly;
    this.makeVisible = config.makeVisible;
    this.makeExpanded = config.makeExpanded;
    this.autoRefresh = config.autoRefresh;
    this.showExpectedMissingParts = config.showExpectedMissingParts;
    this.showReliableSuppliers = config.showReliableSuppliers;
    this.showDashboardGraphs = config.showDashboardGraphs;
    this.showDashboardTables = config.showDashboardTables;
  }

  @action.bound
  toggleUploadModal() {
    this.isUploadModalVisible = !this.isUploadModalVisible;
  }

  @action.bound
  selectMaterial(materialNumber) {
    if (this.selectedMaterial === materialNumber) {
      this.selectedMaterial = undefined;
    } else {
      this.selectedMaterial = materialNumber;
    }
  }

  @action.bound
  toggleSourceCompact() {
    this.sourceVisible = !this.sourceVisible;
    this.sourceExpanded = false;
    this.sourceWeekly = false;
    this.makeExpanded = false;
  }

  @action.bound
  toggleSourceWeekly() {
    this.sourceWeekly = !this.sourceWeekly;
    this.sourceExpanded = false;
    this.sourceVisible = true;
  }

  @action.bound
  toggleSourceExpanded() {
    this.sourceExpanded = !this.sourceExpanded;
    if (this.sourceExpanded === true) {
      this.sourceVisible = true;
      this.makeVisible = false;
      this.makeExpanded = false;
      this.sourceWeekly = true;
    }
  }

  @action.bound
  toggleMakeVisible() {
    this.makeVisible = !this.makeVisible;
    this.makeExpanded = false;
    this.sourceExpanded = false;
  }

  @action.bound
  toggleMakeExpanded() {
    this.makeExpanded = !this.makeExpanded;
    if (this.makeExpanded === true) {
      this.makeVisible = true;
      this.sourceVisible = false;
      this.sourceExpanded = false;
      this.sourceWeekly = false;
    }
  }

  @action.bound
  toggleGrouping() {
    this.grouped = !this.grouped;
  }

  @action.bound
  toggleSourceSwitch() {
    this.sourceSwitch = !this.sourceSwitch;
  }

  @action.bound
  toggleAutoRefresh() {
    this.autoRefresh = !this.autoRefresh;
  }

  @action.bound
  toggleShowExpectedMissingParts() {
    this.showExpectedMissingParts = !this.showExpectedMissingParts;
  }

  @action.bound
  toggleShowReliableSuppliers() {
    this.showReliableSuppliers = !this.showReliableSuppliers;
  }

  @action.bound
  toggleShowDashboardGraphs() {
    this.showDashboardGraphs = !this.showDashboardGraphs;
    if (!this.showDashboardGraphs) {
      this.showDashboardTables = true;
    }
  }

  @action.bound
  toggleShowDashboardTables() {
    this.showDashboardTables = !this.showDashboardTables;
    if (!this.showDashboardTables) {
      this.showDashboardGraphs = true;
    }
  }

  @action.bound
  registerMaterialRendered() {
    this.materialRendered = true;
    this.checkIntroReady();
  }

  @action.bound
  registerProjectRendered() {
    this.projectRendered = true;
    this.checkIntroReady();
  }

  @action.bound
  checkIntroReady() {
    if (this.materialRendered && this.projectRendered) {
      this.mainIntroReady = true;
    }
  }

  @action.bound
  startMainIntro() {
    this.mainIntroDone = false;

    if (this.autoRefresh) {
      // disable autorefresh during intro to avoid dom location issues
      this.mainIntroDisablingAutoRefresh = true;
      this.autoRefresh = false;
    }
  }

  @action.bound
  finishMainIntro() {
    this.mainIntroDone = true;

    if (this.mainIntroDisablingAutoRefresh) {
      this.autoRefresh = true;
      this.mainIntroDisablingAutoRefresh = false;
    }
  }

  @action.bound
  toggleShowRange() {
    this.showRange = !this.showRange;
  }

  @action.bound
  toggleShowType() {
    this.showType = !this.showType;
  }

  @action.bound
  toggleShowDiagram() {
    this.showDiagram = !this.showDiagram;
  }

  @action.bound
  toggleShowExtended() {
    this.showExtended = !this.showExtended;
  }

  @action.bound
  toggleShowFilter() {
    this.showFilter = !this.showFilter;
  }

  @action.bound
  toggleShowLogic() {
    this.showLogic = !this.showLogic;
  }

  @action.bound
  toggleShowMinMax() {
    this.showMinMax = !this.showMinMax;
  }

  @action.bound
  hideReportConfig() {
    this.showRange = false;
    this.showType = false;
    this.showDiagram = false;
    this.showExtended = false;
    this.showFilter = false;
    this.showLogic = false;
  }

  @action.bound
  showReportConfig() {
    this.showRange = true;
    this.showType = true;
    this.showDiagram = true;
    this.showExtended = true;
    this.showFilter = true;
    this.showLogic = true;
  }
}
