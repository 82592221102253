import axios from 'axios';
import { reportingUrl, htmlReportGeneratorUrl } from '../index';

const reportingAPI = axios.create({
  baseURL: `${reportingUrl}/api`,
});

const htmlReportGenerator = axios.create({
  baseURL: htmlReportGeneratorUrl,
});


export async function getReportFromUrl(url, configParams, token) {
  try {
    const res = await reportingAPI.get(
      `reports/${url}`,
      {
        params: configParams,
        headers: {
          Authorization: `JWT ${token}`,
        },
      },
    );

    return {
      value: res.data,
    };
  } catch (e) {
    return {
      error: e.response,
    };
  }
}

export async function getInfo(token) {
  try {
    const res = await reportingAPI.get(
      'reports/systems-with-expected-missing-parts/info',
      {
        headers: {
          Authorization: `JWT ${token}`,
        },
      },
    );
    return {
      value: res.data,
    };
  } catch (e) {
    return {
      error: e.response,
    };
  }
}

export async function getCategories() {
  try {
    const res = await htmlReportGenerator.get('/api/reportCategories');
    return {
      value: res.data,
    };
  } catch (e) {
    return {
      error: e.response,
    };
  }
}
