import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject, observer, PropTypes as MobxPropTypes } from 'mobx-react';
import { MuiThemeProvider, withStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import IconButton from '@material-ui/core/IconButton';
import Toolbar from '@material-ui/core/Toolbar';
import AppBar from '@material-ui/core/AppBar';
import Typography from '@material-ui/core/Typography';
import PersonIcon from '@material-ui/icons/Person';
import VisibilityIcon from '@material-ui/icons/Visibility';
import DashboardIcon from '@material-ui/icons/Dashboard';
import DetailsIcon from '@material-ui/icons/Details';
import AddIcon from '@material-ui/icons/Add';
import ClearIcon from '@material-ui/icons/Clear';
import HelpIcon from '@material-ui/icons/Help';
import BarChartIcon from '@material-ui/icons/BarChart';
import classNames from 'classnames';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { withApollo } from 'react-apollo';

import UploadForm from '../UploadForm';
import ViewHeader from './view';
import Legend from './legend';
import DashboardFilter from './DashboardFilter';
import Account from './Account';

import { ReactComponent as Logo } from '../../assets/VF_Logo.svg';
import { ReactComponent as TestLogo } from '../../assets/VF_Test_Logo.svg';
import lightTheme from '../../theme';
import darkTheme from '../../alternateTheme';
import LinkLabel from './LinkLabel';
import Notifications from './Notifications';

const IS_TEST = process.env.REACT_APP_IS_TEST === 'true';

@inject('store')
@observer
class Header extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isExpanded: false,
      mode: '',
    };
  }

  handleAccountOpen = () => {
    this.setState({
      isExpanded: true,
      mode: 'account',
    });
  }

  handleViewOpen = () => {
    this.setState({
      isExpanded: true,
      mode: 'view',
    });
  }

  handleDeliveryOpen = () => {
    this.setState({
      isExpanded: true,
      mode: 'newDelivery',
    });
  }

  handleLegendOpen = () => {
    this.setState({
      isExpanded: true,
      mode: 'legend',
    });
  }

  reset = () => {
    this.setState({
      isExpanded: false,
      mode: '',
    });
  }

  playIntro = () => {
    const {
      store,
      location,
    } = this.props;
    this.reset();
    if (location.pathname === '/all') {
      store.ui.startMainIntro();
    } else if (location.pathname === '/reports') {
      store.ui.reportingIntroDone = false;
    } else {
      store.ui.dashboardIntroDone = false;
    }
  }

  renderContents = () => {
    const {
      classes,
      location,
      store,
    } = this.props;

    const {
      isExpanded,
      mode,
    } = this.state;

    const {
      permissions,
    } = store.user.user;

    let tabValue = 0;
    if (location.pathname === '/all') {
      tabValue = 1;
    } else if (location.pathname === '/reports') {
      tabValue = 2;
    }

    if (!isExpanded) {
      return (
        <MuiThemeProvider theme={lightTheme}>
          <div className={classes.topLeftMenu}>
            <Link to="/" aria-label="Zurück zur Startseite">
              {IS_TEST
                ? <TestLogo className={classes.logo}/>
                : <Logo className={classes.logo}/>}
            </Link>
            <Tabs textColor="secondary" value={tabValue} >
              <Tab
                component={Link}
                to="/"
                label={(
                  <LinkLabel
                    id="dashboard-tab"
                    text="Dashboard"
                    icon={<DashboardIcon />}
                  />
                )}
              />
              <Tab
                id="details-button"
                component={Link}
                to="/all"
                label={(
                  <LinkLabel
                    text="Details"
                    icon={<DetailsIcon />}
                  />
                )}
              />
              {permissions.includes('user.access_reporting') && (
                <Tab
                  id="reporting-button"
                  component={Link}
                  to="/reports"
                  label={(
                    <LinkLabel
                      text="Reporting"
                      icon={<BarChartIcon />}
                    />
                  )}
                />
              )}
            </Tabs>
          </div>
          <span className={classes.topRightMenu}>
            {location.pathname === '/all' && (
              <>
                <DashboardFilter />
                <IconButton
                  id="header-add"
                  color="secondary"
                  onClick={this.handleDeliveryOpen}
                  data-testid="delivery"
                  aria-label="Anlieferung hinzufügen"
                >
                  <AddIcon/>
                </IconButton>
                <IconButton
                  id="header-view"
                  color="secondary"
                  onClick={this.handleViewOpen}
                  data-testid="view"
                  aria-label="Ansicht Einstellungen"
                >
                  <VisibilityIcon/>
                </IconButton>
              </>
            )}
            {location.pathname === '/' && (<DashboardFilter showDispoFilter/>)}
            <Notifications />
            <IconButton
              id="header-legend"
              color="secondary"
              onClick={this.handleLegendOpen}
              data-testid="legend"
              aria-label="Legende anzeigen"
            >
              <HelpIcon/>
            </IconButton>
            <IconButton
              id="header-profile"
              color="secondary"
              onClick={this.handleAccountOpen}
              data-testid="account"
              aria-label="Profil anzeigen"
            >
              <PersonIcon/>
            </IconButton>
          </span>
        </MuiThemeProvider>
      );
    }

    const contents = this.renderExpandedContent();
    const headingMap = {
      view: 'Ansicht',
      account: 'Account',
      newDelivery: 'Neue Lieferung',
      legend: 'Information',
    };
    const appVersion = () => {
      if (process.env.REACT_APP_VERSION && process.env.REACT_APP_VERSION !== 'develop') {
        const trimmedVersion = process.env.REACT_APP_VERSION.slice(0, -2);
        return trimmedVersion;
      }
      return process.env.REACT_APP_VERSION || 'no version';
    };

    return (
      <MuiThemeProvider theme={darkTheme}>
        <div className={classes.expandedContents}>
          <div className={classes.expandedHeader}>
            <Typography variant="h6">{headingMap[mode]}</Typography>
            <span className={classes.headerVersion}>
              Version: {appVersion()}
            </span>
          </div>
          {contents}
          <IconButton
            className={classNames(classes.expandedContent, classes.closeButton)}
            onClick={this.reset}
            data-testid="close"
            aria-label="Schließen"
          >
            <ClearIcon/>
          </IconButton>
        </div>
      </MuiThemeProvider>
    );
  }

  renderExpandedContent = () => {
    const {
      mode,
    } = this.state;

    const {
      store,
    } = this.props;

    const {
      token,
    } = store.user;

    switch (mode) {
      case 'account':
        return (
          <Account />
        );
      case 'view':
        return (
          <ViewHeader/>
        );
      case 'newDelivery':
        return (
          <UploadForm/>
        );
      case 'legend':
        return <Legend playIntro={this.playIntro} token={token}/>;
      default:
        return null;
    }
  }

  handleFilter = (value) => {
    this.props.store.source.changeFilter(value);
  }

  render() {
    const {
      classes,
    } = this.props;

    const {
      isExpanded,
    } = this.state;

    return (
      <AppBar
        position="static"
        elevation={0}
        className={classNames(classes.header, {
          [classes.expanded]: isExpanded,
        })}
      >
        <Toolbar className={classes.toolbar}>
          {this.renderContents()}
        </Toolbar>
      </AppBar>
    );
  }
}

Header.propTypes = {
  store: MobxPropTypes.objectOrObservableObject,
  classes: PropTypes.object,
  location: PropTypes.object,
};

const style = (theme) => {
  const duration = `${theme.transitions.duration.standard}ms`;
  const easing = theme.transitions.easing.easeInOut;
  const transitionString = `height ${duration} ${easing}, background-color ${duration} ${easing}`;
  return ({
    header: {
      backgroundColor: 'transparent',
      transition: transitionString,
      '-moz-transition': transitionString,
      '-webkit-transition': transitionString,
      marginBottom: '1em',
      flex: '0 0 auto',
    },
    expanded: {
      backgroundColor: theme.palette.secondary.main,
    },
    expandedContents: {
      width: '100%',
      padding: '12px',
    },
    expandedHeader: {
      display: 'flex',
      alignItems: 'center',
    },
    headerVersion: {
      marginLeft: '12px',
    },
    closeButton: {
      position: 'absolute',
      right: '0',
      top: '0',
    },
    toolbar: {
      justifyContent: 'space-between',
    },
    topLeftMenu: {
      display: 'flex',
      alignItems: 'center',
    },
    heading: {
      float: 'left',
      color: theme.palette.secondary.main,
      fontWeight: 'bold',
      lineHeight: '62px',
    },
    logo: {
      height: '36px',
      width: '64px',
      margin: '10px 30px 10px 0',
      float: 'left',
      fill: theme.palette.secondary.main,
    },
    iconMargin: {
      marginRight: theme.spacing(),
    },
    topRightMenu: {
      display: 'flex',
      justifyContents: 'flex-end',
    },
  });
};

export default withStyles(style)(withRouter(withApollo(Header)));
