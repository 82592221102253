import React, { Component } from 'react';
import { inject, observer, PropTypes as MobxPropTypes } from 'mobx-react';
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@material-ui/core';
import XLSX from 'xlsx';
import { DateTime } from 'luxon';
import { trimLead0s } from '../../../utils/displayUtils';

@inject('store')
@observer
class MaterialListReport extends Component {
  render() {
    const {
      report,
      loading,
      selectedReport,
    } = this.props.store.reporting;

    if (loading) {
      return null;
    }

    const requestedReportables = Object.keys(report.params.evaluations);

    return (
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Material Nummer</TableCell>
            <TableCell>Bezeichnung</TableCell>
            <TableCell>Lieferant</TableCell>
            <TableCell>Dispo</TableCell>
            <TableCell>ABC Klassifikation</TableCell>
            {requestedReportables.map(reportableId => {
              const reportableLabel = selectedReport.reportables.find(fullReportable => (
                fullReportable.id === reportableId
              )).label;
              return <TableCell key={reportableId}>{reportableLabel}</TableCell>;
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {report.result.map(row => (
            <TableRow key={row.material_number}>
              <TableCell>{trimLead0s(row.material_number)}</TableCell>
              <TableCell>{row.description}</TableCell>
              <TableCell>{row.supplier_name}</TableCell>
              <TableCell>{row.supplier_code}</TableCell>
              <TableCell>{row.abc_classification}</TableCell>
              {requestedReportables.map(reportableId => (
                <TableCell key={reportableId}>{row.evaluations[reportableId]}</TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    );
  }
}

MaterialListReport.propTypes = {
  store: MobxPropTypes.objectOrObservableObject,
};

export const exportTable = (
  report,
  selectedReport,
  filters,
  startDate,
  endDate,
  tableReportables,
  availableReportables,
) => {
  const requestedReportables = Object.keys(report.params.evaluations);
  const headers = [
    'Material Nummer',
    'Bezeichnung',
    'Lieferant',
    'Dispo',
    'ABC Klassifikation',
    ...requestedReportables.map(reportableId => {
      const reportableLabel = selectedReport.reportables.find(fullReportable => (
        fullReportable.id === reportableId
      )).label;
      return reportableLabel;
    }),
    '',
    'Filter',
    '',
  ];

  const getFilterLabel = (index) => {
    switch (index) {
      case 0:
        return 'Lieferant';
      case 1:
        return 'ABC-Klassifizierung';
      case 2:
        return 'Dispo';
      case 3:
        return 'Material Kategorie';
      case 4:
        return '';
      case 5:
        return 'Zeitraum';
      case 6:
        return '';
      case 7:
        return 'Logik Kriterien';
      default:
        return '';
    }
  };

  const getFilterString = (filter) => {
    if (!filter || filter.length === 0) return '';
    return filter.map(f => f.label).join(', ');
  };

  const getFilterValue = (index) => {
    switch (index) {
      case 0:
        return getFilterString(filters.supplier);
      case 1:
        return getFilterString(filters.abcClassification);
      case 2:
        return getFilterString(filters.dispo);
      case 3:
        return getFilterString(filters.group);
      case 4:
        return '';
      case 5:
        return `${DateTime.fromISO(startDate).toISODate()} - ${DateTime.fromISO(endDate).toISODate()}`;
      case 6:
        return '';
      case 7: {
        const reportableStrings = tableReportables
          .filter((tr) => tr.category)
          .map((tr) => {
            const matchingReportable = availableReportables.find(
              (ar) => ar.category === `${tr.category}.${tr.subcategory}`,
            );
            return `${matchingReportable.label} ${
              tr.operator === 'lt' ? 'kleiner als' : 'größer gleich'
            } ${tr.operand}`;
          });
        return reportableStrings.join(', ');
      }
      default:
        return '';
    }
  };

  // Need to have at least 4 rows to let the filters get properly printed
  const fakeRow = {
    // undefined at lowest level is fine, but not at midpoints
    evaluations: {},
  };

  const dataRowArray = report.result.length > 4
    ? report.result
    : [
      report.result[0] || fakeRow,
      report.result[1] || fakeRow,
      report.result[2] || fakeRow,
      report.result[3] || fakeRow,
      report.result[4] || fakeRow,
      report.result[5] || fakeRow,
      report.result[6] || fakeRow,
      report.result[7] || fakeRow,
    ];

  const body = dataRowArray.map((row, index) => [
    trimLead0s(row.material_number),
    row.description,
    row.supplier_name,
    row.supplier_code,
    row.abc_classification,
    ...requestedReportables.map(reportableId => row.evaluations[reportableId]),
    '',
    getFilterLabel(index),
    getFilterValue(index),
  ]);

  const xlsxFormat = [
    headers,
    ...body,
  ];

  const worksheet = XLSX.utils.aoa_to_sheet(xlsxFormat);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, 'SheetJS');
  XLSX.writeFile(workbook, `${DateTime.local().toFormat('yyyyMMddHHmm')}-Materialliste-Report.xlsx`);
};

export default MaterialListReport;
