export const getMaxYFromData = (data) => {
  const ys = data.reduce((allYs, dataSet) => [
    ...allYs,
    ...dataSet.map((dataPoint) => dataPoint.y),
  ], []);

  return Math.max(...ys);
};

export const getMinYFromData = (data) => {
  const ys = data.reduce((allYs, dataSet) => [
    ...allYs,
    ...dataSet.map((dataPoint) => dataPoint.y),
  ], []);

  return Math.min(...ys);
};

export const colors = [
  // d3 category20, rearranged
  '#1f77b4',
  '#ff7f0e',
  '#2ca02c',
  '#d62728',
  '#9467bd',
  '#8c564b',
  '#e377c2',
  '#7f7f7f',
  '#bcbd22',
  '#17becf',
  '#aec7e8',
  '#ffbb78',
  '#98df8a',
  '#ff9896',
  '#c5b0d5',
  '#c49c94',
  '#f7b6d2',
  '#c7c7c7',
  '#dbdb8d',
  '#9edae5',
];
