import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Modal from '@material-ui/core/Modal';
import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';
import { withStyles } from '@material-ui/core/styles';
import ChartCard from './index';

class ChartModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
    };
  }

  // componentDidUpdate(prevProps) {
  //   if (this.props.visible && !prevProps.visible) {
  //     this.setupDefaultValues();
  //   }
  // }

  render() {
    const {
      visible,
      onCancel,
      classes,
      selectedChart,
    } = this.props;

    return (
      <Modal
        open={!!visible}
        onClose={onCancel}
        disableBackdropClick
      >
        <div className={classes.paper}>
          <IconButton
            className={classes.closeButton}
            onClick={onCancel}
            aria-label="Schließen"
          >
            <ClearIcon/>
          </IconButton>
          {selectedChart && (
          <ChartCard
            introId={selectedChart.id}
            title={selectedChart.title}
            data={selectedChart.data}
            labels={selectedChart.labels}
            loading={selectedChart.loading}
          />
          )}
        </div>
      </Modal>
    );
  }
}

ChartModal.propTypes = {
  visible: PropTypes.bool,
  onCancel: PropTypes.func.isRequired,
  // onUpdate: PropTypes.func.isRequired,
  selectedChart: PropTypes.object,
  classes: PropTypes.object,
};

const styles = (theme) => ({
  paper: {
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    position: 'absolute',
    width: '50%',
    backgroundColor: theme.palette.background.paper,
    boxShoadow: theme.shadows[5],
    padding: theme.spacing(4),
    outline: 'none',
  },
  closeButton: {
    position: 'absolute',
    top: 0,
    right: 0,
    padding: '6px',
    zIndex: 1,
  },
});

export default withStyles(styles)(ChartModal);
