import React from 'react';
import PropTypes from 'prop-types';
import { LineChart } from 'react-easy-chart';
import { DateTime } from 'luxon';
import { withStyles } from '@material-ui/core/styles';
import { getMinYFromData, getMaxYFromData, colors } from '../../../utils/graphTools';

const dayDataToPoints = dayData => ({
  x: DateTime.fromISO(dayData.date).toFormat('d-LLL-yy'),
  y: dayData.quantity,
});

const StockHistoryChart = ({ material, classes }) => {
  const demandPoints = material.demandForecast.map(dayDataToPoints);
  const stockPoints = material.stockForecast.map(dayDataToPoints);

  const dates = material.demandForecast.map(dayData => DateTime.fromISO(dayData.date));

  const earliestDay = dates.reduce((earliestDayFound, day) => (
    day < earliestDayFound
      ? day
      : earliestDayFound
  ));

  const latestDay = dates.reduce((latestDayFound, day) => (
    day > latestDayFound
      ? day
      : latestDayFound
  ));

  const index211 = material.storageTypes && material.storageTypes.split(';').indexOf('211');
  const index214 = material.storageTypes && material.storageTypes.split(';').indexOf('214');

  const generateLine = (index, source) => {
    if (index >= 0 && index != null) {
      const values = source.split(';');
      return [
        {
          x: earliestDay.toFormat('d-LLL-yy'),
          y: parseInt(values[index], 10),
        },
        {
          x: latestDay.toFormat('d-LLL-yy'),
          y: parseInt(values[index], 10),
        },
      ];
    }
    return [];
  };

  const minLine211 = generateLine(index211, material.minStorageQuantities);
  const minLine214 = generateLine(index214, material.minStorageQuantities);
  const maxLine211 = generateLine(index211, material.maxStorageQuantities);
  const maxLine214 = generateLine(index214, material.maxStorageQuantities);

  const generateLineData = () => {
    const data = [demandPoints, stockPoints];
    const usedColors = [colors[0], colors[1]];
    const legendData = [
      {
        label: 'Erwarteter Bedarf über 5 Tage',
        color: usedColors[0],
      },
      {
        label: 'Erwarteter Bestand am Stichtag',
        color: usedColors[1],
      },
    ];

    if (index211 >= 0 && index211 != null) {
      data.push(minLine211, maxLine211);
      usedColors.push(colors[2], colors[3]);
      legendData.push({
        label: 'AXHE Min',
        color: colors[2],
      }, {
        label: 'AXHE Max',
        color: colors[3],
      });
    }
    if (index214 >= 0 && index214 !== null) {
      data.push(minLine214, maxLine214);
      usedColors.push(colors[4], colors[5]);
      legendData.push({
        label: 'HED7 Min',
        color: colors[4],
      }, {
        label: 'HED7 Max',
        color: colors[5],
      });
    }
    return [data, usedColors, legendData];
  };

  const [data, usedColors, legendData] = generateLineData();

  const maxY = getMaxYFromData(data);
  const minY = getMinYFromData(data);

  return (
    <>
      <LineChart
        axes
        xType="time"
        tickTimeDiesplayFormat="%d-%m"
        xTicks={5}
        data={data}
        width={500}
        height={300}
        lineColors={usedColors}
        yDomainRange={[Math.min(minY, 0), maxY]}
      />
      {legendData.map(legend => (
        <span key={legend.label} className={classes.legendEntry}>
          <span
            className={classes.legendBox}
            style={{
              backgroundColor: legend.color,
            }}
          />
          <span>
            {legend.label}
          </span>
        </span>
      ))}
    </>
  );
};

StockHistoryChart.propTypes = {
  material: PropTypes.object.isRequired,
  classes: PropTypes.object,
};

const styles = () => ({
  legendEntry: {
    marginRight: 10,
  },
  legendBox: {
    display: 'inline-block',
    width: '1rem',
    height: '1rem',
    marginRight: 3,
    borderRadius: '0.5rem',
  },
});

export default withStyles(styles)(StockHistoryChart);
