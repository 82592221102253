import React, { Component } from 'react';
import { observer, inject, PropTypes as MobxPropTypes } from 'mobx-react';
import Button from '@material-ui/core/Button';
import { DateTime } from 'luxon';
import { withSnackbar } from 'notistack';

import NewDeliveryForm from './NewDeliveryForm';

const initialState = {
  selectedMaterial: '',
  numberOfMaterialsInput: '',
  deliveryDate: DateTime.local(),
  orderNumberInput: '',
};

@inject('store')
@observer
class NewDeliveryFormInner extends Component {
  constructor(props) {
    super(props);
    this.state = initialState;
  }

  clearInputs = () => {
    this.setState(initialState);
  }

  handleMaterialInputChange = (material) => {
    this.setState({
      selectedMaterial: material.value,
    });
  }

  handleOrderNumberChange = (event) => {
    this.setState({
      orderNumberInput: event.target.value,
    });
  }

  handleNumberOfMaterialsChange = (event) => {
    this.setState({
      numberOfMaterialsInput: event.target.value,
    });
  }

  onDateChange = (date) => {
    this.setState({
      deliveryDate: date,
    });
  }

  onAddDelivery = () => {
    const {
      selectedMaterial,
      deliveryDate,
      numberOfMaterialsInput,
      orderNumberInput,
    } = this.state;

    this.props.store.source.addDelivery(
      {
        selectedMaterial,
        deliveryDate,
        numberOfMaterialsInput,
        orderNumberInput,
      },
    );

    this.clearInputs();
  }

  isValid = () => {
    const {
      selectedMaterial,
      deliveryDate,
      numberOfMaterialsInput,
      orderNumberInput,
    } = this.state;

    return selectedMaterial && deliveryDate && numberOfMaterialsInput && orderNumberInput;
  }

  render() {
    const {
      numberOfMaterialsInput,
      deliveryDate,
      orderNumberInput,
    } = this.state;

    return (
      <>
        <NewDeliveryForm
          numberOfMaterialsInput={numberOfMaterialsInput}
          deliveryDate={deliveryDate}
          orderNumberInput={orderNumberInput}
          onOrderNumberChange={this.handleOrderNumberChange}
          onMaterialInputChange={this.handleMaterialInputChange}
          onNumberOfMaterialsChange={this.handleNumberOfMaterialsChange}
          onDateChange={this.onDateChange}
        />
        <Button
          onClick={this.onAddDelivery}
          disabled={!this.isValid()}
          aria-label="Anlegen"
        >
            ANLEGEN
        </Button>
      </>
    );
  }
}

NewDeliveryFormInner.propTypes = {
  store: MobxPropTypes.objectOrObservableObject,
};

export default (withSnackbar(NewDeliveryFormInner));
