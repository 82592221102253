import React from 'react';
import PropTypes from 'prop-types';
import {
  Typography,
  Checkbox,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const DispoCard = ({
  label,
  dispos,
  classes,
  selected,
  hideCheckbox,
}) => (
  <div className={classes.card}>
    {!hideCheckbox && (
      <Checkbox checked={selected} />
    )}
    <span className={classes.textBox}>
      <Typography>{label}</Typography>
      <Typography variant="caption">{dispos.join(', ')}</Typography>
    </span>
  </div>
);

DispoCard.propTypes = {
  label: PropTypes.string,
  dispos: PropTypes.array,
  classes: PropTypes.object,
  selected: PropTypes.bool,
  hideCheckbox: PropTypes.bool,
};

const style = () => ({
  card: {
    display: 'flex',
    flexDirection: 'row',
  },
  textBox: {
    display: 'flex',
    flexDirection: 'column',
  },
});

export default withStyles(style)(DispoCard);
