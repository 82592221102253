import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

const StatusBox = ({
  status,
  classes,
}) => (
  <div
    className={classNames(classes.box, classes[status])}
  />
);

StatusBox.propTypes = {
  classes: PropTypes.object,
  status: PropTypes.string,
};

const styles = () => ({
  box: {
    height: 20,
    width: 20,
  },
  green: {
    backgroundColor: 'rgba(0, 166, 94, 0.5)',
  },
  yellow: {
    backgroundColor: 'rgba(245, 225, 20, 0.5)',
  },
  red: {
    backgroundColor: 'rgba(228, 66, 88, 0.5)',
  },
});

export default withStyles(styles)(StatusBox);
