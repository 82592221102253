import gql from 'graphql-tag';

export const MISSING_PART_DATA = gql`
  fragment MissingPartData on MissingPartType {
    systemName
    omlStart
    delivery
    confirmed
    quantity
    materialNumber
    materialDescription
    supplierCode
    supplierName
    supplierNumber
    salesOrderNumber
    productionOrderNumber
    loadDate
    confirmed
    customerName
    customerCountryCode
    remark
    kojeNumber
  }
`;

export const MISSING_PART_GROUP_DATA = gql`
    ${MISSING_PART_DATA}
    fragment MissingPartGroupData on MissingPartGroupType {
        materialNumber
        missingParts {
            edges {
                node {
                    ...MissingPartData
                }
            }
        }
    }
`;
