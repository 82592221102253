import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Switch from '@material-ui/core/Switch';
import Typography from '@material-ui/core/Typography';
import VisibleIcon from '@material-ui/icons/Visibility';
import InvisibleIcon from '@material-ui/icons/VisibilityOff';
import SaveIcon from '@material-ui/icons/Save';
import DeleteIcon from '@material-ui/icons/Delete';
import classnames from 'classnames';
import { PropTypes as MobxPropTypes, inject, observer } from 'mobx-react';
import { withSnackbar } from 'notistack';
import { debounce } from 'throttle-debounce';
import { updateProfile } from '../../Api/endpoints/updateProfile';
import { extractErrorText } from '../../Api';

@inject('store')
@observer
class ViewHeader extends Component {
  state = {
    presetFormVisible: false,
    newPresetName: '',
  }

  showOutOfDateError = debounce(500, true, () => {
    const { enqueueSnackbar } = this.props;
    enqueueSnackbar('Ihr Profil ist veraltet. Bitte melden Sie sich erneut an', { variant: 'error' });
  })

  togglePresetForm = () => {
    const { store } = this.props;
    const { settings } = store.user.user.profile;
    const { selectedPreset } = store.ui;

    const personalPresets = (settings && JSON.parse(settings).savedPresets) || [];
    const selectedPersonalPreset = personalPresets.find(preset => preset.id === selectedPreset);

    this.setState(({ presetFormVisible }) => {
      const formNowVisible = !presetFormVisible;
      return {
        presetFormVisible: !presetFormVisible,
        newPresetName: (formNowVisible && selectedPersonalPreset)
          ? selectedPersonalPreset.label
          : '',
      };
    });
  }

  activatePreset = (value) => {
    const { store } = this.props;
    const { presetViews, selectPreset, selectUserPreset } = store.ui;
    const { profile } = store.user.user;

    if (profile) {
      const { settings } = this.props.store.user.user.profile;

      if (presetViews.some(preset => preset.id === value)) {
        selectPreset(value);
      } else {
        const personalPresets = (settings && JSON.parse(settings).savedPresets) || [];
        const selectedPreset = personalPresets.find(preset => preset.id === value);
        selectUserPreset(selectedPreset);
      }
    } else {
      this.showOutOfDateError();
    }
  }

  handlePresetSelected = (event) => {
    const { value } = event.target;
    this.activatePreset(value);
  }

  handlePresetNameChange = (event) => {
    const { value } = event.target;
    this.setState({
      newPresetName: value,
    });
  }

  deleteSelectedPreset = async () => {
    const {
      store,
      enqueueSnackbar,
    } = this.props;

    const {
      user,
      token,
    } = store.user;

    const {
      presetViews,
    } = store.ui;

    if (user.profile) {
      const newSettings = user.profile.settings ? JSON.parse(user.profile.settings) : {};

      if (!newSettings.savedPresets) {
        newSettings.savedPresets = [];
      }

      if (presetViews.some(preset => preset.id === store.ui.selectedPreset)) {
        enqueueSnackbar('Sie können diese Preset nicht löschen', { variant: 'error' });
      } else {
        newSettings.savedPresets = newSettings.savedPresets.filter(preset => preset.id !== store.ui.selectedPreset);

        const updatedProfile = {
          profile: {
            ...user.profile,
            settings: JSON.stringify(newSettings),
          },
        };

        try {
          const { value, error } = await updateProfile(updatedProfile, token);
          if (error) {
            throw error;
          }
          enqueueSnackbar('Aktualisierung erfolgreich', { variant: 'success' });

          user.profile = value.profile;

          this.setState({
            newPresetName: '',
            presetFormVisible: false,
          });
          this.activatePreset('source');
        } catch (error) {
          store.handleError(error, extractErrorText(error));
        }
      }
    } else {
      this.showOutOfDateError();
    }
  }

  savePreset = async () => {
    const {
      store,
      enqueueSnackbar,
    } = this.props;

    const {
      newPresetName,
    } = this.state;

    const {
      user,
      token,
    } = store.user;

    const {
      presetViews,
      grouped,
      filterDispo,
      sourceSwitch,
      sourceVisible,
      sourceExpanded,
      makeVisible,
      makeExpanded,
      autoRefresh,
      showExpectedMissingParts,
    } = store.ui;

    const config = {
      grouped,
      filterDispo,
      sourceSwitch,
      sourceVisible,
      sourceExpanded,
      makeVisible,
      makeExpanded,
      autoRefresh,
      showExpectedMissingParts,
    };

    if (user.profile) {
      const newSettings = user.profile.settings ? JSON.parse(user.profile.settings) : {};

      const newPresetId = newPresetName.toLowerCase().replace(' ', '-');

      if (!newSettings.savedPresets) {
        newSettings.savedPresets = [];
      }

      if (presetViews.some(preset => preset.id === newPresetId)) {
        enqueueSnackbar('Presets müssen eindeutige Namen haben', { variant: 'error' });
      } else {
        newSettings.savedPresets = [
          ...newSettings.savedPresets.filter(preset => preset.id !== newPresetId),
          {
            id: newPresetId,
            label: newPresetName,
            config,
          },
        ];

        const updatedProfile = {
          profile: {
            ...user.profile,
            settings: JSON.stringify(newSettings),
          },
        };

        try {
          const { value, error } = await updateProfile(updatedProfile, token);
          if (error) {
            throw error;
          }
          enqueueSnackbar('Aktualisierung erfolgreich', { variant: 'success' });

          user.profile = value.profile;
          store.ui.selectedPreset = newPresetId;

          this.setState({
            newPresetName: '',
            presetFormVisible: false,
          });
        } catch (error) {
          store.handleError(error, extractErrorText(error));
        }
      }
    } else {
      this.showOutOfDateError();
    }
  }

  render() {
    const {
      store,
      classes,
    } = this.props;

    const {
      presetFormVisible,
      newPresetName,
    } = this.state;

    const { user } = store.user;

    if (!user.profile) {
      this.showOutOfDateError();
    }

    const views = [
      {
        id: 'source',
        compactProp: 'sourceVisible',
        compactToggle: 'toggleSourceCompact',
        middleProp: 'sourceWeekly',
        middleToggle: 'toggleSourceWeekly',
        expandedProp: 'sourceExpanded',
        expandedToggle: 'toggleSourceExpanded',
        label: 'Lieferungen',
      },
      {
        id: 'make',
        middleProp: 'makeVisible',
        middleToggle: 'toggleMakeVisible',
        expandedProp: 'makeExpanded',
        expandedToggle: 'toggleMakeExpanded',
        label: 'Fertigungsstarts',
      },
    ];

    const personalPresets = (
      user.profile && user.profile.settings && JSON.parse(store.user.user.profile.settings).savedPresets
    ) || [];

    return (
      <div className={classes.container}>
        <div className={classnames(classes.column, classes.controlColumn)}>
          <FormGroup>
            <FormControl className={classes.formControl}>
              <InputLabel htmlFor="preset">Preset</InputLabel>
              <Select
                value={store.ui.selectedPreset}
                onChange={this.handlePresetSelected}
                inputProps={{
                  name: 'preset',
                  id: 'preset',
                }}
              >
                {[
                  ...store.ui.presetViews,
                  ...personalPresets,
                ].map(preset => (
                  <MenuItem key={preset.id} value={preset.id}>{preset.label}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControlLabel
              control={(
                <Switch
                  checked={store.ui.grouped}
                  onChange={store.ui.toggleGrouping}
                  value="grouped"
                  color="primary"
                />
              )}
              label="Kategorien anzeigen (Material)"
            />
            <FormControlLabel
              control={(
                <Switch
                  checked={store.ui.sourceSwitch}
                  onChange={store.ui.toggleSourceSwitch}
                  value="switched"
                  color="primary"
                />
              )}
              label="Reihenfolge umkehren (Material)"
            />
            <FormControlLabel
              control={(
                <Switch
                  checked={store.ui.autoRefresh}
                  onChange={store.ui.toggleAutoRefresh}
                  value="autoRefresh"
                  color="primary"
                />
              )}
              label="Autoaktualisierung"
            />
            <FormControlLabel
              control={(
                <Switch
                  checked={store.ui.showExpectedMissingParts}
                  onChange={store.ui.toggleShowExpectedMissingParts}
                  value="showExpectedMissingParts"
                  color="primary"
                />
              )}
              label="Errechnete Fehlteile anzeigen"
            />
            <FormControlLabel
              control={(
                <Switch
                  checked={store.ui.showReliableSuppliers}
                  onChange={store.ui.toggleShowReliableSuppliers}
                  value="showReliableSuppliers"
                  color="primary"
                />
              )}
              label="Freigeschaltete Lieferanten anzeigen"
            />
          </FormGroup>
        </div>
        <div className={classnames(classes.column, classes.saveColumn)}>
          {window.location.pathname === '/all' && (
            <>
              {presetFormVisible && (
                <>
                  <TextField
                    id="new-preset-name"
                    label="Name"
                    className={classes.textField}
                    value={newPresetName}
                    onChange={this.handlePresetNameChange}
                    margin="normal"
                  />
                  <Button onClick={this.savePreset} aria-label="Speichern">
                    <SaveIcon className={classes.buttonIcon} />
                    SAVE
                  </Button>
                </>
              )}
              <Button onClick={this.togglePresetForm} aria-label={presetFormVisible ? 'Abbrechen' : 'Voreinstellungen speichern'}>
                {
                  presetFormVisible
                    ? 'CANCEL'
                    : (
                      <>
                        <SaveIcon className={classes.buttonIcon} />
                        SAVE PRESET
                      </>
                    )
                }
              </Button>
              <Button onClick={this.deleteSelectedPreset} aria-label="Voreinstellungen löschen">
                <>
                  <DeleteIcon className={classes.buttonIcon} />
                  DELETE PRESET
                </>
              </Button>
            </>
          )}
        </div>
        {views.map(view => (
          <div
            key={`${view.id}-controls`}
            className={classnames(classes.column, classes.viewColumn)}
          >
            {window.location.pathname === '/all' && (
              <>
                <div className={classes.viewSelector}>
                  {view.compactProp !== undefined && (
                    <div
                      role="button"
                      onClick={store.ui[view.compactToggle]}
                      className={classnames(
                        classes.view,
                        classes.narrowView,
                        {
                          [classes.selected]: store.ui[view.compactProp],
                        },
                      )}
                    >
                      <Typography>Kompakt</Typography>
                      { store.ui[view.compactProp] ? (
                        <VisibleIcon />
                      ) : (
                        <InvisibleIcon />
                      )}
                    </div>
                  )}
                  <div
                    role="button"
                    onClick={store.ui[view.middleToggle]}
                    className={classnames(
                      classes.view,
                      classes.wideView,
                      {
                        [classes.selected]: store.ui[view.middleProp],
                      },
                    )}
                  >
                    <Typography>{view.label}</Typography>
                    { store.ui[view.middleProp] ? (
                      <VisibleIcon />
                    ) : (
                      <InvisibleIcon />
                    )}
                  </div>
                  <div
                    role="button"
                    onClick={store.ui[view.expandedToggle]}
                    className={classnames(
                      classes.view,
                      classes.narrowView,
                      {
                        [classes.selected]: store.ui[view.expandedProp],
                      },
                    )}
                  >
                    <Typography>2. Woche</Typography>
                    { store.ui[view.expandedProp] ? (
                      <VisibleIcon />
                    ) : (
                      <InvisibleIcon />
                    )}
                  </div>
                </div>
              </>
            )}
          </div>
        ))}
      </div>
    );
  }
}

ViewHeader.propTypes = {
  store: MobxPropTypes.objectOrObservableObject,
  classes: PropTypes.object,
  enqueueSnackbar: PropTypes.func,
};

const style = theme => ({
  container: {
    display: 'flex',
    paddingRight: '68px',
    justifyContent: 'space-around',
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
  },
  controlsColumn: {
    width: 350,
  },
  saveColumn: {
    width: 235,
  },
  viewColumn: {
    flex: 1,
  },
  viewSelector: {
    display: 'flex',
    height: '128px',
    marginRight: '10px',
  },
  view: {
    border: '1px dashed white',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
  },
  wideView: {
    flex: '2',
  },
  narrowView: {
    flex: '1',
  },
  selected: {
    border: '1px solid white',
    backgroundColor: 'rgba(255, 255, 255, 0.08)',
  },
  formControl: {
    margin: theme.spacing(1),
    width: 120,
  },
  buttonIcon: {
    marginRight: theme.spacing(1),
  },
});

export default withSnackbar(withStyles(style)(ViewHeader));
