import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Popover from '@material-ui/core/Popover';
import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';

class SimpleTableRow extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      popoverOpen: false,
      popoverAnchor: undefined,
    };
  }

  handlePopoverOpen = (event) => {
    const target = event.currentTarget;
    this.setState((prevState => ({
      popoverAnchor: prevState.popoverAnchor ? prevState.popoverAnchor : target,
      popoverOpen: true,
    })));
  }

  handlePopoverClose = () => {
    this.setState({
      popoverOpen: false,
    });
  }

  render() {
    const {
      row,
      classes,
      highlightField,
      highlightValue,
      onRowClick,
      columns,
      rowHoverEffect,
      rowIntroId,
      rowToolTip,
      forceHighlight,
    } = this.props;

    const {
      popoverOpen,
      popoverAnchor,
    } = this.state;

    const tableRow = (
      <TableRow
        id={rowIntroId}
        component="div"
        className={classnames(
          classes.tableRow,
          classes.mainRow,
          classes.dataRow,
          {
            [classes.hoverRow]: rowHoverEffect,
            [`${classes.highlightedRow}`]: forceHighlight
              || (highlightField && row[highlightField] === highlightValue),
          },
        )}
        onClick={onRowClick && (() => onRowClick(row))}
        onMouseEnter={this.handlePopoverOpen}
        onMouseLeave={this.handlePopoverClose}
      >
        {columns.map((column) => {
          const styleObj = {};
          if (column.width) {
            styleObj.width = column.width;
          } else {
            styleObj.flex = '1';
          }
          if (!column.Cell) {
            return (
              <TableCell
                key={`${column.key || column.accessor}-row`}
                component="div"
                className={classes.tableCell}
                style={styleObj}
                onClick={column.onClick}
              >
                {row[column.accessor]}
              </TableCell>
            );
          }
          return (
            <TableCell
              key={`${column.key || column.accessor}-row`}
              component="div"
              className={classes.tableCell}
              style={styleObj}
              onClick={column.onClick && (event => column.onClick(row, column.accessor, event))}
            >
              {column.accessor
                ? (
                  <column.Cell
                    id={row[column.keyColumn]}
                    row={row[column.accessor]}
                    onChange={column.onChange}
                  />
                ) : (
                  <column.Cell row={row} />
                )}
            </TableCell>
          );
        })}
      </TableRow>
    );

    return (
      <>
        {rowToolTip && (
          <Popover
            className={classes.popover}
            classes={{
              paper: classes.popoverPaper,
            }}
            open={popoverOpen}
            anchorEl={popoverAnchor}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            disableRestoreFocus
            onMouseEnter={this.handlePopoverOpen}
            onMouseLeave={this.handlePopoverClose}
          >
            <IconButton
              className={classes.closeButton}
              onClick={this.handlePopoverClose}
              data-testid="close"
              aria-label="Schließen"
            >
              <ClearIcon />
            </IconButton>
            {rowToolTip(row)}
          </Popover>
        )}
        {tableRow}
      </>
    );
  }
}

SimpleTableRow.propTypes = {
  row: PropTypes.object,
  classes: PropTypes.object,
  highlightField: PropTypes.string,
  highlightValue: PropTypes.any,
  onRowClick: PropTypes.func,
  columns: PropTypes.array.isRequired,
  rowHoverEffect: PropTypes.bool,
  rowIntroId: PropTypes.string,
  rowToolTip: PropTypes.any,
  forceHighlight: PropTypes.bool,
};

const style = theme => ({
  mainRow: {
    height: 'auto',
  },
  hoverRow: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },
  },
  popover: {
    pointerEvents: 'none',
    position: 'relative',
  },
  popoverPaper: {
    padding: theme.spacing(1),
    pointerEvents: 'auto',
  },
  highlightedRow: {
    backgroundColor: 'rgba(0, 0, 0, 0.08)',
    boxShadow: `0px 2px 1px -1px rgba(0,0,0,0.05),
    0px 1px 1px 0px rgba(0,0,0,0.1),
    0px 1px 3px 0px rgba(0,0,0,0.08)`,
  },
  closeButton: {
    position: 'absolute',
    top: 0,
    right: 0,
    padding: '6px',
    zIndex: 1,
  },
  tableRow: {},
  table: {},
  tableBody: {},
  tableCell: {},
  groupHeading: {},
  groupRow: {},
  expandRow: {},
  expand: {},
  expandOpen: {},
  dataRow: {},
});

export default withStyles(style)(SimpleTableRow);
