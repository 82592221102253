import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import CheckIcon from '@material-ui/icons/Check';
import TextCell from '../TextCell';

const DemandCard = ({ classes, value }) => {
  const statusIcon = (<CheckIcon className={classes.statusIcon}/>);

  if (value) {
    return (
      <TextCell value={value.demandQuantity} prependIcon={statusIcon} showPrependIcon={value.reservationFilled}/>
    );
  }
  return null;
};

DemandCard.propTypes = {
  classes: PropTypes.object,
  value: PropTypes.object,
};

const styles = () => ({
  statusIcon: {
    fontSize: '12px',
  },
});

export default withStyles(styles)(DemandCard);
