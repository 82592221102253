import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { inject, observer, PropTypes as MobxPropTypes } from 'mobx-react';
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TextField,
  Button,
} from '@material-ui/core';

const handleNumberChange = (setter) => (e) => setter(parseInt(e.currentTarget.value, 10));

@inject('store')
@observer
class MinMaxConfig extends Component {
  render() {
    const {
      classes,
      store,
    } = this.props;

    const {
      partInfos,
      getRange,
      setMin,
      setMax,
      setToleranceMinus,
      setTolerancePlus,
      copyToleranceToAll,
      resetPartInfos,
    } = store.reporting;

    return (
      <div className={classes.container}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell colSpan={2}>Materialien</TableCell>
              <TableCell>Optimale Reichweite (Tage)</TableCell>
              <TableCell>Toleranzen (%)</TableCell>
              <TableCell>Mögliche Reichweite</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {partInfos.map(part => (
              <React.Fragment key={part.id}>
                <TableRow>
                  <TableCell rowSpan={2}>{part.label}</TableCell>
                  <TableCell>MIN</TableCell>
                  <TableCell>
                    <TextField
                      type="number"
                      value={part.min}
                      onChange={handleNumberChange(setMin(part.id))}
                    />
                  </TableCell>
                  <TableCell rowSpan={part.maxVariation ? 2 : 1}>
                    <div className={classes.toleranceCell}>
                      <TextField
                        id={`${part.id}-tolerance-minus`}
                        type="number"
                        label="-"
                        value={part.toleranceMinus}
                        onChange={handleNumberChange(setToleranceMinus(part.id))}
                      />
                      <TextField
                        id={`${part.id}-tolerance-plus`}
                        type="number"
                        label="+"
                        value={part.tolerancePlus}
                        onChange={handleNumberChange(setTolerancePlus(part.id))}
                      />
                    </div>
                    <Button onClick={() => copyToleranceToAll(part.id)} aria-label="Inhalt an alle kopieren">Inhalt an alle kopieren</Button>
                  </TableCell>
                  <TableCell>{getRange(part, 'min')}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>MAX</TableCell>
                  <TableCell>
                    <TextField
                      type="number"
                      value={part.max}
                      onChange={handleNumberChange(setMax(part.id))}
                    />
                  </TableCell>
                  {!part.maxVariation && (
                    <TableCell>n.a.</TableCell>
                  )}
                  <TableCell>{part.maxVariation ? getRange(part, 'max') : 'n.a.'}</TableCell>
                </TableRow>
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
        <div className={classes.resetAllContainer}>
          <Button
            onClick={resetPartInfos}
            className={classes.resetButton}
            aria-label="Alle Werte zurücksetzen"
          >
            Alle Zurücksetzen
          </Button>
        </div>
      </div>
    );
  }
}

MinMaxConfig.propTypes = {
  store: MobxPropTypes.objectOrObservableObject,
  classes: PropTypes.object,
};

const styles = () => ({
  toleranceCell: {
    display: 'flex',
    flexDirection: 'column',
  },
  container: {
    width: '100%',
  },
  resetAllContainer: {
    marginTop: 5,
    display: 'flex',
    justifyContent: 'flex-end',
  },
});

export default withStyles(styles)(MinMaxConfig);
