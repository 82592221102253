import { observable, action, computed } from 'mobx';
import { persist } from 'mobx-persist';
import { DateTime } from 'luxon';
import jwtDecode from 'jwt-decode';
import { baseUrl, extractErrorText } from '../Api';
import { refreshToken } from '../Api/endpoints/auth';
import { updateProfile } from '../Api/endpoints/updateProfile';

export const emptyUser = {
  pk: '',
  username: '',
  email: '',
  first_name: '',
  last_name: '',
  is_staff: false,
  is_superuser: false,
  permissions: [],
  profile: {
    data_use_consent_date: Date.now(),
    dispo_presets: [],
    location: 'Forchheim',
  },
};

export default class User {
  constructor(rootStore) {
    this.root = rootStore;
  }

  @persist('object')
  @observable user = emptyUser;
  @observable redirecting = false;
  @persist('list') @observable dispoFilters = ['all'];
  @persist('list') @observable locationOptions = [
    {
      value: 'Forchheim',
      label: 'Forchheim',
    },
    {
      value: 'Kemnath',
      label: 'Kemnath',
    },
  ];
  @persist('object') @observable selectedLocation = this.locationOptions[0];

  @computed get isLoggedIn() {
    return !!this.user.username;
  }

  @persist
  @observable token = '';

  @action.bound
  setUser(userObj) {
    if (userObj) {
      this.user = userObj;
      this.dispoFilters = (userObj.profile.dispos
        && userObj.profile.dispos.length > 0)
        ? ['own']
        : ['all'];
      this.setLocation(userObj.profile.location);
    } else {
      this.user = emptyUser;
      this.dispoFilters = ['all'];
    }
  }

  @action.bound
  getTimeToTokenExpiry() {
    const decodedToken = jwtDecode(this.token);
    const expiration = decodedToken.exp;
    const now = DateTime.local().toSeconds();
    const secondsUntilExpiration = expiration - now;

    return secondsUntilExpiration;
  }

  @action.bound
  setToken(token) {
    this.token = token;
    if (token !== '') {
      const secondsUntilExpiration = this.getTimeToTokenExpiry();

      setTimeout(this.refreshUserToken, (secondsUntilExpiration - 30) * 1000);
    }
  }

  @action.bound
  async refreshUserToken() {
    const newToken = await refreshToken(this.token);
    this.setToken(newToken);
  }

  @action.bound
  async redirectToLogin() {
    this.redirecting = true;
    this.setUser();
    this.setToken('');
    window.location = `${baseUrl}/microsoft/auth`;
  }

  @action.bound
  async logout() {
    this.redirecting = true;
    this.setUser();
    this.setToken('');
    window.location = `${baseUrl}/microsoft/logout`;
  }

  @action.bound
  async acceptDataUse() {
    if (this.user.profile) {
      const updatedProfile = {
        profile: {
          ...this.user.profile,
          data_use_consent_date: DateTime.local().toISO(),
        },
      };

      try {
        const { value, error } = await updateProfile(updatedProfile, this.token);
        if (error) {
          throw error;
        }
        this.user.profile = value.profile;
      } catch (error) {
        this.root.handleError(error, extractErrorText(error));
      }
    }
  }

  @action.bound
  async setLocation(locationValue) {
    const locationObject = await this.locationOptions.find(
      option => option.value === locationValue,
    );
    if (locationObject) {
      this.selectedLocation = locationObject;
      this.root.source.refreshData();
    }
  }
}
