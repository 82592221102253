import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';


const ExtendedConfig = ({ 
  classes, 
  expectedMissingPartTrafficLights, 
  disableMissingPartTrafficLights, 
  onExpectedMissingPartTrafficLightsChange 
}) => {
  const handleYellowPartChange = (event) => {
    if (event.target.checked) {
      onExpectedMissingPartTrafficLightsChange(
        expectedMissingPartTrafficLights.filter(value => value !== 'yellow'),
      );
    } else {
      onExpectedMissingPartTrafficLightsChange(
        [...expectedMissingPartTrafficLights, 'yellow'],
      );
    }
  };
  return (
    <div className={classes.container}>
      <FormControlLabel
        className={classes.formControl}
        control={(
          <Checkbox
            disabled={disableMissingPartTrafficLights.has('yellow')}
            checked={!expectedMissingPartTrafficLights.includes('yellow')}
            onChange={handleYellowPartChange}
          />
        )}
        label={(
          <Typography
            className={classes.labelTitle}
            variant="subtitle2"
            align="left"
          >
            Geplante Anlieferungen einkalkulieren
          </Typography>
        )}
      />

    </div>
  );
};
ExtendedConfig.propTypes = {
  classes: PropTypes.object,
  disableMissingPartTrafficLights: PropTypes.instanceOf(Set),
  expectedMissingPartTrafficLights: PropTypes.array,
  onExpectedMissingPartTrafficLightsChange: PropTypes.func,
};

const styles = (theme) => ({
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    padding: theme.spacing(1),
  },
  labelTitle: {
    fontWeight: 'bold',
  },
});

export default withStyles(styles)(ExtendedConfig);
