import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import SvgIcon from '@material-ui/core/SvgIcon';
import { ReactComponent as MissingIcon } from '../../ProjectCard/fehlteile.svg';
import TextCell from '../TextCell';

function StockCard(props) {
  const { classes, value } = props;

  const statusIcon = (
    <SvgIcon className={classes.statusIcon}>
      <MissingIcon/>
    </SvgIcon>
  );

  if (value) {
    const { current } = value.stockObject;
    return (
      <TextCell prependIcon={statusIcon} showPrependIcon={value.isMissingPart} value={current}/>
    );
  }
  return null;
}

StockCard.propTypes = {
  classes: PropTypes.object,
  value: PropTypes.object,
};

const style = () => ({
  statusIcon: {
    fontSize: '12px',
  },
});

export default withStyles(style)(StockCard);
