import { join, pathOr } from 'ramda';

import API from '../index';

async function login(username, password) {
  try {
    const res = await API.post('auth/login/', {
      username,
      password,
    });

    return {
      value: res.data,
    };
  } catch (e) {
    const extractErrors = pathOr([], ['response', 'data', 'non_field_errors']);

    return {
      error: join('. ', extractErrors(e)),
    };
  }
}

async function refreshToken(token) {
  try {
    const res = await API.post('auth/refresh/', {
      token,
    });

    return res.data.token;
  } catch (e) {
    const extractErrors = pathOr([], ['response', 'data', 'non_field_errors']);

    return {
      error: join('. ', extractErrors(e)),
    };
  }
}

async function getUserInfo(token) {
  try {
    const res = await API.get('auth/user/', {
      headers: {
        Authorization: `JWT ${token}`,
      },
    });
    return res.data;
  } catch (e) {
    const extractErrors = pathOr([], ['response', 'data', 'non_field_errors']);

    return {
      error: join('. ', extractErrors(e)),
    };
  }
}

export {
  login,
  refreshToken,
  getUserInfo,
};
