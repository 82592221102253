import { join, pathOr } from 'ramda';
import API from '../index';

export async function getReliableSuppliers(token) {
  try {
    const res = await API.get(
      'reliable-supplier/',
      {
        headers: {
          Authorization: `JWT ${token}`,
        },
      },
    );
    return {
      value: res.data,
    };
  } catch (e) {
    const extractErrors = pathOr([], ['response', 'data', 'non_field_errors']);

    return {
      error: join('. ', extractErrors(e)),
    };
  }
}
