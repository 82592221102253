import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { inject, observer, PropTypes as MobxPropTypes } from 'mobx-react';
import { Steps } from 'intro.js-react';
import Button from '@material-ui/core/Button';
import SaveIcon from '@material-ui/icons/SaveAlt';
import IconButton from '@material-ui/core/IconButton';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import classNames from 'classnames';
import { DateTime } from 'luxon';

import ConfigCard from '../../../Components/Reporting/ConfigCard';
import RangeConfig from '../../../Components/Reporting/RangeConfig';
import TypeConfig from '../../../Components/Reporting/TypeConfig';
import MaterialConfig from '../../../Components/Reporting/MaterialConfig';
import ExtendedConfig from '../../../Components/Reporting/ExtendedConfig';
import FilterConfig from '../../../Components/Reporting/FilterConfig';
import LogicConfig from '../../../Components/Reporting/LogicConfig';
import MinMaxConfig from '../../../Components/Reporting/MinMaxConfig';
import DiagramConfig from '../../../Components/Reporting/DiagramConfig';
import ReportingSection from '../../../Components/Reporting/Section';
import ReportPreview from '../../../Components/Reporting/ReportPreview';
import MaterialListReport, {
  exportTable as exportMaterialListTable,
} from '../../../Components/Reporting/TableReports/MaterialListReport';
import MinMaxReport, {
  exportTable as exportMinMaxTable,
} from '../../../Components/Reporting/TableReports/MinMaxReport';

@inject('store')
@observer
class ReportingContainer extends Component {
  constructor(props) {
    super(props);

    props.store.reporting.getInfo();
  }

  componentDidMount() {
    this.props.store.reporting.activateDashboardFilter();
  }

  handleSelect = (event, newValue) => {
    this.props.store.reporting.selectReportCategory(newValue);
  }

  onIntroExit = () => {
    this.props.store.ui.reportingIntroDone = true;
  }

  render() {
    const {
      classes,
      store,
    } = this.props;

    const {
      selectedReport,
      tableReportables,
      selectedReportables,
      axisScale,
      selectedReportCategory,
      setAxisScale,
      rangeMode,
      setRangeMode,
      startDate,
      setStartDate,
      endDate,
      setEndDate,
      info,
      report,
      loading,
      tableFilter,
      getReport,
      reportCategories,
      selectedMaterial,
      previewUrl,
      pdfUrl,
      previewLoading,
      updatePreviewUrl,
      previewLoadComplete,
    } = store.reporting;

    const tableReportablesNotEmpty = () => {
      let filled = false;
      tableReportables.forEach(reportable => {
        filled = filled
          || (
            reportable.category != null
            && reportable.subcategory != null
            && reportable.operator != null
            && reportable.operand != null
          );
      });
      return filled;
    };

    const allConfigs = [
      {
        id: 'xAxisScaling',
        completed: () => !!axisScale,
      },
      {
        id: 'filter',
        completed: () => true,
      },
      {
        id: 'logic',
        completed: tableReportablesNotEmpty,
      },
      {
        id: 'minMax',
        completed: () => true,
      },
      {
        id: 'timePeriod',
        completed: () => !!(startDate && endDate),
      },
      {
        id: 'materialNumber',
        completed: () => selectedMaterial,
      },
      {
        id: 'extended',
        completed: () => true,
      },
    ];

    const typeDefined = selectedReport && (selectedReport.reportType === 'table' || selectedReportables.length > 0);
    const relevantConfigs = selectedReport ? selectedReport.configs : [];

    const configsBefore = (id) => relevantConfigs.filter((config, index) => index < relevantConfigs.indexOf(id));
    const configWithIdComplete = (id) => allConfigs.find(config => config.id === id).completed();
    const previousConfigsComplete = (id) => configsBefore(id).every(configWithIdComplete);

    const shouldConfigDisplay = (id) => typeDefined && relevantConfigs.includes(id) && previousConfigsComplete(id);

    const getLastBinDate = () => {
      switch (axisScale) {
        case 'daily': {
          const dayDiff = DateTime.local().weekday === 1 ? 3 : 1; // skip weekend if today's monday
          return DateTime.local().minus({ days: dayDiff }).toISODate();
        }
        case 'weekly':
          return DateTime.local()
            .minus({ weeks: 1 })
            .endOf('week')
            .minus({ days: 2 }) // friday
            .toISODate();
        case 'monthly':
          return DateTime.local().minus({ months: 1 }).endOf('month').toISODate();
        case 'quarterly':
          return DateTime.local().minus({ months: 3 }).endOf('quarter').toISODate();
        case 'yearly':
          return DateTime.local().minus({ years: 1 }).endOf('year').toISODate();
        default:
          throw new Error('unknown axis scale used');
      }
    };

    const latestEnd = selectedReport && axisScale ? getLastBinDate() : info.latest_end_date;

    return (
      <div className={classes.outerContainer}>
        <Steps
          enabled={!store.ui.reportingIntroDone}
          steps={[
            {
              element: '#tab-system',
              intro: `
In diesem Reporting können Auswertungen (graphische Darstellung) zu Systemen mit Fehlteilen gewählt werden. Hierbei ist die Auswahl möglich zwischen:

- Systeme mit SCM IS Fehlteilen am Referenztag
- Systeme mit Errechneten Fehlteilen in der ersten Woche / zweiten Woche / vor Materialabruf in der ersten Woche

Die Skalierung der X-Achse kann nach Tagen, Wochen oder Monaten gewählt werden.

Der Zeitraum kann belieblig zwischen Tagen, Monaten, Quartalen oder Geschäftsjahren gewählt werden (abhängig von der Verfügbarkeit der Daten). 

Als Ergebnis wird eine Vorschau angezeigt. Hier können die einzelnen Werte via Mouse-Over sichtbar gemacht werden. Ebenso ist ein pdf-Download möglich.
`,
            },
            {
              element: '#tab-material',
              intro: `
In diesem Reporting können Auswertungen in Form einer Materialliste oder eine graphische Darstellung der Anzahl von Fehlteilen gewählt werden.

Materiallliste:

In diesem Reporting wird eine Liste mit Materialien ausgegeben, die die folgenden Kriterien erfüllen.

Bei den Filterkriterien kann (wenn gewünscht) ein Lieferant, die ABC-Klassifizierung, Dispo und/oder der Materialkategorie ausgewählt werden. Eine Deselektierung ist mittels "x" möglich. 

Bei den Logikkriterien stehen verschiedene Kriterien mit folgende Berechnungslogiken zur Auswahl:
- Bestand in [Tagen]
- Bestand in [Häufigkeit]
- Reichweite in [Tagen]
- Bedarf in [Stück]
`,
            },
            {
              element: '#tab-material',
              intro: `
Materialverlauf: Anzahl Fehlteile

In diesem Reporting können Auswertungen (graphische Darstellung) zur Anzahl von Fehlteilen gewählt werden. Hierbei ist die Auswahl möglich zwischen:

- Fehlteiltyp: SCM IS oder Errechnete Fehlteile
- Berechnungslogik: Anzahl der absoluten FT (Summe von "Gesamt-Menge der FT pro System"), Anzahl basiert auf Systemen (Summe von "Anzahl an FT pro System"), Anzahl Materialien mit FT (Anzahl Materialtypen). 

Zeitraum für Berechnung: erste Woche, zweite Woche, beide Wochen. 

Die Skalierung der X-Achse kann nach Tagen, Wochen oder Monaten gewählt werden.

Der Zeitraum kann belieblig zwischen Tagen, Monaten, Quartalen oder Geschäftsjahren gewählt werden (abhängig von der Verfügbarkeit der Daten). 

Als Ergebnis wird jeweils eine Vorschau angezeigt. In der Grafik können die einzelnen Werte via Mouse-Over sichtbar gemacht werden. Ebenso ist ein Excel- bzw. pdf-Download möglich.
`,
            },
            {
              element: '#tab-material',
              intro: `
Verbrauch des Materials:

Dieses Reporting zeigt für eine gewünschte Materialnummer den Verbrauch des Materials und den gleitenden Durchschnitt.

Die Skalierung der X-Achse kann nach Wochen, Monaten, Quartalen oder Jahren gewählt werden.

Die Zeitraum kann belieblig zwischen Tagen, Monaten, Quartalen oder Geschäftsjahren gewählt werden (abhängig von der Verfügbarkeit der Daten).
`,
            },
            {
              element: '#tab-material',
              intro: `
              Min/Max Auswertung:

              Dieses Reporting zeigt für einen gewünschten Lieferanten oder Disponenten eine Übersicht über die aktuellen Min/Max Einstellungen sowie nötige Anpassungen:
              OK - keine Veränderung notwendig (opt. Min/Max liegt innerhalb der angegebenen Toleranzen)
              Prüfen - Max liegt außerhalb der angegebenen Toleranzen
              Anpassen - Min liegt außerhalb der angegebenen Toleranzen
              
              Im Kriterienbereich können die Werte für eine optimale Rechweite sowie Toleranzen wenn nötig angepasst werden. 
`,
            },
          ]}
          initialStep={0}
          onExit={this.onIntroExit}
        />
        <AppBar position="static" color="secondary">
          <Tabs value={selectedReportCategory} onChange={this.handleSelect}>
            {reportCategories
              .filter(reportCategory => reportCategory.reports.length > 0)
              .map(reportCategory => (
                <Tab
                  id={`tab-${reportCategory.id}`}
                  key={`tab-${reportCategory.id}`}
                  value={reportCategory.id}
                  label={reportCategory.label}
                />
              ))}
          </Tabs>
        </AppBar>
        {selectedReportCategory ? (
          <div className={classes.container}>
            <div className={classes.configsContainer}>
              <ConfigCard
                id="type-config"
                title="Art des Reports"
                expanded={store.ui.showType}
                onToggle={store.ui.toggleShowType}
                visible
              >
                <TypeConfig />
              </ConfigCard>

              <ConfigCard
                id="filter-config"
                title="Filtering Kriterien"
                expanded={store.ui.showFilter}
                onToggle={store.ui.toggleShowFilter}
                visible={shouldConfigDisplay('filter')}
              >
                <FilterConfig availableFilters={selectedReport && (selectedReport.filters || [])} />
              </ConfigCard>

              <ConfigCard
                id="logic-config"
                title="Logik Kriterien"
                expanded={store.ui.showLogic}
                onToggle={store.ui.toggleShowLogic}
                visible={shouldConfigDisplay('logic')}
              >
                <LogicConfig />
              </ConfigCard>

              <ConfigCard
                id="min-max-config"
                title="Kriterien"
                expanded={store.ui.showMinMax}
                onToggle={store.ui.toggleShowMinMax}
                visible={shouldConfigDisplay('minMax')}
              >
                <MinMaxConfig />
              </ConfigCard>

              <ConfigCard
                id="diagram-config"
                title="Diagramm: Skalierung der x-Achse"
                expanded={store.ui.showDiagram}
                onToggle={store.ui.toggleShowDiagram}
                visible={shouldConfigDisplay('xAxisScaling')}
              >
                <DiagramConfig
                  axisScale={axisScale}
                  onAxisScaleChange={setAxisScale}
                  activeScalings={selectedReport && selectedReport.allowedScaling ? selectedReport.allowedScaling.peek() : []}
                />
              </ConfigCard>

              <ConfigCard
                id="range-config"
                title="Zeitraum auswählen"
                expanded={store.ui.showRange}
                onToggle={store.ui.toggleShowRange}
                visible={shouldConfigDisplay('timePeriod')}
              >
                <RangeConfig
                  rangeMode={rangeMode}
                  onModeChange={setRangeMode}
                  startDate={startDate}
                  endDate={endDate}
                  onStartDateChange={setStartDate}
                  onEndDateChange={setEndDate}
                  earliestStartDate={info.earliest_start_date}
                  latestEndDate={latestEnd}
                />
              </ConfigCard>

              <ConfigCard
                id="material-config"
                title="Material Nummer"
                expanded={store.ui.showExtended}
                onToggle={store.ui.toggleShowExtended}
                visible={shouldConfigDisplay('materialNumber')}
              >
                <MaterialConfig />
              </ConfigCard>

              <ConfigCard
                id="extended-config"
                title="Erweiterte Konfiguration (optional)"
                expanded={store.ui.showExtended}
                onToggle={store.ui.toggleShowExtended}
                visible={shouldConfigDisplay('extended')}
              >
                <ExtendedConfig
                  disableMissingPartTrafficLights={store.reporting.disableMissingPartTrafficLights}
                  expectedMissingPartTrafficLights={store.reporting.expectedMissingPartTrafficLights}
                  onExpectedMissingPartTrafficLightsChange={store.reporting.setExpectedMissingPartTrafficLights}
                />
              </ConfigCard>
            </div>
            <div
              className={classNames(classes.buttonWrapper, {
                [classes.hidden]: !relevantConfigs.every(configWithIdComplete),
              })}
            >
              <Button
                variant="outlined"
                color="secondary"
                onClick={selectedReport && selectedReport.reportType === 'table' ? getReport : updatePreviewUrl}
                aria-label={previewUrl || report ? 'Report aktualisieren' : 'Report erstellen'}
              >
                {previewUrl || report ? 'Report aktualisieren' : 'Report erstellen'}
              </Button>
            </div>

            {
              (selectedReport && (previewUrl || report || loading || previewLoading)) && (
                <ReportingSection
                  title="Vorschau des Reports"
                  loading={loading}
                  action={selectedReport.reportType === 'table'
                    ? (
                      <IconButton
                        color="secondary"
                        onClick={selectedReport.id === 'list-of-filtered-materials'
                          ? () => exportMaterialListTable(
                            report,
                            selectedReport,
                            tableFilter,
                            startDate,
                            endDate,
                            tableReportables,
                            selectedReport.reportables,
                          )
                          : () => exportMinMaxTable(
                            report,
                          )}
                        aria-label="Liste exportieren"
                      >
                        <SaveIcon />
                      </IconButton>
                    ) : (
                      <a href={pdfUrl} target="_blank" rel="noopener noreferrer">
                        <IconButton
                          color="secondary"
                          aria-label="Report exportieren"
                        >
                          <SaveIcon />
                        </IconButton>
                      </a>
                    )}
                >
                  {selectedReport.reportType === 'table' ? (
                    <>
                      {report && (
                        <ReportPreview
                          report={report}
                          title={selectedReport.label}
                        >
                          <>
                            {
                              report.warning && report.warning.map((warning) => (
                                <Typography color="error" key={warning} variant="body2">
                                  {warning}
                                </Typography>
                              ))
                            }
                            {selectedReport.id === 'list-of-filtered-materials' && (
                            <MaterialListReport />
                            )}
                            {selectedReport.id === 'min-max-report' && (
                            <MinMaxReport />
                            )}
                          </>
                        </ReportPreview>
                      )}
                    </>
                  ) : (
                    <>
                      {previewLoading && (
                        <div className={classes.loadingOverlay}>
                          <CircularProgress />
                        </div>
                      )}
                      <iframe
                        className={classNames(classes.previewFrame, {
                          [classes.previewLoading]: previewLoading,
                        })}
                        src={previewUrl}
                        title="report"
                        onLoad={previewLoadComplete}
                      />
                    </>
                  )}
                </ReportingSection>
              )
            }
          </div>
        ) : (
          <div className={classes.container}>
            <p>Bitte wählen Sie eine Kategorie</p>
          </div>
        )}
      </div>
    );
  }
}

ReportingContainer.propTypes = {
  store: MobxPropTypes.objectOrObservableObject,
  classes: PropTypes.object,
};

const styles = (theme) => ({
  previewFrame: {
    width: '100%',
    height: 995,
    border: 'none',
  },
  previewLoading: {
    height: '0 !important',
  },
  loadingOverlay: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: 'rgba(255, 255, 255, 0.5)',
    zIndex: 100,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  outerContainer: {
    margin: `0 ${theme.spacing(3)}px`,
    display: 'flex',
    height: '100%',
    flexDirection: 'column',
  },
  container: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'auto',
    backgroundColor: 'white',
    padding: theme.spacing(3),
  },
  configsContainer: {
    display: 'flex',
    flexDirection: 'column',
    '& > div': {
      width: '100%',
    },
  },
  buttonWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    padding: '1em',
  },
  hidden: {
    display: 'none',
  },
});

export default withStyles(styles)(ReportingContainer);
