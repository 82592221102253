function generateSupplierCodes() {
  const allSupplierCodes = [];
  for (let i = 0; i < 100; i += 1) {
    allSupplierCodes.push(`N${i.toString().padStart(2, '0')}`);
  }
  return allSupplierCodes;
}

export const allSupplierCodes = generateSupplierCodes().map(supplierCode => ({
  value: supplierCode,
  label: supplierCode,
}));
