import React from 'react';
import { DateTime } from 'luxon';
import { getNDaysMatchingFrom } from '../../utils/dateTools';
import ProjectCard from '../ProjectCard';

function renderDayProjects(props) {
  const {
    row,
  } = props;

  if (row) {
    return row.map(project => (
      <ProjectCard key={project.id} {...project} />
    ));
  }
  return null;
}

function getMakeColumns(showDay, startingDay, expanded) {
  const dayColumns = [];
  const columnWidth = expanded ? '9.09%' : '16.66%';

  dayColumns.push(
    {
      header: 'Überfällig',
      accessor: 'overdue',
      Cell: renderDayProjects,
      width: columnWidth,
    },
  );

  const today = DateTime.local().startOf('day');
  const daysToShow = expanded ? 10 : 5;
  dayColumns.push(...getNDaysMatchingFrom(
    startingDay,
    showDay,
    daysToShow,
  ).map((daysFromStart, columnNumber) => {
    const dayInQuestion = startingDay.plus({ days: daysFromStart });
    const header = (columnNumber === 0 && today.hasSame(startingDay, 'day'))
      ? 'Heute'
      : dayInQuestion.toFormat('EEE dd.MM', { locale: 'de' });
    return {
      header,
      width: columnWidth,
      accessor: `t_plus_${daysFromStart}`,
      Cell: renderDayProjects,
    };
  }));

  return dayColumns;
}

export {
  getMakeColumns,
};
