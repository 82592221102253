import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { PropTypes as MobxPropTypes, inject, observer } from 'mobx-react';
import { withRouter } from 'react-router';
import { withStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Switch, Route } from 'react-router-dom';

import Home from './Home';
import All from './All';
import Reporting from './Reporting';
import Header from '../../Components/Header';
import { ReactComponent as Logo } from '../../assets/VF_Logo.svg';

@inject('store')
@observer
class Dashboard extends Component {
  static propTypes = {
    store: MobxPropTypes.objectOrObservableObject,
    classes: PropTypes.object,
  };

  constructor(props) {
    super(props);

    if (!props.store.user.isLoggedIn || props.store.user.getTimeToTokenExpiry() < 0) {
      props.store.user.redirectToLogin();
    }
  }

  render() {
    const { classes, store } = this.props;

    if (store.user.redirecting) {
      return (
        <div className={classes.overlayContainer}>
          <Logo className={classes.logo} />
          <CircularProgress />
        </div>
      );
    }

    const {
      user,
    } = store.user;

    const consentDate = user && user.profile && user.profile.data_use_consent_date;

    return (
      <>
        <Modal
          open={!consentDate /* If policy ever changes, compare date of latest here */}
        >
          <Card className={classes.modal}>
            <CardContent>
              <Typography variant="body2">
                Hallo neuer Nutzer,
              </Typography>
              <br/>
              <br/>
              <Typography variant="body2">
                bevor Sie die Applikation zum Ersten Mal nutzen, müssen Sie Ihren Account beim Virtual Factory Support Team beantragen.
              </Typography>
              <br/>
              <Typography variant="body2">
                Hier bestätigen Sie, dass die Freigabe zur Nutzung der Applikation schriftlich (z.B. per E-Mail) vorliegt.
              </Typography>
              <br/>
              <Typography variant="body2">
                Virtual Factory Support Team: Andrea Brade, Felix Bischoff, Kai Froelich.
              </Typography>
              <br/>
              <br/>
              <Typography variant="body2">
                Vielen Dank und Grüße
              </Typography>
              <br/>
              <Typography variant="body2">
                Ihr Virtual Factory Team
              </Typography>
            </CardContent>
            <CardActions className={classes.acceptButtons}>
              <Button color="secondary" onClick={store.user.logout} aria-label="Ablehnen">Ablehnen</Button>
              <Button
                variant="contained"
                color="secondary"
                onClick={store.user.acceptDataUse}
                aria-label="Akzeptieren"
              >
                Akzeptieren
              </Button>
            </CardActions>
          </Card>
        </Modal>
        <div className={classes.container}>
          <Header />
          <div className={classes.content}>
            <Switch>
              <Route exact path="/" component={Home}/>
              <Route exact path="/all" component={All}/>
              <Route exact path="/reports" component={Reporting} />
            </Switch>
          </div>
        </div>
      </>
    );
  }
}

const styles = theme => ({
  content: {
    flex: '0 1 auto',
    height: 'inherit',
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'hidden',
  },
  container: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  overlayContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    flexDirection: 'column',
  },
  logo: {
    height: '100px',
    width: '100px',
    marginBottom: '100px',
    fill: theme.palette.secondary.main,
  },
  modal: {
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
  },
  acceptButtons: {
    display: 'flex',
    marginTop: 48,
    marginBottom: 12,
    justifyContent: 'space-around',
  },
});

export default withStyles(styles)(withRouter(Dashboard));
