import API from '../index';

async function updateProfile(newProfile, token) {
  try {
    const res = await API.patch(
      'auth/user/',
      JSON.stringify(newProfile),
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `JWT ${token}`,
        },
      },
    );

    return {
      value: res.data,
    };
  } catch (e) {
    return {
      error: e.response,
    };
  }
}

export { updateProfile };
