export default theme => ({
  container: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    height: 'calc(100% - 2em)',
  },
  fullHeight: {
    height: '100%',
  },
  controlRow: {
    height: 48,
    display: 'flex',
    justifyContent: 'space-between',
  },
  downloadSpacer: {
    width: 48,
    display: 'inline-block',
  },
  paper: {
    position: 'relative',
    height: 'calc(100% - 48px)',
    '& p': {
      maxWidth: '100%',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    '& h6': {
      opacity: 0.56,
      marginLeft: '2em',
    },
    '&>h6': {
      marginTop: '1em',
    },
    '& nav': {
      flex: 1,
      overflowY: 'scroll',
      '&>div>div:nth-child(1)': {
        '&>p': {
          color: theme.palette.primary.main,
        },
      },
      '&>div>div:nth-child(2)': {
        padding: 0,
      },
      '&>div>div:nth-child(3)': {
        '&>p': {
          color: theme.palette.primary.main,
        },
      },
      '&>div>div:nth-child(4)': {
        paddingRight: 0,
        '&>p': {
          color: theme.palette.secondary.main,
          textAlign: 'right',
        },
      },
      '& p': {
        whiteSpace: 'nowrap',
      },
      '&::-webkit-scrollbar': {
        width: 6,
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: '#ABABAB',
        '&:hover': {
          backgroundColor: '#616161',
        },
      },
    },
  },
  listItem: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  overlay: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  loadingOverlay: {
    backgroundColor: 'rgba(255, 255, 255, 0.5)',
    zIndex: 100,
  },
  longTextCell: {
    '& p': {
      padding: '5px',
      whiteSpace: 'unset !important',
      overflow: 'unset !important',
    },
  },
  deltaQuantity: {
    display: 'flex',
  },
  deltaQuantityValue: {
    fontSize: '0.8em',
    marginLeft: '0.5em',
  },
});
