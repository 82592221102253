import gql from 'graphql-tag';
import { downloadUrl } from '../../Api/index';

export const getTemplateUrl = () => `${downloadUrl}/delivery/sheets/template.xlsx`;

export const GetSuppliers = gql`
  query {
    allSuppliers {
      edges {
        node {
          pk
          name
        }
      }
    }
  }
`;

export const DeleteSheetUpload = gql`
  mutation DeleteSheetUpload(
    $pk: Int,
  ) {
    deleteDeliverySheetUpload(pk: $pk) {
      deliverySheetUpload {
        pk
      }
    }
  }
`;
