import { createMuiTheme } from '@material-ui/core/styles';

const themeObject = createMuiTheme({
  palette: {
    type: 'light',
    background: {
      default: '#fafafa',
    },
    primary: {
      main: '#000000',
    },
    secondary: {
      main: '#54b2b4',
      contrastText: '#fff',
    },
  },
  typography: {
    fontFamily: ['"Calibri"', 'sans-serif'].join(','),
    useNextVariants: true,
  },
});

const theme = createMuiTheme(themeObject);

export default theme;
