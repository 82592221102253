import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Modal from '@material-ui/core/Modal';
import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';
import {
  List, ListItem, ListItemText, Typography,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

class ErrorOverviewModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
    };
  }

  render() {
    const {
      visible,
      onCancel,
      classes,
      materialErrors,
      projectErrors,
    } = this.props;
    return (
      <Modal
        open={!!visible && (materialErrors.length > 0 || projectErrors.length > 0)}
        onClose={onCancel}
        disableBackdropClick
      >
        <div className={classes.paper}>
          <IconButton
            className={classes.closeButton}
            onClick={onCancel}
            aria-label="Schließen"
          >
            <ClearIcon />
          </IconButton>
          <div className={classes.flexContainer}>
            {materialErrors.length > 0 && (
              <>
                <Typography variant="h6" color="secondary">
                  Fehlerhafte Materialnummern
                </Typography>
                <List dense className={classes.scrollContainer}>
                  {materialErrors.map((error) => (
                    <ListItem key={error}>
                      <ListItemText primary={error} />
                    </ListItem>
                  ))}
                </List>
              </>
            )}
            {projectErrors.length > 0 && (
              <>
                <Typography variant="h6" color="secondary">
                  Fehlerhafte Projektnummern
                </Typography>
                <List dense className={classes.scrollContainer}>
                  {projectErrors.map((error) => (
                    <ListItem key={error}>
                      <ListItemText primary={error} />
                    </ListItem>
                  ))}
                </List>
              </>
            )}
          </div>
        </div>
      </Modal>
    );
  }
}

ErrorOverviewModal.propTypes = {
  visible: PropTypes.bool,
  onCancel: PropTypes.func,
  classes: PropTypes.object,
  materialErrors: PropTypes.array,
  projectErrors: PropTypes.array,
};

const styles = (theme) => ({
  paper: {
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    position: 'absolute',
    width: '50%',
    height: '80%',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4),
    outline: 'none',
  },
  closeButton: {
    position: 'absolute',
    top: 0,
    right: 0,
    padding: '6px',
    zIndex: 1,
  },
  scrollContainer: {
    flexBasis: '50%',
    flexGrow: 1,
    overflow: 'auto',
    minHeight: '0px',
  },
  flexContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
});


export default withStyles(styles)(ErrorOverviewModal);
