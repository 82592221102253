import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {
  Typography,
  Tooltip,
} from '@material-ui/core';
import TableCell from '../TableCell';

function TextCell(props) {
  const {
    classes,
    value,
    prependIcon,
    showPrependIcon,
    appendIcon,
    showAppendIcon,
    tooltip,
  } = props;

  const content = (
    <Typography variant="body2" className={classes.text}>
      {value}
    </Typography>
  );

  const wrappedContent = tooltip ? (
    <Tooltip title={tooltip}>
      {content}
    </Tooltip>
  ) : content;

  if (typeof value !== 'undefined' || value !== null) {
    return (
      <TableCell>
        {prependIcon && (
          <div className={classes.statusPlaceholder}>
            {showPrependIcon && prependIcon}
          </div>
        )}
        {wrappedContent}
        {appendIcon && (
          <div className={classes.appendPlaceholder}>
            {showAppendIcon && appendIcon}
          </div>
        )}
      </TableCell>
    );
  }
  return null;
}

TextCell.propTypes = {
  classes: PropTypes.object,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  prependIcon: PropTypes.object,
  showPrependIcon: PropTypes.bool,
  appendIcon: PropTypes.object,
  showAppendIcon: PropTypes.bool,
  tooltip: PropTypes.string,
};

const style = () => ({
  statusPlaceholder: {
    width: 20,
    display: 'flex',
    alignItems: 'center',
    fontSize: 12,
  },
  text: {
    fontSize: '16px',
    textAlign: 'left',
  },
  appendPlaceholder: {
    position: 'absolute',
    right: 0,
    top: 8,
    width: '1.5rem',
    height: '1.5rem',
  },
});

export default withStyles(style)(TextCell);
