import React from 'react';
import PropTypes from 'prop-types';
import { DatePicker } from '@material-ui/pickers';
import { withStyles } from '@material-ui/core';
import MaterialFilter from './MaterialFilter';
import NumberRefFilter from './NumberRefFilter';

const FilterForm = ({
  filterObject,
  changeFilter,
  data,
  classes,
}) => {
  const {
    materialNumber,
    productionOrderNumber,
    salesOrderNumber,
    omlStart,
  } = filterObject;

  const handleChange = field => newValue => changeFilter(field, newValue || []);

  const handleFromChange = newValue => changeFilter('omlStart', {
    ...filterObject.omlStart,
    from: newValue,
  });

  const handleToChange = newValue => changeFilter('omlStart', {
    ...filterObject.omlStart,
    to: newValue,
  });

  return (
    <form autoComplete="off" className={classes.form}>
      <div className={classes.row}>
        <div className={classes.column}>
          <MaterialFilter
            value={materialNumber}
            onChange={handleChange('materialNumber')}
            data={data}
          />
        </div>
        <div className={classes.column}>
          <NumberRefFilter
            value={productionOrderNumber}
            onChange={handleChange('productionOrderNumber')}
            data={data}
            field="productionOrderNumber"
            label="FAUF"
          />
        </div>
        <div className={classes.column}>
          <NumberRefFilter
            value={salesOrderNumber}
            onChange={handleChange('salesOrderNumber')}
            data={data}
            field="salesOrderNumber"
            label="KAUF"
          />
        </div>
      </div>
      <span>OML Start</span>
      <div className={classes.row}>
        <div className={classes.column}>
          <DatePicker
            autoOk
            id="omlFrom"
            label="From"
            value={omlStart.from}
            onChange={handleFromChange}
          />
        </div>
        <div className={classes.column}>
          <DatePicker
            autoOk
            id="omlTo"
            label="To"
            value={omlStart.to}
            onChange={handleToChange}
          />
        </div>
      </div>
    </form>
  );
};

FilterForm.propTypes = {
  filterObject: PropTypes.object,
  changeFilter: PropTypes.func,
  data: PropTypes.array,
  classes: PropTypes.object,
};

const styles = () => ({
  form: {
    display: 'flex',
    width: '100%',
    color: 'white',
    flexDirection: 'column',
  },
  row: {
    display: 'flex',
    marginBottom: '30px',
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    '&:not(:last-child)': {
      marginRight: '30px',
    },
  },
});

export default withStyles(styles)(FilterForm);
