import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject, observer, PropTypes as MobxPropTypes } from 'mobx-react';
import { withStyles } from '@material-ui/core/styles';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import SettingsIcon from '@material-ui/icons/Settings';
import {
  Button,
  Typography,
} from '@material-ui/core';

import { baseUrl } from '../../../Api/index';
import CreateDispoProfile from './CreateDispoProfile';
import ManageDispoProfile from './ManageDispoProfile';

export const goToAdmin = () => {
  window.open(`${baseUrl}/admin/`, '_blank');
};

@inject('store')
@observer
class Account extends Component {
  state = {
    mode: 'create',
  }

  render() {
    const {
      classes,
      store,
    } = this.props;

    const {
      username,
      email,
    } = store.user.user;

    const {
      mode,
    } = this.state;

    return (
      <div className={classes.container}>
        <div className={classes.profileSection}>
          <Typography variant="h6">{email || username}</Typography>
          <div className={classes.accountButtons}>
            <Button
              onClick={store.user.logout}
              startIcon={<PowerSettingsNewIcon />}
              className={classes.profileButton}
              aria-label="Logout"
            >
              Logout
            </Button>
            {store.user.user.is_superuser && (
              <Button
                onClick={goToAdmin}
                startIcon={<SettingsIcon />}
                className={classes.profileButton}
                aria-label="Admin"
              >
                Admin
              </Button>
            )}
          </div>
        </div>
        <div className={classes.disposSection}>
          <Typography variant="h6">Gespeicherte Dispos</Typography>
          <div className={classes.dispoContent}>
            <div className={classes.accountButtons}>
              <Button
                className={mode === 'create' ? classes.activeMode : ''}
                onClick={() => {
                  this.setState({
                    mode: 'create',
                  });
                }}
                aria-label="Erstellen"
              >
                Erstellen
              </Button>
              <Button
                className={mode === 'manage' ? classes.activeMode : ''}
                onClick={() => {
                  this.setState({
                    mode: 'manage',
                  });
                }}
                aria-label="Verwalten"
              >
                Verwalten
              </Button>
            </div>
            {mode === 'create' && (
              <CreateDispoProfile />
            )}
            {mode === 'manage' && (
              <ManageDispoProfile />
            )}
          </div>
        </div>
      </div>
    );
  }
}

Account.propTypes = {
  classes: PropTypes.object,
  store: MobxPropTypes.objectOrObservableObject,
};

const style = () => ({
  container: {
    display: 'flex',
    width: '100%',
  },
  profileSection: {
    flex: 1,
  },
  profileButton: {
    justifyContent: 'left',
  },
  accountButtons: {
    display: 'flex',
    flexDirection: 'column',
    width: '150px',
  },
  activeMode: {
    fontWeight: '900',
  },
  disposSection: {
    flex: 2,
  },
  dispoContent: {
    display: 'flex',
  },
});

export default withStyles(style)(Account);
