import gql from 'graphql-tag';

export const updateExpectedMissingPartDetails = gql`
  mutation UpdateExpectedMissingPartDetails(
    $materialNumber: String,
    $salesOrderNumber: String,
    $remark: String,
  ) {
    updateExpectedMissingPartDetails(
      materialNumber: $materialNumber,
      salesOrderNumber: $salesOrderNumber,
      remark: $remark,
    ) {
      expectedMissingPartDetails {
        materialNumber
        salesOrderNumber
        remark
      }
    }
  }
`;
