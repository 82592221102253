export const style = theme => ({
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    width: '400px',
    margin: theme.spacing(1),
  },
  formControl: {
    minWidth: 120,
  },
  list: {
    overflowY: 'auto',
    height: '244px',
  },
  listItem: {
    paddingTop: '5px',
    paddingBottom: '5px',
  },
  itemIcon: {
    minWidth: '40px',
    '& button': {
      padding: '2px',
    },
  },
  historyLine: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  buttons: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  button: {
    margin: theme.spacing(1),
  },
  buttonIcon: {
    marginRight: theme.spacing(1),
  },
  input: {
    display: 'none',
  },
  fileDrop: {
    width: '100%',
    height: 100,
    cursor: 'pointer',
    '&& .file-drop': {
      position: 'relative',
      height: '100%',
      width: '100%',
      '& > .file-drop-target': {
        position: 'absolute',
        top: 0,
        left: 0,
        height: '100%',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'rgba(255, 255, 255, 0.08)',
        border: '1px dashed white',
        '&.file-drop-dragging-over-frame': {
          backgroundColor: 'rgba(255, 255, 255, 0.5)',
          border: '1px solid white',
        },
      },
    },
  },
});
