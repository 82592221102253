import React, { Component } from 'react';
import { inject, observer, PropTypes as MobxPropTypes } from 'mobx-react';
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@material-ui/core';
import XLSX from 'xlsx';
import { DateTime } from 'luxon';
import { trimLead0s } from '../../../utils/displayUtils';

const COLUMNS = [
  {
    label: 'Material Nummer',
    accessor: 'material_number',
    trim: true,
  },
  {
    label: 'Bezeichnung',
    accessor: 'description',
  },
  {
    label: 'Lieferant',
    accessor: 'supplier_name',
  },
  {
    label: 'Dispo',
    accessor: 'supplier_code',
  },
  {
    label: 'ABC Klassifikation',
    accessor: 'abc_classification',
  },
  {
    label: 'Min',
    accessor: 'min_storage_quantity',
  },
  {
    label: 'Max',
    accessor: 'max_storage_quantity',
  },
  {
    label: 'Opt. Min',
    accessor: 'opt_min',
  },
  {
    label: 'Opt. Max',
    accessor: 'opt_max',
  },
  {
    label: 'Kategorie',
    accessor: 'action',
  },
  {
    label: 'Basierend auf',
    accessor: 'used',
  },
];

@inject('store')
@observer
class MinMaxReport extends Component {
  render() {
    const {
      report,
      loading,
    } = this.props.store.reporting;

    if (loading) {
      return null;
    }

    return (
      <Table>
        <TableHead>
          <TableRow>
            {COLUMNS.map(column => (
              <TableCell key={column.accessor}>{column.label}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {report.map(row => (
            <TableRow key={row.key}>
              {COLUMNS.map(column => (
                <TableCell key={column.accessor}>
                  {column.trim ? trimLead0s(row[column.accessor]) : row[column.accessor]}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    );
  }
}

MinMaxReport.propTypes = {
  store: MobxPropTypes.objectOrObservableObject,
};

export const exportTable = (
  report,
) => {
  const headers = COLUMNS.map(column => column.label);
  const body = report.map(row => [
    ...COLUMNS.map(column => {
      if (column.trim) {
        return trimLead0s(row[column.accessor]);
      }
      return row[column.accessor];
    }),
  ]);

  const xlsxFormat = [
    headers,
    ...body,
  ];

  const worksheet = XLSX.utils.aoa_to_sheet(xlsxFormat);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, 'SheetJS');
  XLSX.writeFile(workbook, `${DateTime.local().toFormat('yyyyMMddHHmm')}-Min-Max-Report.xlsx`);
};

export default MinMaxReport;
