import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject, observer, PropTypes as MobxPropTypes } from 'mobx-react';
import { withStyles } from '@material-ui/core/styles';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import Typography from '@material-ui/core/Typography';
import classnames from 'classnames';
import { reformatIsoDate } from '../../../utils/dateTools';


@inject('store')
@observer
class DeliveryCard extends Component {
  static propTypes = {
    classes: PropTypes.object,
    value: PropTypes.number,
    source: PropTypes.string,
    status: PropTypes.string,
    deliveryDate: PropTypes.string,
    store: MobxPropTypes.objectOrObservableObject,
  }

  render() {
    const {
      classes,
      value,
      source,
      status,
      deliveryDate,
      store,
    } = this.props;

    const {
      permissions,
    } = store.user.user;

    if (!value) {
      return (
        <div id="add-delivery" className={classes.addIconCell}>
          {permissions.includes('delivery.add_deliveryline') && (
            <AddCircleIcon/>
          )}
        </div>
      );
    }

    return (
      <div className={classnames(classes.card, classes[status])}>
        <div className={classes.row}>
          <Typography className={classes.key}>Menge</Typography>
          <Typography className={classes.value}>{value}</Typography>
        </div>
        { Boolean(deliveryDate) && (
          <div className={classes.row}>

            <Typography className={classes.key}>
              Lieferdatum
            </Typography>
            <Typography className={classes.value}>{reformatIsoDate(deliveryDate)}</Typography>
          </div>
        )}
        <div className={classes.row}>

          <Typography className={classes.key}>
            Quelle
          </Typography>
          <Typography className={classes.value}>
            {source}
          </Typography>
        </div>
      </div>
    );
  }
}

const style = () => ({
  card: {
    height: '100%',
    width: '100%',
    minHeight: '64px',
    padding: 2,
    position: 'relative',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },
  red: {
    border: '2px solid rgba(228, 66, 88, 0.33)',
  },
  yellow: {
    border: '2px solid rgba(245, 225, 20, 0.33)',
  },
  green: {
    border: '2px solid rgba(0, 166, 94, 0.2)',
  },
  addIconCell: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '64px',
    height: '100%',
    color: 'rgba(191, 191, 191, 1)',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.08)',
    },
  },
  value: {
    fontSize: '12px',
  },
  key: {
    fontSize: '12px',
    fontWeight: 'bold',
  },
});

export default withStyles(style)(DeliveryCard);
