import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { DateTime } from 'luxon';
import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { withSnackbar } from 'notistack';
import NewDeliveryForm from './NewDeliveryForm';

export const getDefaultDate = (selectedDay, startingDay) => {
  if (selectedDay === 'nextDelivery') return DateTime.local().startOf('day');

  const dayOffset = selectedDay ? parseInt(selectedDay.match(/\d+/)[0], 10) : undefined;
  return (dayOffset !== undefined)
    ? startingDay.plus({ days: dayOffset })
    : startingDay;
};

class NewDeliveryModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedMaterial: '',
      deliveryDate: null,
      orderNumberInput: '',
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.visible && !prevProps.visible) {
      this.setupDefaultValues();
    }

    if (prevProps.initialMaterial !== this.props.initialMaterial) {
      this.prefillSelectedMaterial(this.props.initialMaterial);
    }
  }

  prefillSelectedMaterial = (selectedMaterial) => {
    this.setState({
      selectedMaterial,
    });
  }


  setupDefaultValues = () => {
    const {
      initialMaterial,
      selectedDay,
      startingDay,
    } = this.props;

    this.setState({
      selectedMaterial: initialMaterial,
      numberOfMaterialsInput: '',
      deliveryDate: getDefaultDate(selectedDay, startingDay),
    });
  }

  handleMaterialInputChange = (material) => {
    this.setState({
      selectedMaterial: material.value,
    });
  }

  handleOrderNumberChange = (event) => {
    this.setState({
      orderNumberInput: event.target.value,
    });
  }

  handleNumberOfMaterialsChange = (event) => {
    this.setState({
      numberOfMaterialsInput: event.target.value,
    });
  }

  handleUpdateClick = () => {
    const {
      selectedMaterial,
      orderNumberInput,
      numberOfMaterialsInput,
      deliveryDate,
    } = this.state;

    this.props.onUpdate({
      selectedMaterial,
      orderNumberInput,
      numberOfMaterialsInput,
      deliveryDate,
    });
  }

  onDateChange = (date) => {
    this.setState({
      deliveryDate: date,
    });
  }

  isValid = () => {
    const {
      selectedMaterial,
      deliveryDate,
      numberOfMaterialsInput,
      orderNumberInput,
    } = this.state;

    return selectedMaterial && deliveryDate && numberOfMaterialsInput && orderNumberInput;
  }

  render() {
    const {
      visible,
      onCancel,
      classes,
    } = this.props;

    const {
      orderNumberInput,
      numberOfMaterialsInput,
      deliveryDate,
      selectedMaterial,
    } = this.state;

    return (
      <Modal
        open={!!visible}
        onClose={onCancel}
        disableBackdropClick
      >
        <div className={classes.paper}>
          <Typography variant="h6">Lieferung</Typography>
          <NewDeliveryForm
            numberOfMaterialsInput={numberOfMaterialsInput}
            deliveryDate={deliveryDate}
            orderNumberInput={orderNumberInput}
            onOrderNumberChange={this.handleOrderNumberChange}
            onMaterialInputChange={this.handleMaterialInputChange}
            onNumberOfMaterialsChange={this.handleNumberOfMaterialsChange}
            onDateChange={this.onDateChange}
            selectedMaterial={selectedMaterial}
          />
          <div className={classes.buttons}>
            <Button onClick={onCancel} aria-label="Abbrechen">CANCEL</Button>
            <Button
              onClick={this.handleUpdateClick}
              color="primary"
              disabled={!this.isValid()}
              aria-label="Bestätigen"
            >
              OK
            </Button>
          </div>
        </div>
      </Modal>
    );
  }
}

NewDeliveryModal.propTypes = {
  visible: PropTypes.bool,
  onCancel: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  initialMaterial: PropTypes.string,
  selectedDay: PropTypes.string,
  classes: PropTypes.object,
  startingDay: PropTypes.object,
};

const styles = theme => ({
  paper: {
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    position: 'absolute',
    width: theme.spacing(50),
    backgroundColor: theme.palette.background.paper,
    boxShoadow: theme.shadows[5],
    padding: theme.spacing(4),
    outline: 'none',
  },
  buttons: {
    display: 'flex',
    justifyContent: 'end',
  },
});

export default withStyles(styles)(withSnackbar(NewDeliveryModal));
