import React, { useState } from 'react';
import PropTypes from 'prop-types';
import FilterListIcon from '@material-ui/icons/FilterList';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { Button, Chip, IconButton } from '@material-ui/core';
import { withStyles, MuiThemeProvider } from '@material-ui/core/styles';
import classNames from 'classnames';
import { DateTime } from 'luxon';

import lightTheme from '../../theme';
import darkTheme from '../../alternateTheme';

const filterFieldHeadings = {
  materialNumber: 'Material',
  status: 'Ampel',
  supplierCode: 'Dispo',
  supplierNumber: 'Lieferant',
  materialGroup: 'Material Kategorie',
  faufKauf: 'FAUF/KAUF',
  productionOrderNumber: 'FAUF',
  salesOrderNumber: 'KAUF',
  omlStart: 'OML Start',
};

const FilterRow = ({
  classes,
  filterObject,
  handleReset,
  filterForm,
  handleDeleteFilterLine,
  auxiliaryInfo,
}) => {
  const [isOpen, setOpen] = useState(false);

  const filterClasses = Object.keys(filterObject);
  const allFilters = filterClasses.reduce((filters, filterClass) => {
    const thisFilter = filterObject[filterClass];
    if (thisFilter) {
      if (thisFilter.dateFilter) {
        // is a date range
        if (thisFilter.from && thisFilter.to) {
          return [
            ...filters,
            {
              key: filterClass,
              isAuxiliary: false,
              label: (
                <>
                  {`${filterFieldHeadings[filterClass]} - `}
                  <span className={classes.chipTextValue}>
                    {`${thisFilter.from.toLocaleString(DateTime.DATE_SHORT)} - ${thisFilter.to.toLocaleString(DateTime.DATE_SHORT)}`}
                  </span>
                </>
              ),
            },
          ];
        }
        return filters;
      }
      // is a list of references
      return [
        ...filters,
        ...thisFilter.map((filterValue) => ({
          key: `${filterClass}.${filterValue.value}`,
          isAuxiliary: false,
          label: (
            <>
              {`${filterFieldHeadings[filterClass]} - `}
              <span className={classes.chipTextValue}>
                {filterValue.label}
              </span>
            </>
          ),
        })),
      ];
    }
    return filters;
  }, []);

  const auxiliaryClasses = auxiliaryInfo ? Object.keys(auxiliaryInfo) : [];
  const allAuxiliaries = auxiliaryClasses.reduce((auxiliaries, auxiliaryClass) => [
    ...auxiliaries,
    ...auxiliaryInfo[auxiliaryClass].map((auxiliaryValue) => ({
      key: `aux-${auxiliaryClass}.${auxiliaryValue.value}`,
      isAuxiliary: true,
      label: (
        <>
          {`${filterFieldHeadings[auxiliaryClass]} - `}
          <span className={classes.chipTextValue}>
            {auxiliaryValue.label}
          </span>
        </>
      ),
      onRemove: auxiliaryValue.onRemove,
      exclusive: auxiliaryValue.exclusive,
    })),
  ], []);

  const allChips = [
    ...allAuxiliaries,
    ...allFilters,
  ];

  const toggleOpen = () => setOpen(!isOpen);

  const handleDelete = (key) => () => handleDeleteFilterLine(key);

  const exclusiveFilterInPlace = allAuxiliaries.some(aux => aux.exclusive);

  return (
    <MuiThemeProvider theme={isOpen ? darkTheme : lightTheme}>
      <span className={`${classNames(classes.container, {
        [classes.openContainer]: isOpen,
      })} filter`}
      >
        <span className={classes.topRow}>
          <IconButton onClick={toggleOpen} aria-label={isOpen ? 'Schließen' : 'Filter öffnen'}>
            {isOpen ? (
              <ArrowBackIcon color="secondary" />
            ) : (
              <FilterListIcon color="secondary" />
            )}
          </IconButton>
          <span className={classes.filterList}>
            {allChips.map((filter) => (
              <Chip
                key={filter.key}
                className={classNames(classes.chip, {
                  [classes.auxiliary]: filter.isAuxiliary,
                  [classes.overruled]: exclusiveFilterInPlace && !filter.isAuxiliary,
                })}
                label={<span className={classes.chipContent}>{filter.label}</span>}
                onDelete={filter.isAuxiliary ? filter.onRemove : handleDelete(filter.key)}
              />
            ))}
          </span>
          <Button color="secondary" onClick={handleReset} aria-label="Zurücksetzen">Reset</Button>
        </span>
        {isOpen && (
          <div className={classes.formContainer}>
            {filterForm}
          </div>
        )}
      </span>
    </MuiThemeProvider>
  );
};

FilterRow.propTypes = {
  classes: PropTypes.object,
  filterObject: PropTypes.object,
  handleReset: PropTypes.func,
  filterForm: PropTypes.object,
  handleDeleteFilterLine: PropTypes.func,
  auxiliaryInfo: PropTypes.object,
};

const style = (theme) => ({
  chip: {
    margin: '2px 2px',
    backgroundColor: 'rgb(236, 236, 236)',
    color: 'black',
    '& > svg': {
      color: 'rgba(0, 0, 0, 0.26)',
    },
  },
  auxiliary: {
    border: '2px solid grey',
  },
  overruled: {
    opacity: 0.5,
  },
  container: {
    transition: 'min-height 500ms, background-color 500ms',
  },
  chipContent: {
    display: 'flex',
  },
  chipTextValue: {
    maxWidth: '200px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    marginLeft: '3px',
  },
  openContainer: {
    transition: 'min-height 500ms, background-color 500ms',
    height: '100%',
    minHeight: '100%',
    backgroundColor: theme.palette.secondary.main,
  },
  filterList: {
    width: '100%',
    flex: 1,
  },
  topRow: {
    display: 'flex',
    borderBottom: '1px solid lightgrey',
    alignItems: 'center',
  },
  formContainer: {
    padding: '10px 30px',
  },
});

export default withStyles(style)(FilterRow);
