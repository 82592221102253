import gql from 'graphql-tag';

const materialFields = `
  materialNumber
  hasReliableSupplier
  materialGroup {
    name
  }
  description
  trafficLight
  supplierCode
  supplierNumber
  supplierName
  stockQuantity
  demandQuantity
  storageTypes
  minStorageQuantities
  maxStorageQuantities
  demandForecast {
    date
    quantity
  }
  stockForecast {
    date
    quantity
  }
  reservationFilled
  deliveryLines {
    edges {
      node {
        pk
        supplierName
        createdDate
        deliveryDate
        quantity
        source
        overdue
        trafficLight
        poNumber
      }
    }
  }
  isMissingPart
  abcClassification
  materialRange
`;

export const SOURCE_DATA = gql`
  query materialsFromAllMaterials(
    $after: String,
    $first: Int,
    $materialNumber_In: String
    $supplierNumber_In: String
    $trafficLight_In: String
    $supplierCode_In: String
    $materialGroup_In: String
    $orderBy: String
    $location: String
  ) {
    allMaterials(
      after: $after,
      first: $first,
      materialNumber_In: $materialNumber_In,
      supplierNumber_In: $supplierNumber_In,
      trafficLight_In: $trafficLight_In,
      supplierCode_In: $supplierCode_In,
      materialGroup_In: $materialGroup_In,
      orderBy: $orderBy,
      location: $location,
    ) {
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        node {
          ${materialFields}
        }
      }
    }
  }
`;


export const MATERIALS_FROM_PROJECT = gql`
  query MaterialsFromProject(
    $salesOrderNumber: String,
    $positionNumber: Int,
    $after: String,
    $first: Int,
    $location: String
  ) {
    allProjects(
      salesOrderNumber: $salesOrderNumber
      positionNumber: $positionNumber
      location: $location
    ) {
      edges {
        node {
          materials (
            after: $after,
            first: $first,
          ) {
            pageInfo {
              endCursor
              hasNextPage
            }
            edges {
              node {
                ${materialFields}
              }
            }
          }
        }
      }
    }
  }
`;

export const AVAILABLE_MATERIALS = gql`
  query availableMaterialsFromAllMaterials(
    $after: String,
    $first: Int,
    $deliveryLineQuantity: Float,
    $supplierCode_In: String,
    $orderBy: String,
    $location: String
  ) {
    allMaterials(
      after: $after,
      first: $first,
      deliveryLineQuantity: $deliveryLineQuantity,
      supplierCode_In: $supplierCode_In,
      orderBy: $orderBy,
      location: $location,
    ) {
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        node {
          materialNumber
          supplierCode
          deliveryLines {
            edges {
              node {
                pk
                supplierName
                createdDate
                deliveryDate
                quantity
                source
                overdue
                trafficLight
                poNumber
              }
            }
          }
        }
      }
    }
  }
`;

export const UPCOMING_PROJECTS = gql`
  query UpcomingProjectsQuery (
    $beforeDate: DateTime,
    $location: String
  ){
    allProjects(
      omlStart_Lt: $beforeDate
      location: $location
    ){
      edges {
        node {
          called
          id
          salesOrderNumber
          productionOrderNumber
          positionNumber
          systemName
          systemGroup
          customerName
          customerCountryCode
          omlStart
          omlEnd
          callDate
          trafficLight
          overdue
          priority
          hasMissingPart
          completed
          stats {
            expectedMissingParts {
              uniqueMaterials
            }
          }
          location
        }
      }
    }
  }
`;

export const PROJECTS_WITH_MATERIAL = gql`
  query projectsFromMaterial (
    $materialNumber: String,
  ){
    material(
      materialNumber: $materialNumber
    ){
      projects {
        edges {
          node {
            called
            id
            salesOrderNumber
            productionOrderNumber
            positionNumber
            systemName
            systemGroup
            customerName
            customerCountryCode
            omlStart
            omlEnd
            callDate
            trafficLight
            overdue
            priority
            hasMissingPart
            completed
            stats {
              expectedMissingParts {
                uniqueMaterials
              }
            }
            assignment {
              openDemandFulfillableFromStockOrDeliveries
              openDemandFulfillableFromStock
              openDemandQuantity
            }
          }
        }
      }
    }
  }
`;
