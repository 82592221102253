import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject, observer, PropTypes as MobxPropTypes } from 'mobx-react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import HeartIcon from '@material-ui/icons/FavoriteBorder';
import SvgIcon from '@material-ui/core/SvgIcon';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import CheckIcon from '@material-ui/icons/Check';
import RemoveIcon from '@material-ui/icons/Remove';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import classNames from 'classnames';
import { withApollo } from 'react-apollo';

import Divider from '@material-ui/core/Divider';
import { ReactComponent as CalledIcon } from './abgerufen.svg';
import { ReactComponent as MissingIcon } from './fehlteile.svg';
import { reformatIsoDate, reformatIsoDateTime } from '../../utils/dateTools';
import { trimLead0s } from '../../utils/displayUtils';
import { SET_PROJECT_PRIORITY } from './Api/Mutations';
import CopyableText from '../CopyableText';

@inject('store')
@observer
class ProjectCard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      updatedPriority: props.priority,
      removed: false,
    };
  }

  componentDidMount() {
    this.props.store.ui.registerProjectRendered();
  }

  select = () => {
    const {
      store,
      salesOrderNumber,
      positionNumber,
    } = this.props;

    store.source.projectChangeFilter(salesOrderNumber, positionNumber);
  }

  deselect = () => {
    const {
      store,
    } = this.props;

    if (store.source.filterFromProject) {
      store.source.projectChangeFilter(undefined);
    }
  }

  handlePriorityChange = (change) => (event) => {
    event.stopPropagation();
    const {
      client,
      salesOrderNumber,
      store,
    } = this.props;

    const {
      updatedPriority,
    } = this.state;

    const originalPriority = updatedPriority;

    try {
      this.setState((prevState) => ({
        updatedPriority: prevState.updatedPriority + change,
      }));

      client.mutate({
        mutation: SET_PROJECT_PRIORITY,
        variables: {
          priority: updatedPriority + change,
          salesOrderNumber,
        },
      });
    } catch (error) {
      this.setState({
        updatedPriority: originalPriority,
      });
      store.handleError(error);
    }
  }

  getSubtitle = () => {
    const {
      salesOrderNumber,
      productionOrderNumber,
      classes,
    } = this.props;

    return (
      <div className={classes.subHeadingContainer}>
        {this.isExpanded()
          ? (
            <CopyableText
              value={productionOrderNumber.trim() ? trimLead0s(productionOrderNumber) : trimLead0s(salesOrderNumber)}
              variant="subtitle1"
              bold
              className={classes.subHeading}
            />
          ) : (
            <Typography
              variant="subtitle1"
              className={classes.subHeading}
            >
              {productionOrderNumber.trim() ? trimLead0s(productionOrderNumber) : trimLead0s(salesOrderNumber)}
            </Typography>
          )}
        {!productionOrderNumber.trim() && (
          <Typography
            variant="subtitle1"
            className={classes.subHeadingKauf}
          >
            Kauf
          </Typography>
        )}
      </div>
    );
  }

  isExpanded = () => this.props.store.source.projectFilter === this.props.salesOrderNumber
    && this.props.store.source.projectFilterPositionNumber === this.props.positionNumber

  renderRow = (heading, contents, copyValue) => {
    const {
      classes,
    } = this.props;

    return (
      <div className={classes.row}>
        <Typography
          variant="subtitle1"
          style={{ fontWeight: heading === 'E. Fehlteile' ? 'unset' : undefined }}
          className={classes.rowHeading}
        >
          {heading}
        </Typography>
        <CopyableText
          value={contents || ''}
          variant="subtitle1"
          style={{ fontWeight: heading === 'E. Fehlteile' ? 'bold' : undefined }}
          className={classes.rowValue}
          copyValue={copyValue || ''}
        />
      </div>
    );
  }

  render() {
    const {
      systemName,
      customerName,
      customerCountryCode,
      status,
      callDate,
      omlStart,
      omlEnd,
      salesOrderNumber,
      productionOrderNumber,
      positionNumber,
      called,
      hasMissingPart,
      completed,
      classes,
      store,
      stats,
      assignment,
    } = this.props;

    const {
      updatedPriority,
      removed,
    } = this.state;

    const {
      source,
      ui,
      user,
    } = store;

    const {
      projectFilter,
      projectFilterPositionNumber,
    } = source;

    const {
      showExpectedMissingParts,
    } = ui;

    const subtitle = this.getSubtitle();
    // TODO the failover to 0 is a temporary patch. stats.expectedMissingParts should be reliably present
    const numExpectedMissingParts = stats.expectedMissingParts ? stats.expectedMissingParts.uniqueMaterials : 0;
    const fulfilled = assignment && assignment.openDemandFulfillableFromStock;
    const fulfillable = assignment && !fulfilled && assignment.openDemandFulfillableFromStockOrDeliveries;
    const numDemanded = assignment && assignment.openDemandQuantity;

    if (removed) {
      return null;
    }

    return (
      <div id="project" className={classes.container}>
        <div
          onClick={!this.isExpanded() ? this.select : () => undefined}
          data-testid="card"
          className={classNames(
            classes.card, {
            [classes[status]]: !this.isExpanded(),
            [classes[`${status}Highlight`]]: this.isExpanded(),
            [classes.unexpanded]: !this.isExpanded(),
          },
          )}
        >
          {fulfilled !== undefined && (
            <div
              className={classNames(classes.fulfilledStatus, {
                [classes.fulfilled]: fulfilled,
                [classes.fulfillable]: fulfillable,
                [classes.unfulfilled]: !fulfilled && !fulfillable,
              })}
            >
              {numDemanded}
            </div>
          )}
          <header className={classes.heading}>
            {this.isExpanded()
              ? (
                <div className={classes.subHeadingContainer}>
                  <CopyableText
                    value={systemName}
                    variant="subtitle1"
                    className={classNames(
                      classes.mainHeading,
                      {
                        [classes.expandedHeading]: projectFilter === salesOrderNumber && projectFilterPositionNumber === positionNumber,
                      },
                    )}
                  />
                </div>
              ) : (
                <div className={classes.subHeadingContainer}>
                  <Typography
                    variant="subtitle1"
                    className={classNames(
                      classes.mainHeading,
                      {
                        [classes.expandedHeading]: projectFilter === salesOrderNumber && projectFilterPositionNumber === positionNumber,
                      },
                    )}
                  >
                    {systemName}
                  </Typography>
                </div>
              )}
            {subtitle}
            <div className={classes.statusRow}>
              <div className={classes.iconBox}>
                {(updatedPriority > 0) && (
                  <Tooltip title="Priorisiert">
                    <div
                      className={classNames(classes.statusIconBorder, {
                        [classes.statusIconBorderExpanded]: this.isExpanded(),
                      })}
                    >
                      <HeartIcon className={classes.statusIcon} />
                    </div>
                  </Tooltip>
                )}
                {called && (
                  <Tooltip title="Abgerufen">
                    <div
                      className={classNames(classes.statusIconBorder, {
                        [classes.statusIconBorderExpanded]: this.isExpanded(),
                      })}
                    >
                      <SvgIcon className={classes.statusIcon}>
                        <CalledIcon />
                      </SvgIcon>
                    </div>
                  </Tooltip>
                )}
                {hasMissingPart && (
                  <Tooltip title="Fehlteile">
                    <div
                      className={classNames(classes.statusIconBorder, {
                        [classes.statusIconBorderExpanded]: this.isExpanded(),
                      })}
                    >
                      <SvgIcon className={classes.statusIcon} style={{ fontSize: 10 }}>
                        <MissingIcon />
                      </SvgIcon>
                    </div>
                  </Tooltip>
                )}
                {completed && (
                  <Tooltip title="Abgeschlossen">
                    <div
                      className={classNames(classes.statusIconBorder, {
                        [classes.statusIconBorderExpanded]: this.isExpanded(),
                      })}
                    >
                      <SvgIcon className={classes.statusIcon}>
                        <CheckIcon />
                      </SvgIcon>
                    </div>
                  </Tooltip>
                )}
              </div>
              {showExpectedMissingParts && (
                numExpectedMissingParts ? (
                  this.renderRow('E. Fehlteile', numExpectedMissingParts)
                ) : (
                  <div className={classes.vSpace} />
                )
              )}
            </div>
          </header>

          {this.isExpanded()
            && (
              <main>
                <Divider className={classes.divider} />
                {this.renderRow('KAUF', trimLead0s(salesOrderNumber))}
                {this.renderRow('FAUF', trimLead0s(productionOrderNumber))}
                {this.renderRow('Position', positionNumber)}
                {this.renderRow('Kunde', customerName)}
                {this.renderRow('Land', customerCountryCode)}
                {this.renderRow('Abruf', callDate ? reformatIsoDateTime(callDate) : '')}
                {this.renderRow('OML-Start', reformatIsoDate(omlStart))}
                {this.renderRow('OML-Ende', reformatIsoDate(omlEnd))}
                {this.renderRow(
                  'Priorität',
                  (
                    <span className={classes.priorityContainer}>
                      {user.user.permissions.includes('delivery.change_projectpriority') && (
                        <RemoveIcon
                          className={classes.priorityButton}
                          onClick={this.handlePriorityChange(-1)}
                        />
                      )}
                      <Typography
                        variant="subtitle1"
                        className={classes.priorityValue}
                      >
                        {updatedPriority}
                      </Typography>
                      {user.user.permissions.includes('delivery.change_projectpriority') && (
                        <AddIcon
                          data-testid="increase-priority"
                          className={classes.priorityButton}
                          onClick={this.handlePriorityChange(+1)}
                        />
                      )}
                    </span>
                  ),
                  updatedPriority,
                )}
                <Button
                  onClick={this.deselect}
                  className={classes.collapseButton}
                  data-testid="collapse"
                  aria-label="Collapse"
                >
                  <ExpandLessIcon />
                </Button>
              </main>
            )}
        </div>
      </div>
    );
  }
}

ProjectCard.propTypes = {
  status: PropTypes.string,
  systemName: PropTypes.string,
  productionOrderNumber: PropTypes.string.isRequired,
  salesOrderNumber: PropTypes.string,
  positionNumber: PropTypes.number,
  callDate: PropTypes.string,
  omlStart: PropTypes.string,
  omlEnd: PropTypes.string,
  customerName: PropTypes.string,
  customerCountryCode: PropTypes.string,
  store: MobxPropTypes.objectOrObservableObject,
  classes: PropTypes.object,
  client: PropTypes.object,
  priority: PropTypes.number,
  called: PropTypes.bool,
  hasMissingPart: PropTypes.bool,
  completed: PropTypes.bool,
  stats: PropTypes.object,
  assignment: PropTypes.object,
};


const style = () => ({
  container: {
    padding: 1,
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },
    position: 'relative',
  },
  unexpanded: {
    cursor: 'pointer',
  },
  card: {
    padding: '7px',
  },
  fulfilledStatus: {
    position: 'absolute',
    top: 0,
    right: 0,
    height: 18,
    width: 18,
    borderRadius: 9,
    margin: 4,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '10px',
    fontWeight: 'bold',
    borderWidth: 1,
    borderStyle: 'solid',
    color: 'black',
  },
  fulfilled: {
    backgroundColor: 'rgba(0, 166, 94, 0.6)',
    borderColor: 'rgb(0, 166, 94)',
  },
  fulfillable: {
    backgroundColor: 'rgba(245, 225, 20, 0.5)',
    borderColor: 'rgb(245, 225, 20)',
  },
  unfulfilled: {
    backgroundColor: 'rgba(228, 66, 88, 0.7)',
    borderColor: 'rgb(228, 66, 88)',
  },
  red: {
    border: '2px solid rgba(228, 66, 88, 0.5)',
  },
  yellow: {
    border: '2px solid rgba(245, 225, 20, 0.5)',
  },
  green: {
    border: '2px solid rgba(0, 166, 94, 0.4)',
  },
  redHighlight: {
    backgroundColor: 'rgba(228, 66, 88, 0.5)',
  },
  yellowHighlight: {
    backgroundColor: 'rgba(245, 225, 20, 0.5)',
  },
  greenHighlight: {
    backgroundColor: 'rgba(0, 166, 94, 0.4)',
  },
  mainHeading: {
    overflow: 'hidden',
    color: 'black',
    fontSize: '12px',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    paddingRight: 10,
  },
  expandedHeading: {
    textOverflow: 'visible',
    whiteSpace: 'normal',
  },
  subHeading: {
    fontWeight: 'bold',
    overflow: 'hidden',
    fontSize: '20px',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  subHeadingContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'baseline',
  },
  subHeadingKauf: {
    fontSize: '12px',
    paddingLeft: '10px',
    textTransform: 'uppercase',
  },
  statusRow: {
    display: 'flex',
    flexDirection: 'column',
  },
  iconBox: {
    height: '20px',
    display: 'flex',
    flexDirection: 'row',
  },
  statusIconBorder: {
    backgroundColor: 'rgb(236, 236, 236)',
    height: 18,
    width: 18,
    marginRight: 2,
    borderRadius: '50%',
    border: '1px solid rgb(191, 191, 191)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  statusIconBorderExpanded: {
    borderColor: 'rgb(255, 255, 255)',
  },
  statusIcon: {
    fontSize: '12px',
    color: 'black',
  },
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  rowHeading: {
    fontWeight: 'bold',
    fontSize: '12px',
  },
  divider: {
    backgroundColor: '#fff',
    marginBottom: '3px',
  },
  rowValue: {
    textAlign: 'right',
    fontSize: '12px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: 'flex',
  },
  priorityContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  priorityButton: {
    fontSize: '12px',
    margin: '0 2px',
  },
  priorityValue: {
    fontSize: '12px',
    display: 'inline-block',
  },
  deleteButton: {
    width: '100%',
  },
  collapseButton: {
    width: '100%',
  },
  vSpace: {
    height: '21px',
  },
});

export default withApollo(withStyles(style)(ProjectCard));
