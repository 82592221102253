import React from 'react';
import { MuiThemeProvider, withStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import IconButton from '@material-ui/core/IconButton';
import VisibilityIcon from '@material-ui/icons/Visibility';
import ClearIcon from '@material-ui/icons/Clear';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import lightTheme from '../../theme';
import SimpleTable from '../SimpleTable';
import { trimLead0s } from '../../utils/displayUtils';

class DeliveryModal extends React.Component {
  state = {
    open: false,
  }

  renderCell = (props) => (
    <div className={this.props.classes.cell}>
      <Typography variant="body2">{props.row}</Typography>
    </div>
  )

  toggleOpen = () => {
    this.setState((prevState) => ({
      open: !prevState.open,
    }));
  }

  render() {
    const {
      classes,
      upload,
    } = this.props;

    const {
      open,
    } = this.state;

    const tableColumns = [
      {
        header: 'Material',
        accessor: 'materialNumber',
        width: 70,
        Cell: this.renderCell,
      },
      {
        header: 'Bezeichnung',
        accessor: 'description',
        Cell: this.renderCell,
      },
      {
        header: 'Ordernummer',
        accessor: 'orderId',
        width: 116,
        Cell: this.renderCell,
      },
      {
        header: 'Lieferdatum',
        accessor: 'deliveryDate',
        width: 100,
        Cell: this.renderCell,
      },
      {
        header: 'Menge',
        accessor: 'quantity',
        width: 55,
        Cell: this.renderCell,
      },
      {
        header: 'Bemerkung',
        accessor: 'comment',
        width: 200,
        Cell: this.renderCell,
      },
    ];

    const data = upload.delivery_sheet.delivery_lines.map(line => ({
      materialNumber: trimLead0s(line.material.material_number),
      description: line.material.description,
      orderId: line.order.order_id,
      deliveryDate: line.delivery_date,
      quantity: line.quantity,
      comment: line.comment,
    }));

    return (
      <>
        <IconButton onClick={this.toggleOpen} data-testid="show-button" aria-label="Öffnen">
          <VisibilityIcon />
        </IconButton>
        <MuiThemeProvider theme={lightTheme}>
          <Modal
            open={open}
            onClose={this.toggleOpen}
          >
            <Card className={classes.modal}>
              <IconButton
                className={classes.closeButton}
                onClick={this.toggleOpen}
                aria-label="Schließen"
              >
                <ClearIcon/>
              </IconButton>
              <CardContent>
                <Typography variant="h6">{upload.supplier_name} Lieferungen</Typography>
                <SimpleTable keyColumn="materialNumber" columns={tableColumns} data={data} />
              </CardContent>
            </Card>
          </Modal>
        </MuiThemeProvider>
      </>
    );
  }
}

DeliveryModal.propTypes = {
  classes: PropTypes.object,
  upload: PropTypes.object,
};

const styles = () => ({
  modal: {
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
  },
  cell: {
    padding: '5px',
  },
  closeButton: {
    position: 'absolute',
    top: 0,
    right: 0,
    padding: '6px',
    zIndex: 1,
  },
});

export default withStyles(styles)(DeliveryModal);
