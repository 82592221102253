import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { withApollo } from 'react-apollo';
import gql from 'graphql-tag';
import Autocomplete from '../Autocomplete';
import StatusBox from '../StatusBox';
import { trimLead0s } from '../../utils/displayUtils';

export const FaufKaufQuery = gql`
query faufKaufQuery (
  $searchTerm: String
){
  allProjects(
    first: 5,
    salesOrProductionOrderNumber: $searchTerm,
  ) {
    edges {
      node {
        salesOrderNumber
        productionOrderNumber
      }
    }
  }
}`;

const FilterForm = ({
  classes,
  value,
  onOk,
  client,
}) => {
  const handleStatusChange = (newStatus) => {
    onOk({
      ...value,
      status: newStatus || [],
    });
  };

  const handleFaufKaufChange = (newFaufKauf) => {
    onOk({
      ...value,
      faufKauf: newFaufKauf || [],
    });
  };

  const loadFaufKaufs = async (searchValue) => {
    const response = await client.query({
      query: FaufKaufQuery,
      variables: {
        searchTerm: searchValue,
      },
    });
    if (response) {
      return response.data.allProjects.edges.map(({ node }) => {
        if (node.productionOrderNumber.includes(searchValue)) {
          return {
            value: node.productionOrderNumber.trim(),
            label: trimLead0s(node.productionOrderNumber.trim()),
          };
        }
        if (node.salesOrderNumber.includes(searchValue)) {
          return {
            value: node.salesOrderNumber.trim(),
            label: trimLead0s(node.salesOrderNumber.trim()),
          };
        }
        return [];
      });
    }
    return [];
  };

  return (
    <form autoComplete="off" className={classes.form}>
      <Autocomplete
        isMulti
        value={value.status && value.status.peek()}
        onChange={handleStatusChange}
        label="Ampel"
        name="status"
        defaultOptions={[
          {
            value: 'red',
            label: <StatusBox status="red" />,
          },
          {
            value: 'yellow',
            label: <StatusBox status="yellow" />,
          },
          {
            value: 'green',
            label: <StatusBox status="green" />,
          },
        ]}
      />
      <Autocomplete
        async
        isMulti
        value={value.faufKauf && value.faufKauf.peek()}
        onChange={handleFaufKaufChange}
        label="FAUF/KAUF"
        name="faufKauf"
        loadOptions={loadFaufKaufs}
      />
    </form>
  );
};

FilterForm.propTypes = {
  classes: PropTypes.object,
  value: PropTypes.object,
  onOk: PropTypes.func,
  client: PropTypes.object.isRequired,
};

const styles = () => ({
  form: {
    display: 'flex',
    width: '100%',
    color: 'white',
    flexDirection: 'column',
  },
});

export default withStyles(styles)(withApollo(FilterForm));
