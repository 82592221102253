import React from 'react';
import PropTypes from 'prop-types';
import { DateTime } from 'luxon';
import { getNDaysMatchingFrom, isWorkday } from '../../utils/dateTools';
import DeliveryCard from './DeliveryCard';
import StockCard from './StockCard';
import StatusCell from './StatusCell';
import DemandCard from './DemandCard';
import MaterialActions, { editableSources } from './MaterialActions';
import TableCell from './TableCell';
import TextCell from './TextCell';
import { trimLead0s } from '../../utils/displayUtils';
import { ReactComponent as DeliveryIcon } from '../../assets/trolley.svg';

const renderMaterialStatus = props => {
  if (props.row) {
    return <StatusCell value={props.row.status}/>;
  }
  return null;
};

renderMaterialStatus.propTypes = {
  row: PropTypes.object,
};


const renderMaterialNumber = (props, showReliableSupplier) => {
  if (props.row) {
    return (
      <TextCell
        value={trimLead0s(props.row.materialNumber)}
        appendIcon={<DeliveryIcon />}
        showAppendIcon={showReliableSupplier && props.row.hasReliableSupplier}
        tooltip={props.row.description}
      />
    );
  }
  return null;
};

renderMaterialNumber.propTypes = {
  row: PropTypes.object,
};

const renderDayStatus = props => {
  const quantity = props.row ? props.row.quantity : undefined;
  const source = props.row ? props.row.source : undefined;
  const status = props.row ? props.row.trafficLight : undefined;
  const deliveryDate = props.row ? props.row.deliveryDate : undefined;

  return (
    <DeliveryCard
      value={quantity}
      source={source}
      status={status}
      deliveryDate={deliveryDate}
    />
  );
};

renderDayStatus.propTypes = {
  row: PropTypes.object,
};

const renderStockCard = (props) => {
  if (props.row) {
    return (
      <StockCard value={props.row}/>
    );
  }
  return null;
};

renderStockCard.propTypes = {
  row: PropTypes.object,
};

const renderOverdue = (props) => {
  if (props.row) {
    return (
      <TextCell value={props.row.numOverdueDeliveries} />
    );
  }
  return null;
};

renderOverdue.propTypes = {
  row: PropTypes.object,
};

const renderDispoNr = (props) => {
  if (props.row) {
    return (
      <TextCell value={props.row.disp} />
    );
  }
  return null;
};

renderDispoNr.propTypes = {
  row: PropTypes.object,
};

const renderDemandCard = (props) => {
  if (props.row) {
    return (
      <DemandCard value={props.row}/>
    );
  }
  return null;
};

renderDemandCard.propTypes = {
  row: PropTypes.object,
};

const renderRange = (props) => {
  if (props.row) {
    return (
      <TextCell value={props.row.materialRange} />
    );
  }
  return null;
};

renderRange.propTypes = {
  row: PropTypes.object,
};

const renderActions = onDeliverySelected => (props) => {
  if (props.row) {
    return (
      <TableCell id="material-details-button">
        <MaterialActions value={props.row} onDeliverySelected={onDeliverySelected}/>
      </TableCell>
    );
  }
  return null;
};

renderActions.propTypes = {
  row: PropTypes.object,
};

const renderQuantity = (props) => {
  if (props.row) {
    return (
      <TextCell value={props.row.quantity} />
    );
  }
  return null;
};

renderQuantity.propTypes = {
  row: PropTypes.object,
};

function getDayColumnWidth(expanded, weekly) {
  if (expanded) {
    return 125;
  }
  if (weekly) {
    return 100;
  }
  return 200;
}

function getDayColumns(expanded, startingDay, onDeliverySelected, showWeeklyDeliveries) {
  const today = DateTime.local().startOf('day');
  const daysToShow = expanded ? 10 : 5;
  return getNDaysMatchingFrom(startingDay, isWorkday, daysToShow).map((daysFromStart, columnNumber) => {
    const dayInQuestion = startingDay.plus({ days: daysFromStart });
    const header = (columnNumber === 0 && today.hasSame(startingDay, 'day'))
      ? 'Heute'
      : dayInQuestion.toFormat('EEE dd.MM', { locale: 'de' });
    return {
      header,
      accessor: `t_plus_${daysFromStart}`,
      width: getDayColumnWidth(expanded, showWeeklyDeliveries),
      Cell: renderDayStatus,
      style: { cursor: 'pointer', flex: 'unset' },
      centered: true,
      onClick: (row, column, event) => {
        event.stopPropagation();
        if (!row[column] || editableSources.includes(row[column].source)) {
          onDeliverySelected(row, column);
        }
      },
    };
  });
}

function getBaseColumns(
  data,
  onDeliverySelected,
  startingDay,
  expanded,
  switchColumns,
  showWeeklyDeliveries,
  showReliableSupplier,
) {
  const baseColumns = [
    {
      header: '',
      key: 'status',
      tooltip: '2-Wochen-Verfügbarkeit',
      Cell: renderMaterialStatus,
      width: 10,
      centered: true,
    },
    {
      header: 'Material',
      key: 'heading',
      Cell: (props) => renderMaterialNumber(props, showReliableSupplier),
      centered: true,
    },
    {
      header: 'Bestand',
      key: 'stock',
      tooltip: 'Dispo-Bestand aus SAP',
      sortId: 'stockQuantity',
      Cell: renderStockCard,
      width: 100,
      sortable: true,
      centered: true,
    },
    {
      header: 'Bedarf',
      key: 'demand',
      tooltip: '2-Wochen-Bedarf',
      sortId: 'demandQuantity',
      Cell: renderDemandCard,
      width: 87,
      sortable: true,
      centered: true,
    },
    {
      header: 'RW',
      key: 'range',
      tooltip: 'Reichweite',
      sortId: 'materialRange',
      Cell: renderRange,
      width: 50,
      centered: true,
    },
    {
      header: 'Überfällig',
      key: 'numOverdueDeliveries',
      sortId: 'overdueDeliveryQuantity',
      Cell: renderOverdue,
      width: 90,
      centered: true,
      sortable: true,
    },
  ];

  const deliveryColumns = [];
  if (showWeeklyDeliveries) {
    deliveryColumns.push(...getDayColumns(expanded, startingDay, onDeliverySelected, showWeeklyDeliveries));
  } else {
    deliveryColumns.push({
      header: 'Anlieferung',
      tooltip: '2-Wochen-Ausblick',
      accessor: 'nextDelivery',
      Cell: renderDayStatus,
      width: 150,
      centered: true,
      style: { justifyContent: 'center', flex: 'unset' },
      onClick: (row, column, event) => {
        event.stopPropagation();
        if (!row[column] || editableSources.includes(row[column].source)) {
          onDeliverySelected(row, column);
        }
      },
    });
  }

  const actionColumn = {
    header: '',
    key: 'actions',
    Cell: renderActions(onDeliverySelected),
    width: 30,
    centered: true,
  };

  if (switchColumns) {
    return [
      actionColumn,
      ...deliveryColumns.reverse(),
      ...baseColumns,
    ];
  }

  return [
    ...baseColumns,
    ...deliveryColumns,
    actionColumn,
  ];
}

// Colums for reduced view on dashboard
function getDashboardColumns(
  data,
  showReliableSupplier,
) {
  const baseColumns = [
    {
      header: 'Material',
      key: 'heading',
      Cell: (props) => renderMaterialNumber(props, showReliableSupplier),
      centered: true,
    },
    {
      header: 'Dispo',
      key: 'dispo',
      tooltip: 'Zugewiesenes Dispo',
      sortId: 'dispo',
      Cell: renderDispoNr,
      width: 100,
      centered: true,
    },
    {
      header: 'Menge',
      key: 'quantity',
      tooltip: 'Dispo-Bestand aus SAP',
      sortId: 'quantity',
      Cell: renderQuantity,
      width: 100,
      centered: true,
    },
  ];

  return [
    ...baseColumns,
  ];
}

export { getBaseColumns, getDashboardColumns };
