import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject, observer, PropTypes as MobxPropTypes } from 'mobx-react';
import { toJS } from 'mobx';
import { withStyles } from '@material-ui/core/styles';
import { withSnackbar } from 'notistack';
import {
  Button,
  TextField,
} from '@material-ui/core';

import { extractErrorText } from '../../../Api/index';
import { allSupplierCodes } from '../../../utils/dispoUtils';
import Autocomplete from '../../Autocomplete';
import { updateProfile } from '../../../Api/endpoints/updateProfile';

@inject('store')
@observer
class CreateDispoProfile extends Component {
  state = {
    selectedDispos: [],
    title: '',
  }

  saveProfile = async () => {
    const {
      store,
      enqueueSnackbar,
    } = this.props;

    const {
      selectedDispos,
      title,
    } = this.state;

    const {
      user,
      token,
    } = store.user;

    if (user.profile) {
      const oldPresets = user.profile.dispo_presets ? toJS(user.profile.dispo_presets) : [];

      const newPresets = [
        ...oldPresets,
        {
          title,
          dispos: selectedDispos.map(dispo => ({
            code: dispo.value,
          })),
        },
      ];

      const updatedProfile = {
        profile: {
          ...user.profile,
          dispo_presets: newPresets,
        },
      };

      try {
        const { value, error } = await updateProfile(updatedProfile, token);
        if (error) {
          throw error;
        }
        enqueueSnackbar('Aktualisierung erfolgreich', { variant: 'success' });

        user.profile = value.profile;

        this.setState({
          title: '',
          selectedDispos: [],
        });
      } catch (error) {
        store.handleError(error, extractErrorText(error));
      }
    } else {
      this.showOutOfDateError();
    }
  }

  render() {
    const {
      classes,
    } = this.props;

    const {
      selectedDispos,
      title,
    } = this.state;

    return (
      <div className={classes.dispoForm}>
        <div className={classes.dropdownContainer}>
          <Autocomplete
            isMulti
            value={selectedDispos}
            defaultOptions={allSupplierCodes}
            onChange={(newSelection) => {
              this.setState({
                selectedDispos: newSelection,
              });
            }}
            label="Dispos"
            name="supplierCode"
          />
        </div>
        <TextField
          className={classes.textField}
          label="Titel"
          value={title}
          onChange={(event) => this.setState({
            title: event.target.value,
          })}
        />
        <Button
          onClick={this.saveProfile}
          aria-label="Speichern"
        >
          Speichern
        </Button>
      </div>
    );
  }
}

CreateDispoProfile.propTypes = {
  classes: PropTypes.object,
  store: MobxPropTypes.objectOrObservableObject,
  enqueueSnackbar: PropTypes.func,
};

const style = () => ({
  dispoForm: {
    width: '100%',
    display: 'flex',
  },
  dropdownContainer: {
    flex: 1,
    margin: '0 5px',
  },
  textField: {
    flex: 1,
    margin: '0 5px',
  },
});

export default withSnackbar(withStyles(style)(CreateDispoProfile));
